import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import { ShowActiveTab } from '../../../../Context'
import service from '../../../../api/services'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import constant from '../../../../constants/constant'
import {handleFileDownload} from '../../../../constants/utils'
import molecules from '../../../../components/molecules'
import { useToasts } from 'react-toast-notifications'
const { Delivered } = organisms
const { TopBar } = molecules
const Index = () => {
  const { addToast } = useToasts();
  const [receiptData, setReceiptData] = useState<any>()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const { setActiveTab }: any = useContext(ShowActiveTab)
  const getFilterList = async () => {
    try {
      const response = await service.PendingOrder.delivered({ search: search, page: currentPage, limit: limit, status: constant?.ORDER_STATUS?.DELIVERED })

      if (response.status === 200) {
        setReceiptData(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handlePrintSharePdf = async(id: string, share?: boolean) => {
    try {
        let response;
        if (share) {
          response = await service.SalesCart.sharePdf(id, { share });
          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
        });
        } else {
          response = await service.SalesCart.generatePdf(id);
          if (response.status === 200) {
            handleFileDownload(response?.data?.data?.path)
          }
        }
  
      } catch (error:any) {
        console.log('error', error)
        addToast(error?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
      });
      }
}

  useEffect(() => {
    getFilterList()
  }, [search, currentPage, limit])

  useEffect(() => {
    setActiveTab("deliver")
    setCurrentPage(1)
    if (currentPage == 1 && initialLoading) {
      getFilterList()
      setInitialLoading(true)
    }
  }, [limit])
  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Sales History' />
      </div>
      <div className='mt-3'>
        <Delivered
          receiptData={receiptData}
          setSearch={setSearch}
          limit={limit}
          currentPage={currentPage}
          setLimitPerPage={setLimit}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          handlePrintSharePdf={(id:string, share?:boolean)=>handlePrintSharePdf(id, share)}
        />
      </div>
    </Wrapper>
  )
}

export default Index
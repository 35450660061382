import { useContext, useEffect, useState, useRef } from 'react'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import atoms from '../../atoms';
import service from '../../../api/services';
import { findConstantLabel, getDropdown, isValidProductType, numberWithCommas, sumTwoNumbers, } from '../../../constants/utils';
import SearchTab from '../SearchTab';
import constant from '../../../constants/constant';
import ConfirmationModal from '../ConfirmationModal';
import { IoMdAddCircleOutline } from 'react-icons/io';
import CreateModal from '../CreateModal';
import { useToasts } from 'react-toast-notifications';
import { VscDiffAdded } from "react-icons/vsc";
import { CiEdit } from "react-icons/ci";
import { IAdditionalProduct } from './interface';
import { MdOutlineAddToPhotos } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosAddCircleOutline } from "react-icons/io";
import { LiaEdit } from "react-icons/lia";
// import { FiEdit } from "react-icons/fi";
import { SalesCartTimer } from '../../../Context';
const { Select, Search, Image, Input, Checkbox, AvailableBadge, TextArea, CustomImages, RadioInput, CustomSearch, Button } = atoms
const ProductCard = ({ product, onUpdateDiscount, getViewCart, setSelectedCartItems, setShowModal, setAdditionalProduct, setBillingSummary }: any) => {
    const [discountAmount, setDiscountAmount] = useState<number>(product?.discount);
    const [showProductDetail, setShowProductDetail] = useState<boolean>(false)
    const [showLensDetail, setShowLensDetail] = useState<boolean>(false)
    const [discountPercentage, setDiscountPercentage] = useState<number | null>(null);
    const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
    const [removeProductId, setRemoveProductId] = useState<string>('')
    const [disAmount, setDisAmount] = useState<number>(NaN)
    const { addToast } = useToasts();
    const { salesCartTimer, setSalesCartTimer }: any = useContext(SalesCartTimer)
    const repeatItem = async (orderItemId: string) => {

        try {
            const response = await service.SalesCart.repeatItem(orderItemId)
            if (response.status === 200) {
                setSalesCartTimer({ isFirst: false, isStarted: !salesCartTimer.isStarted })
                localStorage.setItem('salesCartTimerPopup', '1')
                getViewCart(false)
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const _handleEditProduct = async (selectedItem: any) => {
        await setAdditionalProduct({
            product_type_id: selectedItem?.other_product_data?.product_type_id,
            product_type_name: selectedItem?.other_product_data?.product_type_name,
            price: selectedItem?.other_product_data?.price,
            description: selectedItem?.other_product_data?.description,
            isAdditional: selectedItem?.is_other_product,
            name: selectedItem?.other_product_data?.lens_details?.name,
            phone: selectedItem?.other_product_data?.lens_details?.phone,
            r_dv_sph: selectedItem?.other_product_data?.lens_details?.r_dv_sph,
            r_dv_cyl: selectedItem?.other_product_data?.lens_details?.r_dv_cyl,
            r_dv_axis: selectedItem?.other_product_data?.lens_details?.r_dv_axis,
            r_dv_pd: selectedItem?.other_product_data?.lens_details?.r_dv_pd,
            r_dv_va: selectedItem?.other_product_data?.lens_details?.r_dv_va,
            r_nv_sph: selectedItem?.other_product_data?.lens_details?.r_nv_sph,
            r_nv_cyl: selectedItem?.other_product_data?.lens_details?.r_nv_cyl,
            r_nv_axis: selectedItem?.other_product_data?.lens_details?.r_nv_axis,
            r_nv_pd: selectedItem?.other_product_data?.lens_details?.r_nv_pd,
            r_add: selectedItem?.other_product_data?.lens_details?.r_add,

            l_dv_sph: selectedItem?.other_product_data?.lens_details?.l_dv_sph,
            l_dv_cyl: selectedItem?.other_product_data?.lens_details?.l_dv_cyl,
            l_dv_axis: selectedItem?.other_product_data?.lens_details?.l_dv_axis,
            l_dv_pd: selectedItem?.other_product_data?.lens_details?.l_dv_pd,
            l_dv_va: selectedItem?.other_product_data?.lens_details?.l_dv_va,
            l_nv_sph: selectedItem?.other_product_data?.lens_details?.l_nv_sph,
            l_nv_cyl: selectedItem?.other_product_data?.lens_details?.l_nv_cyl,
            l_nv_axis: selectedItem?.other_product_data?.lens_details?.l_nv_axis,
            l_nv_pd: selectedItem?.other_product_data?.lens_details?.l_nv_pd,
            l_add: selectedItem?.other_product_data?.lens_details?.l_add,
            isEdit: true,
            productId: selectedItem?.id
        })
        setShowModal(true)

    }
    const removeItem = async () => {
        try {
            const response = await service.SalesCart.removeItem(removeProductId)
            if (response.status === 200) {
                setHandleDeleteModal(!handleDeleteModal)
                setSalesCartTimer({ isFirst: false, isStarted: !salesCartTimer.isStarted })
                localStorage.setItem('salesCartTimerPopup', '1')
                getViewCart(false)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    const calculateDiscountPercentage = (amount: number, product_srp: number) => {
        let p = (amount / product_srp) * 100
        let percentage: any = p > 0 ? p : null
        setDiscountPercentage(parseFloat(percentage?.toFixed(2)));
        setDiscountAmount(parseFloat(amount?.toFixed(2)));
        // setDiscountAmount(amount);
        setDisAmount(amount)
        setBillingSummary((preV: any) => {
            return { ...preV, redemption_request: false };
        });
        onUpdateDiscount(product.id, percentage, amount);
    };
    useEffect(() => {
        let srp_price = product?.product?.product_srp || product?.other_product_data?.price
        calculateDiscountPercentage(product?.discount, srp_price)
    }, [product?.discount, product?.other_product_data?.price])

    const calculateDiscountAmount = (percentage: number, product_srp: number) => {
        let amount = (percentage / 100) * product_srp
        setDiscountAmount(parseFloat(amount.toFixed(2)));
        setDiscountPercentage(percentage);
        setDisAmount(amount)
        setBillingSummary((preV: any) => {
            return { ...preV, redemption_request: false };
        });
        onUpdateDiscount(product.id, percentage, amount);
    };
    const _handleRemove = (productId: string) => {
        setHandleDeleteModal(!handleDeleteModal)
        setRemoveProductId(productId)

    }
    const _handleBarcode = (barcode: any) => {
        product.inventory_item = barcode
        setSelectedCartItems((prevArray: any) =>
            prevArray?.map((obj: any) =>
                (obj.isDelivered == true && obj.cart_item_id === product.id)
                    ? { ...obj, inventory_item_id: barcode?.value }
                    : obj
            )
        )
    }
    const _handleIsDelivered = (e: any) => {
        let isChecked = e.target.checked
        setSelectedCartItems((prevArray: any) => {
            const newArray = prevArray?.map((obj: any) =>
                obj.cart_item_id === product.id
                    // ? { ...obj, isDelivered: isChecked }
                    ? { ...obj, inventory_item_id: isChecked ? obj.inventory_item_id : '', isDelivered: isChecked }
                    : obj
            )

            return newArray;
        }
        )
        if (product.id) {
            product.isDelivered = isChecked;
            // product.inventory_item_id = isChecked ? product.inventory_item_id : '';
        }
    }
    useEffect(() => {
        let x = ((product?.product?.barcodes.length > 1) && (!product?.inventory_item))
            ? null // If there are multiple barcodes, don't prefill
            : {
                key: product?.inventory_item?.id || product?.inventory_item?.key || product?.product?.barcodes[0]?.id,
                value: product?.inventory_item?.id || product?.inventory_item?.value || product?.product?.barcodes[0]?.id,
                label: product?.inventory_item?.bar_code || product?.inventory_item?.label || product?.product?.barcodes[0]?.bar_code
            }
        _handleBarcode(x)
    }, [product.isDelivered])

    return (
        <>
            {(!product.is_other_product) ?
                // ================== For Regular Products================

                <div className='checkout-product-card mt-md-4 mt-2'>
                    <div className='d-flex flex-wrap  align-items-center justify-content-between gap-sm-4 gap-2 '>
                        <div className='d-none d-md-flex flex-wrap gap-3 align-items-center'>
                            <div className='d-flex flex-wrap align-items-center gap-3'>
                                <h2 className='mb-0 fs-14 fw-600'>Product ID :-</h2>
                                <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                    <h2 className='mb-0 fs-12 fw-400'>{product?.product?.product_id_display}</h2>
                                    <Image src={'Copy'} alt="" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className='d-md-none d-flex justify-content-between w-100 align-items-center'>
                            <div>
                                <h2 className='mb-0 fs-16 fw-600'>{product?.product?.brand?.brand_name}</h2>
                            </div>
                            <div className='d-flex gap-2 text-sm-start text-md-start text-lg-end mt-1 mt-md-0 align-items-center'>
                                {(discountAmount > 0 || (discountPercentage || 0) > 0) && <p className='mb-0 fs-md-16 fs-12 fw-500 dark-gray text-decoration-line-through'>₹{product?.product?.product_srp}</p>}
                                <p className='mb-0 fs-md-18 fs-14 fw-600 dark-gray'>₹{disAmount > 0 ? numberWithCommas(parseFloat((product?.product?.product_srp - disAmount).toFixed(2))) : numberWithCommas(parseFloat(product?.product?.product_srp.toFixed(2)))}</p>
                            </div>
                        </div>
                        <div>
                            {product.availableAtStore && <div className='d-flex flex-wrap align-items-center gap-3'>
                                <Checkbox className='checkbox-style cursor-pointer'
                                    onChange={(e) => _handleIsDelivered(e)}
                                    id={'idDelivered'}
                                    label={'Product Is Delivered'}
                                    checked={product?.isDelivered}
                                    labelClass='fs-md-16 fs-12 ms-2'
                                    name={'idDelivered'} />
                            </div>}
                        </div>
                        <div className='d-flex justify-content-between gap-sm-4'>

                            <div className='d-none d-md-flex ms-3 flex-wrap align-items-center gap-3'>
                                <h2 className='mb-0 fs-md-14 fs-12 fw-500 text-decoration-underline cursor-pointer' onClick={() => _handleRemove(product?.id)}>Remove</h2>
                                <h2 className='mb-0 fs-md-14 fs-12 fw-500 text-decoration-underline cursor-pointer' onClick={() => repeatItem(product?.id)}>Repeat</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        {product.availableAtStore && <div className="sales-card-badge">
                            <AvailableBadge />
                        </div>}
                        <div className={`col-5 col-md-3 ${product.availableAtStore && "mt-3"} pe-0 mt-md-4`} style={{ overflow: "hidden" }}>
                            <div className='sales-product-image' style={{ overflow: "hidden" }}>
                                <div className=''>
                                    <CustomImages imageHeight='100px' src={product?.product?.images[0] || BrandPlaceHolder} />
                                </div>
                                <div className='d-md-none row'>
                                    <div className='col-6 d-flex justify-content-center border p-1' onClick={() => _handleRemove(product?.id)}>
                                        <RiDeleteBin6Line className='fs-18' style={{ color: "#fb3f3fe6" }} />
                                    </div>
                                    <div className='col-6 d-flex justify-content-center border p-1' onClick={() => repeatItem(product?.id)}>
                                        <IoIosAddCircleOutline className='fs-18' />
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ backgroundImage: `url(${product?.product?.images[0] || BrandPlaceHolder})` }}
                                className='img-fluid product-image ' ></div> */}
                        </div>

                        <div className={`col-7 col-md-9 ${product.availableAtStore && "mt-3"} mt-md-4 pe-0 pe-md-2`}>
                            <div className='d-md-none d-flex flex-wrap align-items-center gap-1'>
                                <h2 className='mb-0 fs-md-14 fs-10 fw-600'>Product ID :-</h2>
                                <h2 className='mb-0 fs-md-12 fs-10 fw-400 dark-gray'>{product?.product?.product_id_display}</h2>
                                {/* <div className='d-none id-input-design d-flex justify-content-between align-items-center gap-2'>
                                    <Image src={'Copy'} alt="" className='img-fluid' />
                                </div> */}
                            </div>
                            <div className='d-md-none mt-2'>
                                {<div>
                                    <div className='d-flex gap-1 mb-1'>
                                        <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Model {window.innerWidth < 768 ? 'No' : 'Number'}: <span className='mb-0 fs-md-14 fs-11 fw-600'>{product?.product?.modal_number}</span></h2>
                                        <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Size: <span className='mb-0 fs-md-14 fs-11 fw-600'>{product?.product?.glass_size}MM</span></h2>
                                    </div>
                                    <div className='d-flex gap-1 mb-1'>
                                        <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Warranty: <span className='mb-0 fs-md-14 fs-11 fw-600'>{findConstantLabel(constant.PRODUCT_WARRANTY, product?.product?.warranty)}</span></h2>
                                        <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Polarized: <span className='mb-0 fs-md-14 fs-11 fw-600'>{findConstantLabel(constant?.POLARIZED, product?.product?.isPolarised)}</span></h2>
                                    </div>
                                </div>}
                                {showProductDetail &&
                                    <>
                                        <div className='d-flex gap-1 mb-1'>
                                            <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>With Case: <span className='mb-0 fs-md-14 fs-11 fw-600'>{product?.product?.product_with_case ? "Yes" : "No"} </span></h2>
                                            <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Color Code: <span className='mb-0 fs-md-14 fs-11 fw-600'>{product?.product?.brand_color_code}</span></h2>
                                        </div>
                                        <div className="gap-1 align-items-center px-0">
                                            <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'><span className=''>{product?.product?.description}</span></h2>
                                        </div>
                                    </>
                                }
                                <div className='d-md-none text-start'>
                                    <a className='fs-10' style={{ minWidth: "40px" }} onClick={() => setShowProductDetail(!showProductDetail)}>{!showProductDetail ? "see more" : "see less"}</a>
                                </div>
                            </div>
                            <div className='row d-none d-md-flex flex-wrap justify-content-between align-items-end '>
                                <div className='col-lg-10'>
                                    <h2 className='mb-0 fs-md-16 fs-14 fw-600 dark-gray'>{product?.product?.brand?.brand_name}</h2>
                                    <div className="d-flex gap-md-3 gap-2 align-items-center my-2 ">
                                        <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Model {window.innerWidth < 768 ? 'No' : 'Number'}: <span className='mb-0 fs-md-14 fs-10 fw-600'>{product?.product?.modal_number}</span></h2>
                                        <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Warranty: <span className='mb-0 fs-md-14 fs-10 fw-600'>{findConstantLabel(constant.PRODUCT_WARRANTY, product?.product?.warranty)}</span></h2>
                                        <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Polarized: <span className='mb-0 fs-md-14 fs-10 fw-600'>{findConstantLabel(constant?.POLARIZED, product?.product?.isPolarised)}</span></h2>
                                    </div>

                                    <div className="d-flex gap-md-3 gap-2 align-items-center mb-2">
                                        <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Color Code: <span className='mb-0 fs-md-14 fs-10 fw-600'>{product?.product?.brand_color_code}</span></h2>
                                        <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>With Case: <span className='mb-0 fs-md-14 fs-10 fw-600'>{product?.product?.product_with_case ? "Yes" : "No"} </span></h2>
                                        <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Size: <span className='mb-0 fs-md-14 fs-10 fw-600'>{product?.product?.glass_size}MM</span></h2>
                                    </div>
                                    <div className="d-flex gap-3 align-items-center mb-2">
                                        <h2 className='mb-0 fs-md-14 fs-12 fw-500 dark-gray'><span className=''>{product?.product?.description}</span></h2>
                                    </div>
                                </div>
                                <div className='col-lg-2 text-sm-start text-md-start text-lg-end mt-1 mt-md-0'>
                                    {(discountAmount > 0 || (discountPercentage || 0) > 0) && <p className='mb-0 fs-16 fw-500 dark-gray text-decoration-line-through'>₹{product?.product?.product_srp}</p>}
                                    <p className='mb-0 fs-md-18 fs-12 fw-600 dark-gray'>₹{disAmount > 0 ? numberWithCommas(parseFloat((product?.product?.product_srp - disAmount).toFixed(2))) : numberWithCommas(parseFloat(product?.product?.product_srp.toFixed(2)))}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <hr className="mt-md-3 mt-2" style={{ border: '1px solid #22222260', }} />

                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className='d-flex flex-wrap gap-md-3 gap-2 mt-md-2 mb-3 align-items-center'>
                            <h2 className="mb-0 fs-md-18 fs-12 fw-600">Item Discount :-</h2>
                            <div className='d-flex gap-md-3 gap-1 mt-md-0 align-items-center'>
                                <div className='d-flex gap-1'>
                                    <span>₹</span>
                                    <Input type="number" placeholder='Rupee' className='roundOff-inp fs-md-12 fs-10'
                                        value={discountAmount}
                                        onChange={(e: any) => calculateDiscountPercentage(parseFloat(e.target.value), product.product.product_srp)} />
                                </div>
                                <FaArrowRightArrowLeft />
                                <div>
                                    <div className="d-flex gap-1 position-relative">
                                        <Input type="number" placeholder='Percentage%' className='roundOff-inp fs-md-12 fs-10'
                                            value={discountPercentage}
                                            onChange={(e: any) => e.target.value <= 100 && calculateDiscountAmount(parseFloat(e.target.value), product.product.product_srp)} />
                                        <span>%</span>
                                    {(discountPercentage || 0) > product?.product?.brand_allocation_details?.brand_discount && 
                                    <p style={{minWidth:'250px', bottom:'-15px'}} className='position-absolute text-danger fs-md-12 fs-14 fw-500 mb-0'> Max discount is {product?.product?.brand_allocation_details?.brand_discount}% </p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {product.isDelivered && product.availableAtStore && <div className='d-flex flex-wrap gap-md-3 gap-2 align-items-center mt-2'>
                            <h2 className="mb-md-0 fs-md-18 fs-14 fw-600">Barcode :-</h2>

                            <Select className='sales-custom-select fs-12'
                                value={{
                                    key: product?.inventory_item?.id || product?.inventory_item?.key,
                                    value: product?.inventory_item?.id || product?.inventory_item?.value,
                                    label: product?.inventory_item?.bar_code || product?.inventory_item?.label
                                }}
                                options={getDropdown(product?.product?.barcodes, 'bar_code')}
                                onChange={(e: any) => _handleBarcode(e)}
                            />
                        </div>}
                    </div>
                </div >
                :
                // ================== For Additional Products================
                <div className='checkout-product-card mt-md-4 mt-2'>
                    <div className='d-md-none d-flex justify-content-between w-100 align-items-center mb-2'>
                        <div>
                            <h2 className='mb-0 fs-16 fw-600'>{product?.other_product_data?.product_type_name}</h2>
                        </div>
                        <div className='d-flex gap-2 text-sm-start text-md-start text-lg-end'>
                            {(discountAmount > 0 || (discountPercentage || 0) > 0) && <p className='mb-0 fs-md-16 fs-12 fw-500 dark-gray text-decoration-line-through'>₹{(product?.other_product_data?.price).toFixed(2)}</p>}
                            <p className='mb-0 fs-md-18 fs-14 fw-600 dark-gray'>₹{disAmount > 0 ? numberWithCommas(parseFloat((product?.other_product_data?.price - disAmount).toFixed(2))) : numberWithCommas(parseFloat((product?.other_product_data?.price).toFixed(2)))}</p>
                        </div>
                    </div>
                    <div className='d-flex flex-wrap  align-items-center justify-content-between gap-4 '>
                        <div className='d-flex flex-wrap gap-3 align-items-center'>
                            {product.availableAtStore && <div className='d-flex flex-wrap align-items-center gap-3'>
                                <Checkbox className='checkbox-style cursor-pointer fs-12'
                                    onChange={(e) => _handleIsDelivered(e)}
                                    id={'idDelivered'} label={'Product Is Delivered'}
                                    labelClass='fs-md-16 fs-12 ms-2'
                                    checked={product?.isDelivered}
                                    name={'idDelivered'} />
                                {/* <h2 className='mb-0 fs-14 fw-600' >Product Is Delivered</h2> */}
                            </div>}
                        </div>

                        <div className='d-none d-md-flex flex-wrap align-items-center gap-3'>
                            <h2 className='mb-0 fs-md-14 fs-12 fw-500 text-decoration-underline cursor-pointer' onClick={() => _handleEditProduct(product)}>Edit</h2>
                            <h2 className='mb-0 fs-md-14 fs-12 fw-500 text-decoration-underline cursor-pointer' onClick={() => _handleRemove(product?.id)}>Remove</h2>
                            <h2 className='mb-0 fs-md-14 fs-12 fw-500 text-decoration-underline cursor-pointer' onClick={() => repeatItem(product?.id)}>Repeat</h2>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-5 col-md-3 mt-3 pe-0 pe-md-1 mt-md-4" style={{ overflow: "hidden" }}>
                            <div style={{ overflow: "hidden" }}>
                                <div className='sales-product-image'>
                                    <Image src={BrandPlaceHolder} alt="Product Image" className='img-fluid product-image ' />
                                </div>
                                <div className='d-md-none'>
                                    <div className='d-flex'>
                                        <div className='col-4 d-flex justify-content-center border p-1' onClick={() => _handleRemove(product?.id)}>
                                            <RiDeleteBin6Line className='fs-18' style={{ color: "#fb3f3fe6" }} />
                                        </div>
                                        <div className='col-4 d-flex justify-content-center border p-1' onClick={() => _handleEditProduct(product)}>
                                            <CiEdit className='fs-18' />
                                        </div>
                                        <div className='col-4 d-flex justify-content-center border p-1' onClick={() => repeatItem(product?.id)}>
                                            <IoIosAddCircleOutline className='fs-18 fw-400' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="sales-product-image col-5 col-md-3 mt-md-4 mt-3" style={{overflow:"hidden"}}>
                            <div>
                                <Image src={BrandPlaceHolder} alt="Product Image" className='img-fluid product-image ' />
                            </div>
                        </div> */}

                        <div className="col-7 col-md-9 mt-md-4 mt-3">
                            <div className='row flex-wrap justify-content-between align-items-end '>
                                <div className="w-80">
                                    <h2 className='d-none d-md-flex mb-0 fs-md-16 fs-12 fw-600 dark-gray'>{product?.other_product_data?.product_type_name}</h2>
                                    <div className="d-flex gap-3 align-items-center mt-2">
                                        <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'><span className=''>{product?.other_product_data?.description}</span></h2>
                                    </div>
                                    {product?.other_product_data?.lens_details && <div className='mt-2'>
                                        <h3 className='fs-md-14 fs-11 fw-500 dark-gray'>Name : {product?.other_product_data?.lens_details?.name}</h3>
                                        <h3 className='fs-md-14 fs-11 fw-500 dark-gray'>Phone : {product?.other_product_data?.lens_details?.phone} </h3>
                                        <div className="d-none d-md-block  card p-3 mb-2">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <h3 className='text-center fs-16 fw-600 dark-gray'>{`Right Eye (OD)`}</h3>
                                                    <div className="row">
                                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                                        <div className="col fs-12 fw-500 dark-gray">SPH</div>
                                                        <div className="col fs-12 fw-500 dark-gray">CYL</div>
                                                        <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                                                        <div className="col fs-12 fw-500 dark-gray">PD</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col fs-12 fw-500 dark-gray">DV:</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_sph}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_cyl}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_axis}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_pd}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col fs-12 fw-500 dark-gray">NV:</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_sph}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_cyl}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_axis}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_pd}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_add}</div>
                                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                                    </div>

                                                </div>
                                                <div className="col-12 col-md-6" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.175)' }}>
                                                    <h3 className='text-center fs-16 fw-600 dark-gray'>{`Left Eye (OS)`}</h3>
                                                    <div className="row">
                                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                                        <div className="col fs-12 fw-500 dark-gray">SPH</div>
                                                        <div className="col fs-12 fw-500 dark-gray">CYL</div>
                                                        <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                                                        <div className="col fs-12 fw-500 dark-gray">PD</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col fs-12 fw-500 dark-gray">DV:</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_sph}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_cyl}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_axis}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_pd}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col fs-12 fw-500 dark-gray">NV:</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_sph}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_cyl}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_axis}</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_pd}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_add}</div>
                                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className='d-none d-md-block text-sm-start text-md-start text-lg-end mt-3 mt-md-0'>
                                    {(discountAmount > 0 || (discountPercentage || 0) > 0) && <p className='mb-0 fs-16 fw-500 dark-gray text-decoration-line-through'>₹{(product?.other_product_data?.price).toFixed(2)}</p>}
                                    <p className='mb-0 fs-md-18 fs-12 fw-600 dark-gray'>₹{disAmount > 0 ? numberWithCommas(parseFloat((product?.other_product_data?.price - disAmount).toFixed(2))) : numberWithCommas(parseFloat((product?.other_product_data?.price).toFixed(2)))}</p>
                                </div>
                            </div>
                            {product?.other_product_data?.lens_details && <div className='d-md-none text-start'>
                                <a className='fs-10' style={{ minWidth: "40px" }} onClick={() => setShowLensDetail(!showLensDetail)}>{!showLensDetail ? "see more" : "see less"}</a>
                            </div>}
                        </div>
                    </div>
                    {(product?.other_product_data?.lens_details && showLensDetail) && <div className='d-md-none mt-2'>
                        <div className="card p-3 mb-2">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <h3 className='text-center fs-16 fw-600 dark-gray'>{`Right Eye (OD)`}</h3>
                                    <div className="row">
                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                        <div className="col fs-12 fw-500 dark-gray">SPH</div>
                                        <div className="col fs-12 fw-500 dark-gray">CYL</div>
                                        <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                                        <div className="col fs-12 fw-500 dark-gray">PD</div>
                                    </div>
                                    <div className="row">
                                        <div className="col fs-12 fw-500 dark-gray">DV:</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_sph}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_cyl}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_axis}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_pd}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col fs-12 fw-500 dark-gray">NV:</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_sph}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_cyl}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_axis}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_pd}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_add}</div>
                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                    </div>

                                </div>
                                <div className="col-12 col-md-6" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.175)' }}>
                                    <h3 className='text-center fs-16 fw-600 dark-gray'>{`Left Eye (OS)`}</h3>
                                    <div className="row">
                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                        <div className="col fs-12 fw-500 dark-gray">SPH</div>
                                        <div className="col fs-12 fw-500 dark-gray">CYL</div>
                                        <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                                        <div className="col fs-12 fw-500 dark-gray">PD</div>
                                    </div>
                                    <div className="row">
                                        <div className="col fs-12 fw-500 dark-gray">DV:</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_sph}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_cyl}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_axis}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_pd}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col fs-12 fw-500 dark-gray">NV:</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_sph}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_cyl}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_axis}</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_pd}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_add}</div>
                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                        <div className="col fs-12 fw-500 dark-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <hr className="mt-3" style={{ border: '1px solid #22222260', }} />

                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className='d-flex flex-wrap gap-md-3 gap-2 mt-md-2 mb-3 align-items-center'>
                            <h2 className="mb-0 fs-md-18 fs-12 fw-600">Item Discount :-</h2>
                            <div className='d-flex gap-md-3 gap-1 mt-md-0 align-items-center'>
                                <div className='d-flex gap-1'>
                                    <span>₹</span>
                                    <Input type="number" placeholder='Rupee' className='roundOff-inp'
                                        value={discountAmount}
                                        onChange={(e: any) => calculateDiscountPercentage(parseFloat(e.target.value), product.other_product_data.price)} />
                                </div>
                                <FaArrowRightArrowLeft />
                                <div className="d-flex gap-1">
                                    <Input type="number" placeholder='Percentage%' className='roundOff-inp'
                                        value={discountPercentage}
                                        onChange={(e: any) => e.target.value <= 100 && calculateDiscountAmount(parseFloat(e.target.value), product.other_product_data.price)} />
                                    <span>%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >}
            {
                handleDeleteModal && <ConfirmationModal
                    show={handleDeleteModal}
                    handleConfirmItem={() => removeItem()}
                    onHide={() => setHandleDeleteModal(!handleDeleteModal)}
                    message={'remove this product'} />
            }
        </>
    )
}
const Index = ({ cart_Data, getViewCart, setSearchProduct, productList, setSelectedCartItems,
    setBillingSummary, customerInfo, setSearchType }: any) => {

    let cartData = cart_Data
    const inputRef = useRef<any>(null);
    const { addToast } = useToasts();
    const [isOpen, setIsOpen] = useState(false);
    const [isProduct, setIsProduct] = useState("")
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedProductType, setSelectedProductType] = useState<string>('')
    const [productTypeList, setProductTypeList] = useState()
    const [lensDetailsError, setLensDetailsError] = useState<boolean>(false)
    const [additionalProduct, setAdditionalProduct] = useState<IAdditionalProduct>({
        product_type_id: '',
        price: '',
        description: '',
        isAdditional: true,
        name: customerInfo?.name || '',
        phone: customerInfo?.mobile || '',
        r_dv_sph: "",
        r_dv_cyl: "",
        r_dv_axis: "",
        r_dv_pd: "",
        r_add: "",
        r_nv_sph: "",
        r_nv_cyl: "",
        r_nv_axis: "",
        r_nv_pd: "",
        l_dv_sph: "",
        l_dv_cyl: "",
        l_dv_axis: "",
        l_dv_pd: "",
        l_nv_sph: "",
        l_nv_cyl: "",
        l_nv_axis: "",
        l_nv_pd: "",
        l_add: "",
        isEdit: false,
        productId: ''
    })
    const [lensPhoneNumberError, setLensPhoneNumberError] = useState<boolean>(false)
    const { salesCartTimer, setSalesCartTimer }: any = useContext(SalesCartTimer)
    const clearFields = () => {
        return setAdditionalProduct({
            product_type_id: '',
            price: '',
            description: '',
            isAdditional: false,
            name: customerInfo?.name || '',
            phone: customerInfo?.mobile || '',
            r_dv_sph: "",
            r_dv_cyl: "",
            r_dv_axis: "",
            r_dv_pd: "",
            r_add: "",
            r_nv_sph: "",
            r_nv_cyl: "",
            r_nv_axis: "",
            r_nv_pd: "",
            l_dv_sph: "",
            l_dv_cyl: "",
            l_dv_axis: "",
            l_dv_pd: "",
            l_nv_sph: "",
            l_nv_cyl: "",
            l_nv_axis: "",
            l_nv_pd: "",
            l_add: "",
        })
    }
    const addItem = async (productId: { id: string }) => {
        const payload = {
            product_id: productId?.id
        }
        try {
            await service.SalesCart.addToCart(payload)
            setSalesCartTimer({ isFirst: false, isStarted: !salesCartTimer.isStarted })
            localStorage.setItem('salesCartTimerPopup', '1')
            getViewCart(false)
        } catch (error: any) {
            console.log('error', error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const searchCallBack = (search: string) => {
        setIsProduct(search)
        setSearchProduct(search)
        setIsOpen(true)
    }
    const clearSearchField = () => {
        if (inputRef?.current) {
            inputRef.current.value = '';
        }
    };

    const updateDiscount = (productId: number, discountPercentage: number, discountAmount: number) => {
        setSelectedCartItems((prevArray: any) =>
            prevArray?.map((obj: any) =>
                obj.cart_item_id === productId
                    ? { ...obj, discount: discountAmount }
                    : obj
            )
        )
    };

    const getProductTypeList = async () => {
        try {
            const response = await service.Product.productTypeList()
            if (response.status === 200)
                setProductTypeList(getDropdown(response.data.data, 'name'))
        } catch (error) {
            console.log('error', error)
        }
    }
    const _handleSelectedProductType = (obj: any) => {
        setAdditionalProduct({
            product_type_id: obj.value,
            price: '',
            description: '',
            isAdditional: false,
            name: customerInfo?.name || '',
            phone: customerInfo?.mobile || '',
            r_dv_sph: "",
            r_dv_cyl: "",
            r_dv_axis: "",
            r_dv_pd: "",
            r_add: "",
            r_nv_sph: "",
            r_nv_cyl: "",
            r_nv_axis: "",
            r_nv_pd: "",
            l_dv_sph: "",
            l_dv_cyl: "",
            l_dv_axis: "",
            l_dv_pd: "",
            l_nv_sph: "",
            l_nv_cyl: "",
            l_nv_axis: "",
            l_nv_pd: "",
            l_add: "",
        });
        setSelectedProductType(obj.label)
        setLensPhoneNumberError(false)
    }

    const formatValue = (value: number, targetValue: string) => {
        return value !== undefined ? ((/\+/.test(targetValue)) ? `+${value}` : `${value}`) : '';
    }

    const handleSubmit = async () => {
        const payload = {
            product_type_id: additionalProduct?.product_type_id,
            price: additionalProduct?.price,
            description: additionalProduct?.description,
            isAdditional: true,
            lens_details: (isValidProductType(selectedProductType) || isValidProductType(additionalProduct?.product_type_name)) ?
                {
                    name: additionalProduct?.name,
                    phone: additionalProduct?.phone,

                    r_dv_sph: `${additionalProduct?.r_dv_sph}`,
                    r_dv_cyl: `${additionalProduct?.r_dv_cyl}`,
                    r_dv_axis: `${additionalProduct?.r_dv_axis}`,
                    r_dv_pd: `${additionalProduct?.r_dv_pd}`,
                    r_add: `${additionalProduct?.r_add ? additionalProduct?.r_add : ""}`,

                    r_nv_sph: `${additionalProduct?.r_nv_sph}`,
                    r_nv_cyl: `${additionalProduct?.r_nv_cyl}`,
                    r_nv_axis: `${additionalProduct?.r_nv_axis}`,
                    r_nv_pd: `${additionalProduct?.r_nv_pd}`,

                    l_dv_sph: `${additionalProduct?.l_dv_sph}`,
                    l_dv_cyl: `${additionalProduct?.l_dv_cyl}`,
                    l_dv_axis: `${additionalProduct?.l_dv_axis}`,
                    l_dv_pd: `${additionalProduct?.l_dv_pd}`,
                    l_nv_sph: `${additionalProduct?.l_nv_sph}`,
                    l_nv_cyl: `${additionalProduct?.l_nv_cyl}`,
                    l_nv_axis: `${additionalProduct?.l_nv_axis}`,
                    l_nv_pd: `${additionalProduct?.l_nv_pd}`,
                    l_add: `${additionalProduct?.l_add ? additionalProduct?.l_add : ""}`,

                }
                : null
        }
        let response
        try {
            if ((isValidProductType(selectedProductType) || isValidProductType(additionalProduct?.product_type_name))
                && (additionalProduct?.name.length <= 0 || additionalProduct?.phone == null)) {
                setLensDetailsError(true)

            } else {
                setLensDetailsError(false)
                if (additionalProduct.isEdit) {
                    response = await service.SalesCart.EditItem(additionalProduct?.productId || '', payload)

                } else {
                    response = await service.SalesCart.addAdditionalProducts(payload)

                }
                if (response.status === 200) {
                    setSalesCartTimer({ isFirst: false, isStarted: !salesCartTimer.isStarted })
                    localStorage.setItem('salesCartTimerPopup', '1')
                    getViewCart(false)
                    setShowModal(false)
                    setSelectedProductType('')
                    clearFields()
                }
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const _handleOnHide = () => {
        setShowModal(false)
        setSelectedProductType('')
        clearFields()
    }

    const getLensContactLensDetail = async (phoneNumber: number, selectedProductType: string) => {
        try {
            setLensPhoneNumberError(true)
            if (phoneNumber?.toString()?.length === 10) {
                const response = await service?.Prescription?.searchPrescription({ search: phoneNumber })
                let type = selectedProductType.toLowerCase()?.replace(/\s/g, '_')
                if (response.status === 200) {
                    let lensDetail = response?.data?.data?.[type]?.prescription_details
                    setAdditionalProduct({
                        ...additionalProduct,
                        name: lensDetail?.name || '',
                        r_dv_sph: lensDetail?.r_dv_sph || '',
                        r_dv_cyl: lensDetail?.r_dv_cyl || '',
                        r_dv_axis: lensDetail?.r_dv_axis || "",
                        r_dv_pd: lensDetail?.r_dv_pd || '',
                        r_add: lensDetail?.r_add || '',
                        r_nv_sph: lensDetail?.r_nv_sph || '',
                        r_nv_cyl: lensDetail?.r_nv_cyl || '',
                        r_nv_axis: lensDetail?.r_nv_axis || '',
                        r_nv_pd: lensDetail?.r_nv_pd || '',
                        l_dv_sph: lensDetail?.l_dv_sph || '',
                        l_dv_cyl: lensDetail?.l_dv_cyl || '',
                        l_dv_axis: lensDetail?.l_dv_axis || '',
                        l_dv_pd: lensDetail?.l_dv_pd || '',
                        l_nv_sph: lensDetail?.l_nv_sph || '',
                        l_nv_cyl: lensDetail?.l_nv_cyl || '',
                        l_nv_axis: lensDetail?.l_nv_axis || '',
                        l_nv_pd: lensDetail?.l_nv_pd || '',
                        l_add: lensDetail?.l_add || '',
                    })
                }
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    useEffect(() => {
        getProductTypeList()
    }, [])

    return (
        <div className='sales-checkout-card'>
            <div className="d-md-flex">
                <h2 className='mb-0 fs-16 fw-600 align-items-center'>Cart ({cartData?.cart_items?.length} items)</h2>
                <div className='d-flex mt-md-0 mt-1 align-items-center'>
                    <RadioInput type="radio" name='search_type'
                        className="ms-sm-2"
                        onChange={(e: any) => { setSearchType('barcode'), clearSearchField(); searchCallBack("") }}
                        checked={cartData?.searchType === 'barcode'}
                        labelClass='fs-md-16 fs-14'
                        value={'barcode'} label='Barcode' id={'Yes'}
                    />
                    <RadioInput type="radio" name='search_type'
                        className="ms-2"
                        labelClass='fs-md-16 fs-14'
                        onChange={(e: any) => { setSearchType('search'); clearSearchField(); searchCallBack("") }}
                        checked={cartData?.searchType === 'search'}
                        value={'search'} label={`Product Id / Modal ${window.innerWidth < 768 ? 'No.' : 'Number'}`} id={'No'}
                    />
                </div>
            </div>
            <div className='mt-sm-4 mt-2 position-relative' onClick={() => setIsOpen(!isOpen)}>
                <Search placeholder={cartData?.searchType === 'search' ? 'Enter Product ID Or Modal Number' : 'Enter Barcode'}
                    searchCallBack={searchCallBack} inputRef={inputRef}
                    className='fs-md-16 fs-12 new-custom-search sales-input-height' heading={'Search Product'} />
                {(isProduct.length > 0 && isOpen) && <SearchTab data={productList} searchCallBack={addItem} />}
            </div>

            <div className="col-12 mt-sm-4 mt-2 other-items-btn"
                onClick={() => setShowModal(true)}>
                Add Additional Products
                <IoMdAddCircleOutline className='fs-22 ms-1' />
            </div>

            {cartData && cartData?.cart_items?.map((item: any, index: number) => {
                return (
                    <ProductCard key={item.id} product={item} onUpdateDiscount={updateDiscount}
                        getViewCart={getViewCart} setSelectedCartItems={setSelectedCartItems} setShowModal={setShowModal}
                        setAdditionalProduct={setAdditionalProduct} setBillingSummary={setBillingSummary} />
                )
            })}
            {showModal && <CreateModal
                show={showModal}
                onHide={() => _handleOnHide()}
                submitBtnText={`${additionalProduct.isEdit ? 'Edit Product' : 'Add Product'}`}
                handleSubmit={handleSubmit}
                maxWidth={(isValidProductType(selectedProductType) || isValidProductType(additionalProduct?.product_type_name)) ? '1200px' : ''}
            >
                <div className=''>
                    <div className='row'>
                        <div className="col-6">
                            {!additionalProduct?.isEdit && <div className="form-outline mb-4"
                            // style={{ minWidth: '225px' }}
                            >
                                <Select
                                    options={productTypeList}
                                    onChange={(obj: any) => _handleSelectedProductType(obj)}
                                    label={"Product Type"} placeholder={'Select'} />
                            </div>}
                            {additionalProduct?.isEdit &&
                                <div className="mb-4 d-flex flex-wrap align-items-center">
                                    <label className='inputs-label mb-2 fs-16 fw-600  secondary-color'>{'Product Type'}</label>
                                    <div className='w-100 ps-2 d-flex align-items-center'
                                        style={{
                                            border: '1px solid #d5d5d5',
                                            backgroundColor: '#f3f3f3',
                                            borderRadius: '5px',
                                            minHeight: '55px',
                                            cursor: 'default'
                                        }}>{additionalProduct?.product_type_name}</div>
                                </div>
                            }
                        </div>
                        <div className='col-6'>
                            <div className="form-outline mb-4">
                                <Input type={"number"}
                                    onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, price: e.target.value })}
                                    label={"Amount"} value={additionalProduct?.price} placeholder={'Enter Amount'} />
                            </div>
                        </div>
                    </div>
                    <div className="form-outline mb-4"
                    // style={{ maxWidth: '560px' }}
                    >
                        <TextArea type={"text"}
                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, description: e.target.value })}
                            label={"Description"} value={additionalProduct?.description} placeholder={'Enter Description'} />
                    </div>
                    {(isValidProductType(selectedProductType) || isValidProductType(additionalProduct?.product_type_name)) &&
                        <>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <div className="form-outline mb-4"
                                    // style={{ minWidth: '225px' }}
                                    >
                                        <Input type={"text"}
                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, name: e.target.value })}
                                            label={"Name"} value={additionalProduct?.name} placeholder={'Enter Name'}
                                            errors={!additionalProduct?.name && lensDetailsError && 'This field is required'} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-outline mb-4">
                                        <label className='inputs-label mb-2 fs-16 fw-600 secondary-color'>Phone Number</label>
                                        <div style={{ position: "relative" }}>
                                            <Input type={"number"}
                                                onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, phone: e.target.value })}
                                                value={additionalProduct?.phone} placeholder={'Enter Phone Number'}
                                                errors={!additionalProduct?.phone && lensDetailsError && 'This field is required'}
                                            />
                                            {lensPhoneNumberError && additionalProduct?.phone && !(additionalProduct?.phone?.toString()?.length === 10) && <span className="error-text">phone number should be 10 digit</span>}
                                            <Button className={`position-absolute shadow-none border-0 add-product-btn`}
                                                isDisabled={!additionalProduct?.phone}
                                                onClick={() => getLensContactLensDetail(additionalProduct?.phone, selectedProductType)} >
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12 ">
                                    <div className='card'>

                                        <div className='row px-2 my-2'>
                                            <div className="col-12 col-md-6">
                                                <h3 className='text-center inputs-label m-2 fs-16 fw-600 secondary-color'>{`RIGHT EYE (OD)`}</h3>

                                                <div className="d-flex gap-1">
                                                    <div className="align-items-center d-flex" style={{ width: '225px' }} >
                                                        <h4 className='fs-16 fw-600 secondary-color'>{`DV :`}</h4>
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, r_dv_sph: e.target.value, r_nv_sph: formatValue(sumTwoNumbers(e.target.value, parseFloat(additionalProduct.r_add)), e.target.value) })}
                                                            label='SPH' value={additionalProduct?.r_dv_sph} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, r_dv_cyl: e.target.value, r_nv_cyl: e.target.value })}
                                                            label='CYL' value={additionalProduct?.r_dv_cyl} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, r_dv_axis: e.target.value, r_nv_axis: e.target.value })}
                                                            label='AXIS' value={additionalProduct?.r_dv_axis} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, r_dv_pd: e.target.value, r_nv_pd: e.target.value })}
                                                            label='PD' value={additionalProduct?.r_dv_pd} placeholder='' />
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-2 gap-1">
                                                    <div className="align-items-center d-flex" style={{ width: '225px' }}>
                                                        <h4 className='fs-16 fw-600 secondary-color'>{`NV :`}</h4>
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => ''}
                                                            value={additionalProduct.r_nv_sph}
                                                            disabled={true} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => ''} disabled={true}
                                                            value={additionalProduct?.r_nv_cyl} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => ''} disabled={true}
                                                            value={additionalProduct?.r_nv_axis} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => ''} disabled={true}
                                                            value={additionalProduct?.r_nv_pd} placeholder='' />
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-2">
                                                    <div className=" align-items-center d-flex" style={{ width: '100px' }}>
                                                        <h4 className='fs-16 fw-600 secondary-color'>{`ADD :`}</h4>
                                                    </div>
                                                    <div className="" style={{ width: '110px' }}>
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, r_add: e.target.value, r_nv_sph: formatValue(sumTwoNumbers(parseFloat(additionalProduct.r_dv_sph), e.target.value), e.target.value) })}
                                                            value={additionalProduct?.r_add} placeholder='' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mt-4 mt-md-0 left-border "
                                            //  style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.175)' }}
                                            >
                                                <hr className='d-block d-md-none' />

                                                <h3 className='text-center inputs-label my-2 fs-16 fw-600 secondary-color'>{`LEFT EYE (OS)`}</h3>
                                                <div className="d-flex gap-1">
                                                    <div className="align-items-center d-flex" style={{ width: '225px' }} >
                                                        <h4 className='fs-16 fw-600 secondary-color'>{`DV :`}</h4>
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_dv_sph: e.target.value, l_nv_sph: formatValue(sumTwoNumbers(e.target.value, parseFloat(additionalProduct.l_add)), e.target.value) })}
                                                            label='SPH' value={additionalProduct?.l_dv_sph} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_dv_cyl: e.target.value, l_nv_cyl: e.target.value })}
                                                            label='CYL' value={additionalProduct?.l_dv_cyl} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_dv_axis: e.target.value, l_nv_axis: e.target.value })}
                                                            label='AXIS' value={additionalProduct?.l_dv_axis} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_dv_pd: e.target.value, l_nv_pd: e.target.value })}
                                                            label='PD' value={additionalProduct?.l_dv_pd} placeholder='' />
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-2 gap-1">
                                                    <div className=" align-items-center d-flex" style={{ width: '225px' }} >
                                                        <h4 className='fs-16 fw-600 secondary-color'>{`NV :`}</h4>
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => ''}
                                                            value={additionalProduct.l_nv_sph}
                                                            disabled={true} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => ''} disabled={true}
                                                            value={additionalProduct?.l_nv_cyl} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => ''} disabled={true}
                                                            value={additionalProduct?.l_nv_axis} placeholder='' />
                                                    </div>
                                                    <div className="">
                                                        <Input type='text'
                                                            onChange={(e: any) => ''} disabled={true}
                                                            value={additionalProduct?.l_nv_pd} placeholder='' />
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-2">
                                                    <div className="align-items-center d-flex" style={{ width: '100px' }} >
                                                        <h4 className='fs-16 fw-600 secondary-color'>{`ADD :`}</h4>
                                                    </div>
                                                    <div className="" style={{ width: '110px' }}>
                                                        <Input type='text'
                                                            onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_add: e.target.value, l_nv_sph: formatValue(sumTwoNumbers(parseFloat(additionalProduct.l_dv_sph), e.target.value), e.target.value) })}
                                                            value={additionalProduct?.l_add} placeholder='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </div>
            </CreateModal>}
        </div >
    )
}

export default Index
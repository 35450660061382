import React,{useEffect,useContext} from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';

import organisms from '../../../components/organisms';
const { TopBar } = molecules
const { Audit } = organisms

const Index = () => {

  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Inventory Audit' />
      </div>
      <div className='row mt-4'>
        <Audit />
      </div>
    </Wrapper>
  )
}

export default Index
import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { warranty } = Dictionary

const warrantyService = {

    warrantyProductList: async (paramsObj: {}) => {
        return axios.get(BASE_URL + warranty.warrantyProductList(), { params: paramsObj })
    },
    warrantyProductSubmit: async (id: string, payload: {}) => {
        return axios.post(BASE_URL + warranty.warrantyProductSubmit(id), payload)
    },
    warrantyProductUpdate: async (id: string, payload: {}) => {
        return axios.put(BASE_URL + warranty.warrantyProductUpdate(id), payload)
    },
    warrantyProductDetail: async (id: string) => {
        return axios.get(BASE_URL + warranty.warrantyProductDetail(id))
    },
    warrantyProductImageDelete: async (payload: {}) => {
        return axios.post(BASE_URL + warranty.productImageDelete(), payload)
    },


    claimedProductList: async (paramsObj: {}) => {
        return axios.get(BASE_URL + warranty.claimedProductList(), { params: paramsObj })
    },
    claimProductDetail: async (id: string) => {
        return axios.get(BASE_URL + warranty.claimedProductDetail(id))
    },
    claimProductReject: async (id: string) => {
        return axios.put(BASE_URL + warranty.claimedProductReject(id))
    },
    claimProductApprove: async (id: string) => {
        return axios.put(BASE_URL + warranty.claimedProductApprove(id))
    },

    issueNewProductDetail: async (warrantyClaimedId: string, productId: string) => {
        return axios.get(BASE_URL + warranty.issueNewProductDetail(warrantyClaimedId, productId))
    },
    replaceProduct: async (id: string, payload: {}) => {
        return axios.post(BASE_URL + warranty.replaceProduct(id), payload)
    },
    requestProduct: async (id: string) => {
        return axios.post(BASE_URL + warranty.requestProduct(id))
    },
    issueCreditNote: async (id: string) => {
        return axios.put(BASE_URL + warranty.issueCreditNote(id))
    },


    claimedHistoryList: async (paramsObj: {}) => {
        return axios.get(BASE_URL + warranty.claimedHistory(), { params: paramsObj })
    },
    downloadDoc :async (id:string)=>{
        return axios.get(BASE_URL + warranty.shareDoc(id))
    },
    shareDoc: async (id: string, paramsObj?:{}) => {
        return axios.get(BASE_URL + warranty.shareDoc(id), { params: paramsObj })
    },




}
export default warrantyService

import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { paymentVoucher } = Dictionary

const PaymentVoucherService = {
    searchGlAccount: async (paramsObj:{}) => {
        return axios.get(BASE_URL + paymentVoucher.searchGlAccount(),{params:paramsObj})
    },
    createPaymentVoucher: async (payload:any) => {
        return axios.post(BASE_URL + paymentVoucher.createPaymentVoucher(),payload)
    },

    paymentVoucherHistory: async () => {
        return axios.get(BASE_URL + paymentVoucher.paymentVoucherHistory())
    },
    downloadVoucherHistory:async (id:string)=>{
        return axios.get(BASE_URL + paymentVoucher.voucherHistoryDownload(id))
    },
    shareVoucherHistory:async (id:string , paramsObj:{})=>{
        return axios.get(BASE_URL + paymentVoucher.voucherHistoryDownload(id), {params:paramsObj})
    }

}
export default PaymentVoucherService
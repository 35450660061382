import atoms from "../../atoms";
import { numberWithCommas } from "../../../constants/utils";

const { Pagination, Search, Table, CustomImages } = atoms

const Index = ({ productList, setSearch, currentPage, setCurrentPage, setLimitPerPage, limit }: any) => {
    const columns = [
        { name: 'Product ID', selector: (row: any) => row?.product?.product_id_display, },
        { name: 'Product Type', selector: (row: any) => row?.product?.product_type?.name, },
        { name: 'Cover Image', cell: (row: any) => <CustomImages src={row?.product?.images[0] && row?.product?.images[0]} height="75%"/>, center: true },
        { name: 'With Case', selector: (row: any) => row?.product?.product_with_case ? "Yes" : "No", },

        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name || '-', },
        { name: 'Brand Color Code', selector: (row: any) => row?.product?.brand_color_code || '-', },
        { name: 'Model No', selector: (row: any) => row?.product?.modal_number || '-', },
        { name: 'Glass Size', selector: (row: any) => row?.product?.glass_size || '-', },

        { name: 'SRP', selector: (row: any) => `₹${numberWithCommas(parseFloat(row?.product?.product_srp.toFixed(2)))}` || '-', },
    ]


    return (
        <>
            <div className='row mt-3'>
                <div className='col-12 col-md-6'>
                    <Search className='search search-style' searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
                <div>
                    <Table
                        columns={columns}
                        data={productList?.data?.map((item: any) => {
                            return ({
                                ...item
                            })
                        })}
                    />

                </div>
                <div>
                    <Pagination
                        totalCount={productList?.count}
                        currentPage={currentPage}
                        totalPages={productList?.totalPages}
                        onChangePage={(page: any) => setCurrentPage(page)}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    )
}

export default Index
import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import organisms from "../../../components/organisms";
import service from "../../../api/services";
import { useToasts } from 'react-toast-notifications';
import molecules from "../../../components/molecules";
import { ShowActiveTab } from '../../../Context';
import { handleFileDownload } from '../../../constants/utils'
import { paymentDetail } from './interface'


const { PaymentVoucher } = organisms
const { TopBar } = molecules


const Index = () => {
    const { addToast } = useToasts();
    const { setActiveTab }: any = useContext(ShowActiveTab)
    const [glAccountList, setGLAccountList] = useState<any>()
    const [searchItem, setSearchItem] = useState<any>()
    const [paymentExtraDetail, setPaymentExtraDetail] = useState({
        narration: "",
        remarks: ""
    })
    const [paymentsDetail, setPaymentsDetail] = useState<paymentDetail[]>([{
        mode_of_payment: "",
        amount: 0,
        transaction_number: null
    }])



    const getGlAccountList = async (search: string, check?: boolean) => {
        try {
            setSearchItem("")
            if (search?.length > 0) {
                const response = await service?.PaymentVoucherService?.searchGlAccount({ search: search })
                if (response?.data?.data?.length < 1) {
                    addToast("Customer Gl Account is not Found", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                }
                if (check) {
                    handleSearchSelect(response?.data?.data[0])
                }
                if (response?.status === 200) {
                    setGLAccountList(response?.data?.data)
                }
            } else {
                setGLAccountList([])
            }
        } catch (error: any) {
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleSubmitButton = async () => {
        const payments = paymentsDetail?.map((items: any) => ({
            mode_of_payment: items?.mode_of_payment?.value,
            amount: parseInt(items?.amount),
            transaction_number: items?.transaction_number,
        }));
        const payload = {
            account_id: searchItem?.id,
            customer_id: searchItem?.customer_id,
            narration: paymentExtraDetail?.narration,
            remarks: paymentExtraDetail?.remarks,
            payments: payments
        }
        try {
            const response = await service?.PaymentVoucherService?.createPaymentVoucher(payload)
            if (response?.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                // handleFileDownload(response?.data?.data?.path)
                getGlAccountList(searchItem?.gl_number, true)
                clearData()
                // setSearchItem("")
            }
        } catch (error: any) {
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleExtraDetailChange = (name: string, value: any) => {
        setPaymentExtraDetail({ ...paymentExtraDetail, [name]: value })
    }

    const clearData = () => {
        setPaymentExtraDetail({
            narration: "",
            remarks: ""
        })
        setPaymentsDetail([{
            mode_of_payment: "",
            amount: 0,
            transaction_number: null
        }])

    }

    const handleSearchSelect = (item: any) => {
        if (item?.balance <= 0) {
            setSearchItem(item);
            clearData()
        } else {
            addToast("Balance receivable from this customer, Payment voucher will not proceed!", {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }




    useEffect(() => {
        setActiveTab("sales Return")
    }, [])

    return (
        <Wrapper >
            <div style={{ height: "80vh" }}>
                <div className='row align-items-center'>
                    <TopBar heading={'Payment Voucher'} />
                </div>
                <PaymentVoucher
                    searchCallBack={(search: string) => getGlAccountList(search)}
                    glAccountList={glAccountList}
                    handleSearchSelect={(item: any) => handleSearchSelect(item)}
                    handleSubmitButton={() => handleSubmitButton()}
                    searchItem={searchItem}
                    paymentsDetail={paymentsDetail}
                    setPaymentsDetail={setPaymentsDetail}
                    paymentExtraDetail={paymentExtraDetail}
                    handleExtraDetailChange={(name: string, value: any) => handleExtraDetailChange(name, value)} />
            </div>
        </Wrapper>
    )
}

export default Index
import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { store } = Dictionary

const storeService = {
    storeList: async () => {
        return axios.get(BASE_URL + store.storeList())
    },
    switchStore: async (storeId: string) => {
        return axios.get(BASE_URL + store.switchStore(storeId))
    },
}
export default storeService

import React from 'react'
import molecules from '../../molecules'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const { ProductCard } = molecules

const Index = () => {

    const reduxProductList: any = useSelector((state: RootState) => state?.rootReducer?.productListSlice);
    const reduxCounter = useSelector((state: RootState) => state?.rootReducer?.counterSlice?.currentCount);
    return (
        <div className="row">
            <>
                {reduxProductList?.list?.length > 0 ?
                    <>
                        {reduxProductList?.list?.map((item: any, index: number) => (
                            <ProductCard productData={item} key={index} />
                        ))
                        }
                        {!(reduxProductList?.list?.length === reduxCounter) &&
                            // Show skeletons while loading
                            Array.from({ length: window?.innerWidth < 768 ? 2 : 3 }).map((_, index) => (
                                <div className="col-md-4 col-6 mt-1" key={index}>
                                    <Skeleton className='product-list-loading-skeleton' borderRadius={8} />
                                    <Skeleton width="60%" />
                                    <Skeleton width="80%" />
                                </div>
                            ))
                        }
                    </>
                    :
                    <div className='justify-content-center d-flex mt-5 '>
                        <span className='fs-22 '>No Product Available</span>
                    </div>
                }
            </>
        </div>
    )
}

export default Index
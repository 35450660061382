import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface LabelsState {
//   allLabels: string[];
// }

const initialState: any = {
  allLabels: [],
};

const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    setAllLabels: (state, action: PayloadAction<string[]>) => {
      state.allLabels = action.payload;
    },
  },
});

export const { setAllLabels } = labelsSlice.actions;

export default labelsSlice.reducer;

import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { product } = Dictionary

const Product = {
    productList: async (paramsObj: {}, signal:any) => {
        return axios.post(BASE_URL + product.product(),paramsObj, { signal:signal})
    },
    list: async (paramsObj: {}) => {
        return axios.get(BASE_URL + product.product(), { params: paramsObj })
    },
    filter: async () => {
        return axios.get(BASE_URL + product.filter())
    },
    productByID: async (id:string) => {
        return axios.get(BASE_URL + product.productById(id) )
    },
    productTypeList: async () => {
        return axios.get(BASE_URL + product.productTypeList() )
    },
}
export default Product

import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { audit } = Dictionary

const Audit = {
    barcodeSearchApi: async (paramsObj: {}) => {
        return axios.get(BASE_URL + audit.barcodeSearch(), { params: paramsObj })
    },
    updateItemCondition: async (id:string, payload:{}) => {
        return axios.put(BASE_URL + audit.itemCondition(id), payload )
    },
}
export default Audit
import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../components/organisms";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import service from "../../../api/services";
import molecules from "../../../components/molecules";
import { useToasts } from "react-toast-notifications";
import { ShowActiveTab } from "../../../Context";
import { BsFillShareFill } from "react-icons/bs";

const { Prescription } = organisms;
const { TopBar } = molecules;

const Index = () => {
  const { addToast } = useToasts();
  const [searchVal, setSearchVal] = useState<string>("");
  const { setActiveTab }: any = useContext(ShowActiveTab);

  const [prescriptionData, setPrescriptionData] = useState<any>();

  const searchPrescription = async (search: string) => {
    try {
      const response = await service?.Prescription?.searchPrescription({
        search: search,
      });
      if (response.status === 200) {
        setSearchVal(search);
        setPrescriptionData(response?.data?.data);
        if (
          !response?.data?.data?.lens?.prescription_details ||
          !response?.data?.data?.lens?.prescription_details
        ) {
          addToast("Not Found", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleShare = async () => {
    try {
      const response = await service?.Prescription?.sharePrescription({
        search: searchVal,
        whatsappShare: true,
      });
      if (response.status === 200) {
        addToast(response?.data?.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    } catch (error: any) {
      console.log("error", error);
      addToast(error?.data?.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };

  useEffect(() => {
    setActiveTab("Prescription");
  }, []);
  return (
    <Wrapper>
      <div className="mt-0 mt-md-2 mt-lg-0 hide-scroll prescription-container">
        <div className="row align-items-center">
          <TopBar heading="Prescription" />
        </div>
        <div className="mt-3 mb-1">
          <Prescription
            prescriptionData={prescriptionData}
            searchCallBack={(e: string) => searchPrescription(e)}
            handleShare={() => handleShare()}
          />
        </div>
        {prescriptionData?.lens?.prescription_details &&
          <div className="d-md-none prescription-share-button" onClick={() => handleShare()}>
            <BsFillShareFill className="prescription-share-icon" />
          </div>}
      </div>
    </Wrapper>
  );
};

export default Index;

import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Images from '../../../assets/Images';
import atoms from '../../atoms'
import { RxCross2 } from 'react-icons/rx';
import { FiShare } from "react-icons/fi";

const { Button, Image } = atoms
const { SuccessIcon, Download, Subtract, } = Images


const PopUpModal = (props: any) => {
    return (
        <>
            <Modal className="h-100 d-flex justify-content-center align-items-center modal-m position-relative"
                isOpen={props.show}>
                <div className='w-100 d-flex justify-content-center'>
                    <Image src={SuccessIcon} className="img-fluid position-absolute success-icon" />
                </div>
                <ModalHeader className="d-flex justify-content-center border-0 position-relative" >
                    <div className="position-absolute py-1 px-2" style={{ top: '0', right: '0', backgroundColor: '#222', borderRadius: '0px 4px 0px 8px', cursor: 'pointer' }} onClick={props.onHide}><RxCross2 style={{ color: '#fff' }} /></div>
                </ModalHeader>
                <ModalBody className="rounded-10">
                    <h5 className='text-center fs-24 fw-700 mt-4 '>Order Place Successful!</h5>
                    <h5 className='text-center fs-18 fw-400 mt-3 '>Your Order has been successfully Placed.</h5>

                    <hr className='mt-5' />

                    <div className='mt-4'>
                        <h5 className='text-center fs-18 fw-500 mt-3 '>Total Payment</h5>
                        <h5 className='text-center fs-24 fw-700 mt-4 '>₹{props?.invoiceResponse?.total_payment}</h5>
                    </div>

                    {!(props?.invoiceResponse?.invoice_number) && <div className="d-flex align-items-center gap-3 px-4 checkout-popup text-center">
                        <h3 className='mb-0 fs-18 fw-500 '>Order ID</h3>
                        <h3 className='mb-0 fs-18 fw-500'>{props?.invoiceResponse?.order_id}</h3>
                    </div>}

                    {props?.invoiceResponse?.invoice_number && <div className="row">
                        <div className="col-12 col-md-6 mt-4">
                            <div className='checkout-popup'>
                                <h3 className='mb-0 fs-18 fw-500 '>Invoice Number</h3>
                                <h3 className='mb-0 fs-18 fw-500 mt-3 '>{props?.invoiceResponse?.invoice_number}</h3>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 mt-4">
                            <div className='checkout-popup'>
                                <h3 className='mb-0 fs-18 fw-500 '>Order ID</h3>
                                <h3 className='mb-0 fs-18 fw-500 mt-3 '>{props?.invoiceResponse?.order_id}</h3>
                            </div>
                        </div>
                    </div>}

                    <div className="row">
                        <div className="col-12 col-md-6 mt-4">
                            <div className='checkout-popup'>
                                <h3 className='mb-0 fs-18 fw-500 '>Payment Method</h3>
                                <h3 className='mb-0 fs-18 fw-500 mt-3 '>{props?.invoiceResponse?.payment_method}</h3>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 mt-4">
                            <div className='checkout-popup'>
                                <h3 className='mb-0 fs-18 fw-500 '>Customer Name</h3>
                                <h3 className='mb-0 fs-18 fw-500 mt-3 '>{props?.invoiceResponse?.customer_name}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='text-center mt-25'>


                        <Button className='rounded-2 px-4 bg-transparent dark-gray fs-18 fw-600 mt-4'
                            onClick={props.handleGeneratePdf}>
                            {/* <span><Image src={Download} /> </span> Get PDF Receipt */}
                            <span><FiShare className='mb-3 fs-30'  /> </span> Share Receipt

                        </Button>

                    </div>
                    <div className='w-100 d-flex justify-content-center'>

                        <div className=' position-absolute popup-bottom-design' style={{ backgroundImage: `url(${Subtract})` }}>
                        </div>
                    </div>
                </ModalBody>


            </Modal>




        </>



    )
}

export default PopUpModal
import React, { useEffect, useState } from 'react'
import atoms from '../../atoms'
import molecules from '../../molecules'
import { numberWithCommas } from '../../../constants/utils'
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { Checkbox } from '@mui/material'
import constant from '../../../constants/constant';

const { Input, Button } = atoms
const { ConfirmationModal } = molecules

const Index = ({ accountData, setAccountData, createAdvanceReceipt,
    createInvoice, cancelCart, accountDetails, setAccountDetails }: any) => {
    const [handleCancelModal, setHandleCancelModal] = useState<boolean>(false)
    const [handleLoyaltyModal, setHandleLoyaltyModal] = useState<boolean>(false)
    const [handleAdvanceReceipt, setHandleAdvanceReceipt] = useState<boolean>(false)
    const [handleInvoice, setHandleInvoice] = useState<boolean>(false)
    // const [handleAccountBalanceModal, setHandleAccountBalanceModal] = useState<boolean>(false)
    const [accountBalanceDisable, setAccountBalanceDisable] = useState<boolean>(false)
    const [invoicingDetailVisible, setInvoicingDetailVisible] = useState<boolean>(false)

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const param = searchParams.get('s');

    const handleChange = (name: string, value: any) => {
        setAccountData((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const isSubmitDisabled = (data: any) => {
        // Check if card amount is filled but card details are empty
        const isCardInvalid = data.cardAmount !== '' && data.cardDetail === '';

        // Check if UPI amount is filled but UPI details are empty
        const isUpiInvalid = data.upiAmount !== '' && data.upiDetails === '';

        // Check if both card and UPI are empty, and cash amount is also empty
        const isCashInvalid = data.cardAmount === '' && data.cardDetail === '' &&
            data.upiAmount === '' && data.upiDetails === '' &&
            data.cashAmount === '';
        // Disable button if any of the invalid conditions are true
        return isCardInvalid || isUpiInvalid || isCashInvalid;
    };

    const handleCreateAdvancePopup = () => {
        let parsedData;
        let data = sessionStorage.getItem("card_details")
        if (data) {
            parsedData = JSON.parse(data);
        }
        const payableAmount = parsedData.reduce((total: any, item: any) => total + item.product_srp, 0);
        if (payableAmount < Number(accountData.totalAmount)) {
            createAdvanceReceipt();
        } else {
            setHandleAdvanceReceipt(!handleAdvanceReceipt)
        }
    };

    const handleLoyaltyPointPopup = () => {
        let parsedData;
        let data = sessionStorage.getItem("card_details")
        if (data) {
            parsedData = JSON.parse(data);
        }
        const payableAmount = parsedData.reduce((total: any, item: any) => total + item.product_srp, 0);
        if (payableAmount < Number(accountData.totalAmount)) {
            createAdvanceReceipt();
        } else {
            setHandleLoyaltyModal(!handleLoyaltyModal)
        }
    };

    const handleCreateAdvanceReceipt = () => {
        createAdvanceReceipt();
        setAccountDetails({ ...accountDetails, redemption_request: false })
    }
    const handleInvoicePopup = () => {
        let parsedData;
        let data = sessionStorage.getItem("card_details")
        if (data) {
            parsedData = JSON.parse(data);
        }
        const needsInvoice = parsedData?.some((item: any) => !item.isDelivered);
        const payableAmount = accountDetails?.loyalty_points > 0 ? (parseFloat(accountDetails?.totalPayable) + (accountDetails?.redemption_request ? 0 : accountDetails?.loyalty_points)) : accountDetails?.totalPayable
        // const payableAmount = parsedData?.reduce((total: any, item: any) => total + item.product_srp, 0);

        if (!needsInvoice && Number(payableAmount )=== Number(accountData.totalAmount)) {
            setHandleInvoice(!handleInvoice)
        } else {
            createInvoice();
        }
    }

    const handleCreateInvoice = () => {
        createInvoice();
        setHandleInvoice(false)
    };

    const handleAccountBalanceModalSubmitButton = () => {
        // setHandleAccountBalanceModal(!handleAccountBalanceModal)
        // if (accountData?.cashAmount != '' || accountData?.upiAmount != '' || accountData?.cardAmount != '') {
        handleCreateAdvanceReceipt();
        // }
    }

    const toggleInvoicingDetail = () => {
        setInvoicingDetailVisible(!invoicingDetailVisible)
    }

    return (
        <div>
            <div className='checkout-card'>
                <h2 onClick={() => toggleInvoicingDetail()} className='mb-0 fs-18 fw-600 d-flex justify-content-between'>Invoicing & Delivery Details
                    <span>{invoicingDetailVisible ? <IoIosArrowDropup style={{ fontSize: "30px", color: "505d6f" }} /> : <IoIosArrowDropdown style={{ fontSize: "30px", color: "#505d6f" }} />}</span></h2>

                {invoicingDetailVisible && <>
                    <div className="row">
                        <div className="col-12 col-md-6 mt-3">
                            <Input type='text' label='Company Name' name='name' value={accountData.name} className={"custom-input-height "} placeholder={"Enter Name"} defaultValue={'product?.brand_margin'}
                                onChange={(e: any) => handleChange('name', e.target.value)} />
                        </div>
                        <div className="col-12 col-md-6 mt-4 ">
                            <Input type='number' label='Alternate Mobile Number' name='mobile' value={accountData.mobile} className={"custom-input-height"} placeholder={"Enter Alternate Number"} defaultValue={'product?.brand_margin'}
                                onChange={(e: any) => handleChange('mobile', e.target.value)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 mt-4 ">
                            <Input type='text' label='GST Number' name='gstNumber' value={accountData.gstNumber} className={"custom-input-height"} placeholder={"Enter GST Number"} defaultValue={'product?.brand_margin'}
                                onChange={(e: any) => handleChange('gstNumber', e.target.value)} />
                        </div>
                        <div className="col-12 col-md-6 mt-4 ">
                            <Input type='text' label='Address' name='address' value={accountData.address} className={"custom-input-height"} placeholder={"Enter Address"} defaultValue={'product?.brand_margin'}
                                onChange={(e: any) => handleChange('address', e.target.value)} />
                        </div>
                    </div>
                </>}
            </div>

            <div className='checkout-card mt-4'>
                <h2 className='mb-0 fs-18 fw-600'>Payment Details</h2>

                <div className="row">
                    <div className="col-12 col-md-6 mt-4 ">
                        <Input type='number' label='Card Amount' name='cardAmount' value={accountData.cardAmount} className={"custom-input-height"} placeholder={"Enter amount"} defaultValue={'product?.brand_margin'}
                            onChange={(e: any) => handleChange('cardAmount', e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mt-4 ">
                        <Input type='text' label='Card Details' name='cardDetail' value={accountData.cardDetail} className={"custom-input-height"} placeholder={"Enter Card Number"} defaultValue={'product?.brand_margin'}
                            onChange={(e: any) => handleChange('cardDetail', e.target.value)} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 mt-4 ">
                        <Input type='text' label='UPI Amount' name='upiAmount' value={accountData.upiAmount} className={"custom-input-height"} placeholder={"Enter Amount"} defaultValue={'product?.brand_margin'}
                            onChange={(e: any) => handleChange('upiAmount', e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mt-4 ">
                        <Input type='text' label='UPI Details' name='upiDetails' value={accountData.upiDetails} className={"custom-input-height"} placeholder={"Enter details"} defaultValue={'product?.brand_margin'}
                            onChange={(e: any) => handleChange('upiDetails', e.target.value)} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 mt-4 ">
                        <Input type='number' label='Cash Amount' name='cashAmount' value={accountData.cashAmount} className={"custom-input-height"} placeholder={"Enter Amount"} defaultValue={'product?.brand_margin'}
                            onChange={(e: any) => handleChange('cashAmount', e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label className='inputs-label mb-2 fs-16 fw-600 secondary-color d-block'>Account Balance</label>
                        <div className="row align-items-center">
                            {/* <div className='col-1 mb-3 d-flex justify-content-end'>
                                <Checkbox className='cursor-pointer loyalty-checkbox'
                                    onChange={(e: any) => setAccountBalanceDisable(e.target.checked)}
                                    id={'redeemId'}
                                    name='redeemId' />
                            </div> */}
                            <div className='col-12'>
                                <Input
                                    type='text'
                                    name='accountBalance'
                                    value={accountData.accountBalance}
                                    className={"custom-input-height"}
                                    placeholder={"Enter Amount"}
                                    defaultValue={'product?.brand_margin'}
                                    onChange={(e: any) => handleChange('accountBalance', e.target.value < -accountDetails?.accountBalance ? e.target.value : -accountDetails?.accountBalance > 0 ? -accountDetails?.accountBalance : 0)}
                                    disabled={!accountDetails?.accountBalance || parseInt(param || "") === constant.Account_Detail_Constant.PENDING_ORDER || !accountBalanceDisable} />
                                {accountDetails?.accountBalance ?
                                    <div className='d-flex gap-2 align-items-center'>
                                        <Checkbox className='cursor-pointer loyalty-checkbox'
                                            onChange={(e: any) => setAccountBalanceDisable(e.target.checked)}
                                            id={'redeemId'}
                                            disabled={accountDetails?.accountBalance > 0}
                                            name='redeemId' />
                                        <p className='p-0 m-0 fs-12 main-color fw-500'>
                                            Account Balance is ₹{numberWithCommas(parseFloat(accountDetails?.accountBalance) * (-1))}
                                        </p>
                                    </div> : ""
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12 mt-2">
                        <Input type='text' label='Total Amount' name='totalAmount' value={accountData.totalAmount} className={"custom-input-height"} disabled={true}
                            onChange={(e: any) => ''} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mt-4">
                        <Button className='account-page-btn w-100' onClick={() => { accountDetails?.redemption_request ? handleLoyaltyPointPopup() : handleCreateAdvancePopup() }}
                            isDisabled={isSubmitDisabled(accountData) && Number(accountDetails.totalAmount) > 0}
                        >Create Advance Receipt</Button>
                    </div>

                    <div className="col-12 col-md-6 mt-4">
                        <Button className='account-page-btn w-100' onClick={() => handleInvoicePopup()}
                            isDisabled={isSubmitDisabled(accountData) && Number(accountDetails.totalAmount) > 0}
                        >Create Invoice</Button>
                    </div>
                </div>
                {param == `${constant?.Account_Detail_Constant?.SALES_CART}` && <div className="d-flex flex-wrap gap-3 gap-md-4 mt-4 ">
                    <div className="w-100">
                        <Button className='cancel-btn w-100' onClick={() => setHandleCancelModal(!handleCancelModal)}
                        >Cancel</Button>
                    </div>
                </div>}
            </div>
            {
                handleCancelModal && <ConfirmationModal
                    show={handleCancelModal}
                    handleConfirmItem={() => cancelCart()}
                    onHide={() => setHandleCancelModal(!handleCancelModal)}
                    message={'cancel'} />
            }

            {
                handleLoyaltyModal && <ConfirmationModal
                    show={handleLoyaltyModal}
                    handleConfirmItem={() => { handleCreateAdvanceReceipt(); setHandleLoyaltyModal(!handleLoyaltyModal) }}
                    onHide={() => setHandleLoyaltyModal(!handleLoyaltyModal)}
                    message={'allow, that loyalty point will not be applicable while creating advance receipt'} />
            }

            {
                handleAdvanceReceipt && <ConfirmationModal
                    show={handleAdvanceReceipt}
                    handleConfirmItem={() => { handleCreateAdvanceReceipt(); setHandleAdvanceReceipt(!handleAdvanceReceipt) }}
                    onHide={() => setHandleAdvanceReceipt(!handleAdvanceReceipt)}
                    message={'create advance receipt'} />
            }

            {
                handleInvoice && <ConfirmationModal
                    show={handleInvoice}
                    handleConfirmItem={() => { handleCreateInvoice() }}
                    onHide={() => setHandleInvoice(!handleInvoice)}
                    message={'create Invoice'} />
            }

            {/* {
                handleAccountBalanceModal && <ConfirmationModal
                    show={handleAccountBalanceModal}
                    onHideButtonText={"Cancel"}
                    confirmButtonText={"OK"}
                    handleConfirmItem={() => handleAccountBalanceModalSubmitButton()}
                    onHide={() => setHandleAccountBalanceModal(!handleAccountBalanceModal)}
                    message={'Account Balance will not be applicable while creating advance receipt'} />
            } */}
        </div >
    )
}

export default Index
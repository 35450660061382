import React from 'react';
import './Badges.scss'
import { BudgesProps } from './interface';

const Image = ({ count, style, className }: BudgesProps) => {
    return (
        <>
           {(count && count>0) ? <span className={`${className ? className : "translate-middle"} custom-style text-white position-absolute p-1 bg-danger rounded-circle`}>
                {`${count}`} {/* <span className="visually-hidden">New alerts</span> */}
            </span> : ""}
        </>
    );
};

export default Image;

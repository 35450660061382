import React, { useEffect, useState } from 'react'
import './CustomSearch.scss'
import { FiSearch } from "react-icons/fi";

const Index = ({ setIsOpen, searchCallBack, placeholder, className, heading, buttonStyle }: any) => {
    const [searchVal, setSearchVal] = useState('')
    const [searchClicked, setSearchClicked] = useState(false)

    useEffect(() => {
        if (searchClicked) {
            if (searchVal?.length > 0) {
                searchCallBack(searchVal)
                setIsOpen(true)
            }
        }
        setSearchClicked(false)
    }, [searchClicked])

    return (
        <>
            <div className="position-relative">
                <span className='position-absolute search-icon'><FiSearch /></span>
                <input
                    className={[className, 'new-custom-search', ''].join(' ')}
                    autoComplete="off"
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={placeholder ? placeholder : 'Search'}
                    // defaultValue={searchKeyword}
                    onChange={(e) => {
                        setSearchVal(e.target.value?.replace(/\s/g, ''));
                    }}
                />
                <button className={`position-absolute shadow-none border-0 add-product-btn ${buttonStyle}`}
                    onClick={() => setSearchClicked(true)}
                >{heading}</button>
            </div>
        </>

    )
}

export default Index
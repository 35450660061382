import React, { useEffect, useState } from 'react'
import atoms from '../../atoms'
import molecules from '../../molecules'
import service from '../../../api/services'
import { useToasts } from 'react-toast-notifications'
import { useNavigate } from 'react-router'
import constant from '../../../constants/constant'
const { Input, Button } = atoms
const { SalesCard, BillingSummary } = molecules
const Index = ({ cart_data, getViewCart, customerInfo, setCustomerInfo,
    productList, setSearchProduct, billingSummary, setBillingSummary,
    handleSearchCustomer, selectedCartItems, setSelectedCartItems, setSearchType }: any) => {
    const cartData = cart_data
    const { addToast } = useToasts();
    const navigate = useNavigate()

    const [isName, setIsName] = useState<boolean>(false)
    const _handleSelectedCartItems = () => {
        const selectedItems = cartData?.cart_items?.map((item: any) => (
            {
                cart_item_id: item.id,
                product_srp: parseFloat(item?.product?.product_srp || item?.other_product_data?.price),
                discount: item?.discount,
                inventory_item_id: item?.inventory_item_id,
                isDelivered: (item.status == constant.CART_ITEM_STATUS.DELIVERED) ? true : false
            }
        ))
        setSelectedCartItems(selectedItems)


    }

    const calculateTotalPayable = () => {
        // Ensure discount and round-off are not negative
        const validDiscount = billingSummary?.totalDiscount >= 0 ? billingSummary?.totalDiscount : 0;
        const validRoundOff = billingSummary?.roundOff ? billingSummary?.roundOff : 0;
        const loyaltyPoints = billingSummary?.loyalty_points >= 0 ? billingSummary?.loyalty_points : 0;

        // Calculate the total payable amount
        const totalPayable = billingSummary?.subTotal - validDiscount - validRoundOff - (billingSummary?.redemption_request && loyaltyPoints);
        const totalSalesTo = billingSummary?.subTotal - validDiscount - validRoundOff

        // Make sure total payable is not negative
        const finalTotalPayable = totalPayable >= 0 ? totalPayable : 0;
        setBillingSummary({ ...billingSummary, totalSales: finalTotalPayable, totalSalesToShow: totalSalesTo })

    }


    const calculateTotalDiscount = () => {
        const totalDiscount = (selectedCartItems || []).reduce((total: number, obj: { discount: any }) => obj.discount >= 0 ? total + obj.discount : 0, 0);
        setBillingSummary({ ...billingSummary, totalDiscount: totalDiscount })
    }

    const _handleCheckout = async () => {
        const payload = {
            customer_name: customerInfo?.name,
            customer_mobile: customerInfo?.mobile,
            round_off_amount: billingSummary.roundOff,
            cart_items: selectedCartItems,
            redeemed_points: billingSummary?.redemption_request ? billingSummary?.loyalty_points : 0,
            redemption_request: billingSummary?.redemption_request
        }

        var accDetails = {
            name: customerInfo?.name,
            mobile: customerInfo?.mobile,
            totalPayable: billingSummary?.totalSales,
            accountBalance: customerInfo?.accountBalance,
            loyalty_points: (billingSummary?.redemption_request ? billingSummary?.loyalty_points : 0),
            redemption_request: billingSummary?.redemption_request
        };
        var accDetailsString = JSON.stringify(accDetails);
        sessionStorage.setItem('account_details', accDetailsString);

        // To add cart data to session storage
        var cartItem = JSON.stringify(selectedCartItems);
        sessionStorage.setItem('card_details', cartItem);

        if (!hasDuplicateInventoryItemId(selectedCartItems)) {
            if (!(customerInfo?.name?.length <= 0)) {
                try {
                    const response = await service.SalesCart.checkout(payload)
                    if (response.status === 200) {
                        navigate(`/account-details?s=${constant?.Account_Detail_Constant?.SALES_CART}`)
                    }
                } catch (error: any) {
                    addToast(error?.response?.data?.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    console.log('error', error)
                }
            } else {
                setIsName(true)
            }
        } else {
            addToast('Same barcode cant be assigned to different products ', {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const hasDuplicateInventoryItemId = (data: any) => {
        const inventoryItemIds = new Set();

        for (const item of data) {
            const inventoryItemId = item.inventory_item_id;
            // Check if inventory_item_id is already in the Set
            if (item.isDelivered == true && inventoryItemId !== null && inventoryItemId !== undefined && inventoryItemIds.has(inventoryItemId)) {
                return true; // Duplicate found
            }

            // Add inventory_item_id to the Set
            inventoryItemIds.add(inventoryItemId);
        }

        return false; // No duplicates found
    }

    useEffect(() => {
        if (customerInfo?.mobile?.length <= 0) {
            setCustomerInfo({
                name: '',
                mobile: '',
            })
        }
    }, [customerInfo?.mobile])

    useEffect(() => {
        _handleSelectedCartItems()
    }, [cartData])
    useEffect(() => {
        calculateTotalPayable()
    }, [billingSummary.roundOff, billingSummary.totalDiscount, billingSummary?.redemption_request])
    useEffect(() => {
        calculateTotalDiscount()
    }, [selectedCartItems])

    return (
        <div className="row mt-md-3 mt-1 position-relative">
            <div className="col-12 col-md-8 sales-card-side-container-height">
                <div className="row mt-1">
                    <div className="col-6">
                        <div className="position-relative">
                            <label className='inputs-label mb-2 fs-md-16 fs-14 fw-600  secondary-color' >Mobile Number</label>
                            <input
                                type='number'
                                className={"inputs form-control w-100 fs-16 sales-input-height"}
                                placeholder={"Enter Number"}
                                value={customerInfo?.mobile}
                                onChange={(e: any) => { handleSearchCustomer(e.target.value); }}
                                onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement).blur()}
                            />

                            {/* <Button className='position-absolute shadow-none border-0 add-customer-btn'
                                onClick={() => handleSearchCustomer()}
                            >{'Search Customer'}</Button> */}
                        </div>
                        {(!customerInfo?.name && customerInfo?.isSearch) && <p className='p-0 m-0 text-danger fs-12 fw-500'>No customer found</p>}
                    </div>
                    <div className="col-6">
                        <Input type='text' label='Customer Name' className={"remove-min-height custom-input-height fs-16"}
                            placeholder={"Enter Name"} value={customerInfo?.name}
                            labelStyle="fs-md-16 fs-14"
                            onChange={(e: any) => {
                                setCustomerInfo({ ...customerInfo, name: e.target.value })
                                setIsName(false)
                            }
                            } />
                        {(!customerInfo?.name && isName) ? (
                            <p className='p-0 m-0 text-danger fs-md-12 fs-10 fw-500'>Customer name required</p>) : (
                            (customerInfo?.loyalty_points >= 0 ? <p className='p-0 m-0 main-color fs-12 fw-500'>{`Loyalty points ${customerInfo?.loyalty_points}`}</p> : ""))}
                    </div>
                </div>
                <div className="mt-md-4 mt-2">
                    <SalesCard
                        cart_Data={cartData}
                        customerInfo={customerInfo}
                        getViewCart={getViewCart}
                        productList={productList}
                        setSearchProduct={setSearchProduct}
                        setSelectedCartItems={setSelectedCartItems}
                        setBillingSummary={setBillingSummary}
                        setSearchType={setSearchType}
                    />
                </div>
            </div>
            <div className="col-12 col-md-4 position-sticky "
            >
                <BillingSummary _handleCheckout={_handleCheckout}
                    setBillingSummary={setBillingSummary}
                    billingSummary={billingSummary}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                />
            </div>
        </div>
    )
}

export default Index
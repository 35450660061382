import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { dashboard } = Dictionary

const DashboardService = {
    dashboard: async () => {
        return axios.get(BASE_URL + dashboard.dashboard(),)
    },

}
export default DashboardService


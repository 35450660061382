import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import organisms from '../../../components/organisms'
import molecules from '../../../components/molecules'
import service from '../../../api/services'
import { handleFileDownload } from '../../../constants/utils'
import { ShowActiveTab } from '../../../Context'
import { Default_Page_Limit } from '../../../constants/defaultValues'
import { useToasts } from 'react-toast-notifications'

const { PaymentVoucherHistory } = organisms
const { TopBar } = molecules
const Index = () => {
  const { addToast } = useToasts();
  const [paymentVoucherHistoryData, setPaymentVoucherHistoryData] = useState<any>()
  const [displayedData, setDisplayedData] = useState([]);
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const { setActiveTab }: any = useContext(ShowActiveTab)

  useEffect(() => {
    setActiveTab("Payment Voucher History")
    getClaimProductHistoryList()
  }, [])

  const getClaimProductHistoryList = async () => {
    try {
      const response = await service.PaymentVoucherService.paymentVoucherHistory()
      if (response.status === 200) {
        setPaymentVoucherHistoryData(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSearch = () => {
    const arr: any = [];
    const newArr = paymentVoucherHistoryData?.data?.find((item: any, index: number) => {
      if (item?.customer?.mobile.search(search) !== -1) {
        arr.push(item);
      }
    });
    setDisplayedData(arr)
  }

  const handleDownload = async (id: string, share?: boolean) => {
    try {
      let response;
      if (share) {
        response = await service.PaymentVoucherService.shareVoucherHistory(id, { share });
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
      });
      } else {
        response = await service.PaymentVoucherService.downloadVoucherHistory(id);
        if (response.status === 200) {
          handleFileDownload(response?.data?.data?.path)
        }
      }

    } catch (error:any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
    });
    }
  }


  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Payment Voucher History' />
      </div>
      <div className='mt-3'>
        <PaymentVoucherHistory
          cancelledOrderData={paymentVoucherHistoryData}
          limitPerPage={limitPerPage}
          currentPage={currentPage}
          setSearch={(e: string) => setSearch(e)}
          setLimitPerPage={(limit: number) => setLimitPerPage(limit)}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          displayedData={displayedData}
          setDisplayedData={setDisplayedData}
          handleSearch={() => handleSearch()}
          handleDownload={(id: string, share?: boolean) => handleDownload(id, share)}
        />
      </div>
    </Wrapper>
  )
}

export default Index
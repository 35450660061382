import React, { useContext, useState } from 'react'
import Images from '../../../assets/Images'
import { Tooltip } from 'react-tooltip'
import constant from '../../../constants/constant';
import { findConstantLabel, numberWithCommas, getDropdown } from '../../../constants/utils'
import atoms from '../../atoms'
import molecules from '../../molecules';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router';
import { SalesCartTimer } from '../../../Context';
import { issueNewProductInterface } from './interface'
const { Button, Select, Checkbox } = atoms
const { Copy } = Images
const { CreateModal, ConfirmationModal } = molecules

const Index = ({ productDetails, _handleProductId, title, issueNewProduct, issueCreditNote, requestProduct }: issueNewProductInterface) => {
    const { addToast } = useToasts();
    const navigate = useNavigate()
    const [barCodeId, setBarCodeId] = useState<any>()
    const [idDelivered, setIdDelivered] = useState(false)
    const [issueProductConfirmation, setIssueProductConfirmation] = useState<boolean>(false)
    const [creditNoteConfirmation, setCreditNoteConfirmation] = useState<boolean>(false)
    const productData = productDetails
    const colors = productDetails?.extra_details?.variants;
    const { salesCartTimer, setSalesCartTimer }: any = useContext(SalesCartTimer)

    // const coloredDivs = colors && colors.map((color: any, index: number) => {
    //     if (color.specx_colors.length === 2) {
    //         // Handle the case where there are two colors
    //         const [color1, color2] = color.specx_colors;
    //         return (
    //             <div className={color?.brand_color_code == productDetails?.brand_color_code ? 'p-1 active-border-color rounded-circle' : 'p-1 border border-white rounded-circle'}
    //                 onClick={() => { _handleProductId(color.product_id) }}>
    //                 <div key={`color${index}`} className="diagonal-color-div cursor-pointer"
    //                     data-tooltip-id={`color${index}`}
    //                     data-tooltip-content={`${color.brand_color_name}, ${color.brand_color_code}`}

    //                 >
    //                     <div
    //                         className="diagonal-color-div cursor-pointer rounded-circle"
    //                         style={{ backgroundColor: color1.color_code }}
    //                     ></div>

    //                     <Tooltip id={`color${index}`} />
    //                 </div>
    //             </div>
    //         );
    //     } else if (color.specx_colors.length === 1) {
    //         // Handle the case where there is only one color
    //         const singleColor = color.specx_colors[0];
    //         return (
    //             <div className={
    //                 (color?.brand_color_code == productDetails?.brand_color_code) ? 'p-1 active-border-color rounded-circle' : 'p-1 border border-white rounded-circle'}
    //                 onClick={() => { _handleProductId(color.product_id) }}>
    //                 <div key={`color${index}`}
    //                     style={{ backgroundColor: singleColor.color_code }}
    //                     className="color-div cursor-pointer"
    //                     data-tooltip-id={`color${index}`}
    //                     // data-tooltip-content={color.brand_color_name}
    //                     data-tooltip-content={`${color.brand_color_name}, ${color.brand_color_code}`}>
    //                     <Tooltip id={`color${index}`} />
    //                 </div>
    //             </div>

    //         );
    //     } else {
    //         return null; // Handle other cases or add error handling as needed
    //     }
    // });


    const handleIssueNewProduct = () => {
        issueNewProduct(barCodeId?.value);
    }

    const hadleIssueCreditNote = () => {
        issueCreditNote()
    }
    const coloredDivs = colors && colors.map((color: any, index: number) => {
        if (color.specx_colors.length > 0) {
            // Handle the case where there is only one color
            const singleColor = color.specx_colors[0];
            return (
                <div className={
                    (color?.brand_color_code == productDetails?.brand_color_code) ? 'p-1 active-border-color rounded-circle' : 'p-1 border border-white rounded-circle'}
                    onClick={() => { _handleProductId(color.product_id) }}>
                    <div key={`color${index}`}
                        style={{ backgroundColor: singleColor?.color_code }}
                        className="color-div cursor-pointer"
                        data-tooltip-id={`color${index}`}
                        // data-tooltip-content={color.brand_color_name}
                        data-tooltip-content={`${color.brand_color_name}, ${color.brand_color_code}`}>
                        <Tooltip id={`color${index}`} />
                    </div>
                </div>

            );
        } else {
            return null; // Handle other cases or add error handling as needed
        }
    });




    // const singleColor = JSON?.parse(sessionStorage?.getItem("outOfStockData") || "")
    let singleColor: any;

    try {
        const dataFromSessionStorage = sessionStorage?.getItem("outOfStockData");
        singleColor = dataFromSessionStorage ? JSON.parse(dataFromSessionStorage) : null;
    } catch (error) {
        console.error("Error parsing JSON:", error);
        singleColor = null;
    }
    const coloredDivUnavailable = () => {
        // Handle the case where there is only one color
        let index = 1
        return (
            <div className={
                (singleColor?.brand_color_code == productDetails?.brand_color_code) ? 'p-1 active-border-color rounded-circle' : 'p-1 border border-white rounded-circle'}
                onClick={() => { _handleProductId(singleColor?.id) }}>
                <div key={`color${index}`}
                    style={{ backgroundColor: singleColor?.color_code }}
                    className="color-div cursor-pointer"
                    data-tooltip-id={`color${index}`}
                    // data-tooltip-content={color.brand_color_name}
                    data-tooltip-content={`${singleColor?.brand_color_name}, ${singleColor?.brand_color_code}`}>
                    <Tooltip id={`color${index}`} />
                </div>
            </div>

        );
    };

    const _handleIsDelivered = (e: boolean) => {
        setIdDelivered(e);
        setBarCodeId("")

    }

    const isButtonDisabled = (productData: any, idDelivered: boolean, barCodeId: any): boolean => {
        return productData?.out_of_stock || (productData?.available_at_store && (!idDelivered || !barCodeId?.value));
    }





    return (
        <>
            <div className="row mt-md-4 mt-2">
                <div className="col-md-7 mt-4 mt-md-3 order-2 order-md-1">
                    <h2 className='mb-0 fs-md-20 fs-12 fw-600'>{productData?.brand?.brand_name}</h2>
                    <h2 className='mb-0 fs-md-24 fs-12 fw-700 mt-2'>{title}</h2>
                    <h2 className='mb-0 fs-md-24 fs-12 fw-500 mt-md-3 mt-2'>₹{numberWithCommas(parseFloat(productData?.product_srp?.toFixed(2)))}</h2>
                </div>

                <div className="d-none d-md-block col-md-5 mt-4 mt-md-3 order-1 order-md-2">
                    <div className='d-flex align-items-center justify-content-start justify-content-md-end gap-2'>
                        <h2 className='mb-0 fs-16 fw-600'>Product ID :-</h2>
                        <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                            <h2 className='mb-0 fs-16 fw-400'>{productData?.product_id_display}</h2>
                            <img src={Copy} alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex gap-3 mt-md-3 mt-2'>
                <h2 className='mb-0 fs-md-18 fs-12 fw-600' style={{ minWidth: "7vw" }}>Product Color :</h2>
                <div className='d-flex gap-2' style={{ flexWrap: "wrap" }}>
                    {singleColor?.id?.length > 0 && coloredDivUnavailable()}
                    {coloredDivs}
                </div>

            </div >
            <div className='d-flex align-items-center gap-3 mt-md-4 mt-2'>
                <h2 className='fs-md-18 fs-12 fw-700 my-auto'>Glass Size :</h2>
                <div className='d-flex gap-2'>
                    {productDetails?.extra_details?.glass_sizes?.map((item: any, index: number) => {
                        return (
                            <p className='glass-div cursor-pointer fw-700 fs-md-18 fs-12 my-auto' key={`glass${index}`}
                                style={{
                                    borderColor: (productDetails?.glass_size == item?.glass_size) ? "#05b7e4" : "black",
                                    backgroundColor: (productDetails?.glass_size == item?.glass_size) ? "#05b7e4" : "#fff",
                                    color: (productDetails?.glass_size == item?.glass_size) ? "#fff" : "black"

                                }}
                                onClick={() => _handleProductId(item.product_id)}
                            >{item?.glass_size}</p>
                        )
                    })}
                    {productDetails?.out_of_stock &&
                        <p className='glass-div cursor-pointer fw-700 fs-md-18 fs-12 my-auto' key={`glass`}
                            style={{
                                borderColor: (productDetails?.glass_size == singleColor?.glass_size) ? "#05b7e4" : "black",
                                backgroundColor: (productDetails?.glass_size == singleColor?.glass_size) ? "#05b7e4" : "#fff",
                                color: (productDetails?.glass_size == singleColor?.glass_size) ? "#fff" : "black"

                            }}
                            onClick={() => _handleProductId(singleColor.id)}
                        >{singleColor?.glass_size}</p>

                    }
                </div>
            </div>

            {productData?.isPolarised !== constant.POLARIZED[2].value && <div className='d-flex align-items-center gap-3 mt-md-3 mt-2'>
                <h2 className='mb-0 fs-md-18 fs-12 fw-700'>Polarized</h2>
                {productDetails?.extra_details?.polarized?.map((item: any, index: number) => {
                    return (
                        <>
                            {item?.isPolarised == constant.POLARIZED[0].value && <div className='d-flex gap-2'>
                                <label className='d-flex align-items-center gap-1'>
                                    <input type="radio" name="polarized1" value={1} onChange={() => _handleProductId(item?.isPolarised == constant.POLARIZED[0].value && item.product_id)}
                                        checked={productData?.isPolarised === constant.POLARIZED[0].value} />
                                    <span
                                        className={!productDetails?.extra_details?.polarized[0] ? 'fs-md-18 fs-12 fw-500 text-secondary' : 'fs-md-18 fs-12 fw-500'}
                                    > Yes</span>
                                </label>
                            </div>}
                            {item?.isPolarised == constant.POLARIZED[1].value && <div className='d-flex gap-2'>
                                <label className='d-flex align-items-center gap-1'>
                                    <input type="radio" name="polarized1" value={2}
                                        onChange={() => _handleProductId(item?.isPolarised == constant.POLARIZED[1].value && item.product_id)}
                                        checked={productData?.isPolarised === constant.POLARIZED[1].value}
                                    />
                                    <span
                                        className={productDetails?.extra_details?.polarized[0] == 2 ?
                                            'fs-md-18 fs-12 fw-500 text-secondary' : 'fs-md-18 fs-12 fw-500'}
                                    > No</span>
                                </label>
                            </div>}
                        </>
                    )
                })}
                {productDetails?.out_of_stock &&
                    <>
                        {singleColor?.isPolarised == constant.POLARIZED[0].value && <div className='d-flex gap-2'>
                            <label className='d-flex align-items-center gap-1'>
                                <input type="radio" name="polarized1" value={1} onChange={() => _handleProductId(singleColor?.isPolarised == constant.POLARIZED[0].value && singleColor.product_id)}
                                    checked={productData?.isPolarised === constant.POLARIZED[0].value} />
                                <span
                                    className={!productDetails?.extra_details?.polarized[0] ? 'fs-18 fw-500 text-secondary' : 'fs-18 fw-500'}
                                > Yes</span>
                            </label>
                        </div>}
                        {singleColor?.isPolarised == constant.POLARIZED[1].value && <div className='d-flex gap-2'>
                            <label className='d-flex align-content-center gap-1'>
                                <input type="radio" name="polarized1" value={2}
                                    onChange={() => _handleProductId(singleColor?.isPolarised == constant.POLARIZED[1].value && singleColor.product_id)}
                                    checked={productData?.isPolarised === constant.POLARIZED[1].value}
                                />
                                <span
                                    className={productDetails?.extra_details?.polarized[0] == 2 ?
                                        'fs-18 fw-500 text-secondary' : 'fs-18 fw-500'}
                                > No</span>
                            </label>
                        </div>}
                    </>
                }
            </div>}

            <div className="row align-items-center">
                <div className='col-md-6 row'>
                    <div className="col-6 mt-sm-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Bridge Size :</h2>
                            <span className='fs-md-18 fs-12 fw-400'>{productData?.bridge_size}</span>
                        </div>
                    </div>
                    <div className="col-6 mt-sm-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Temple Size :</h2>
                            <span className='fs-md-18 fs-12 fw-400'>{productData?.temple_size}</span>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 row'>
                    <div className="col-6 mt-sm-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Lense Color  :</h2>
                            {productData?.lens_colors?.map((item: any, index: number) => {
                                return (
                                    <div key={`color${index}`}
                                        className="color-div "
                                        style={{ backgroundColor: item.color_code, borderRadius: '0px' }}>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-6 mt-sm-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Warranty :</h2>
                            <span className='fs-md-18 fs-12 fw-400'>
                                {findConstantLabel(constant?.PRODUCT_WARRANTY, productData?.warranty)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6 row">
                <div className="col-6 mt-sm-3 mt-1 pr-0">
                    <div className="d-flex flex-wrap align-items-center gap-3 ">
                        <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Product with case :</h2>
                        <span className='fs-md-18 fs-12 fw-400'>{productData?.product_with_case ? 'Yes' : 'No'}</span>
                    </div>
                </div>
                <div className="col-6 mt-sm-3 mt-1 ps-0">
                    <div className="d-flex gap-md-3 gap-1 flex-wrap align-items-center">
                        <h2 className='mb-0 fs-md-18 fs-12 fw-600 gap-md-3 gap-1'>Features :</h2>
                        {productData?.features && productData?.features?.map((item: any, index: number) => {
                            return (
                                <span className='fs-md-18 fs-12 fw-400'
                                >{`${findConstantLabel(constant.PRODUCT_FEATURES, item)}${index < (productData?.features?.length - 1) ? "," : ""}`}</span>
                            )
                        })}
                    </div>
                </div>

            </div>
            {(productData?.available_at_store && !productData?.out_of_stock) && <div className='row mt-3'>
                <div className='col-12 col-md-3 d-flex flex-wrap align-items-center gap-3'>
                    <label className='d-flex cursor-pointer w-100' htmlFor='idDelivered' style={{ cursor: 'pointer' }}>
                        <input
                            type='checkbox'
                            id='idDelivered'
                            disabled={!productData?.available_at_store}
                            style={{ width: "20px" }}
                            onChange={(e: any) => _handleIsDelivered(e.target.checked)}
                        />
                        <h2 className={`mb-0 fs-md-18 fs-12 fw-600 px-md-3 ps-1 ${productData?.available_at_store ? 'inherit' : 'secondary-color'}`}>Product Is Delivered</h2>
                    </label>
                </div>
                <div className='col-12 col-md-9 mt-md-3 mt-2'>
                    <div className='row align-items-center gap-1'>
                        <label className={`inputs-label mb-2 fs-md-18 fs-12 fw-600 warranty-barcode-width ${(productData?.available_at_store && idDelivered) ? 'inherit' : 'secondary-color'}`}
                            htmlFor="barcode">Enter Barcode :</label>
                        <Select className='sales-custom-select w-25 ps-0'
                            isDisabled={!(productData?.available_at_store && idDelivered)}
                            options={getDropdown(productData?.barcodes, 'bar_code')}
                            value={barCodeId}
                            onChange={(e: any) => setBarCodeId(e)}
                        />
                    </div>
                </div>
            </div>}

            <div className="row my-md-4 my-1">
                <div className="col">
                    <div className="d-block align-items-center gap-3 ">
                        <h2 className='mb-0 fs-md-20 fs-12 fw-600 mb-md-3 mb-1'>Product Description :</h2>
                        <span className='fs-md-18 fs-12 fw-500 '>{productData?.description}</span>
                    </div>

                </div>
            </div>
            <div className="row my-md-4 mb-3">
                <div className="col-6 col-md mt-md-3 mt-2">
                    <Button name={window.innerWidth < 768 ? ' Credit Note' : 'Give Credit Note'}
                        isDisabled={!productData?.out_of_stock}
                        onClick={() => setCreditNoteConfirmation(!creditNoteConfirmation)}
                        className={`w-100 background-dark-gray fw-700 fs-18`} />

                    {/* <Button name={'Give Credit Note'}
                        isDisabled={!productData?.out_of_stock}
                        onClick={() => issueCreditNote()}
                        className={`w-100 background-dark-gray fw-700 fs-18`} /> */}
                </div>
                <div className="col-6 col-md mt-md-3 mt-2">
                    <Button name={'Proceed'}
                        isDisabled={isButtonDisabled(productData, idDelivered, barCodeId)}
                        onClick={() => productData?.available_at_store ? setIssueProductConfirmation(!issueProductConfirmation) : requestProduct()}
                        className={`w-100 background-dark-gray fw-700 fs-18`} />

                    {/* <Button name={'Proceed'}
                        isDisabled={isButtonDisabled(productData, idDelivered, barCodeId)}
                        onClick={() => productData?.available_at_store ? issueNewProduct(barCodeId?.value) : requestProduct()}
                        className={`w-100 background-dark-gray fw-700 fs-18`} /> */}

                </div>

            </div>

            {
                creditNoteConfirmation && <ConfirmationModal
                    show={creditNoteConfirmation}
                    onHideButtonText={"Cancel"}
                    confirmButtonText={"OK"}
                    handleConfirmItem={() => { hadleIssueCreditNote(); setCreditNoteConfirmation(!creditNoteConfirmation) }}
                    onHide={() => setCreditNoteConfirmation(!creditNoteConfirmation)}
                    message={'issue credit note'} />
            }

            {
                issueProductConfirmation && <ConfirmationModal
                    show={issueProductConfirmation}
                    onHideButtonText={"Cancel"}
                    confirmButtonText={"OK"}
                    handleConfirmItem={() => { handleIssueNewProduct(); setIssueProductConfirmation(!issueProductConfirmation) }}
                    onHide={() => setIssueProductConfirmation(!issueProductConfirmation)}
                    message={'proceed'} />
            }
        </>
    )
}

export default Index
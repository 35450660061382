import React, { useState, useEffect , useContext} from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
import service from '../../../api/services';
import { ShowActiveTab } from "../../../Context"
const { TopBar } = molecules
const { Warranty } = organisms

const Index = () => {
  const { setActiveTab }: any = useContext(ShowActiveTab)
  const [warrantyProductData, setWarrantyProductData] = useState<any>()
  const [searchDetail, setSearchDetail] = useState({
    invoice_number: "",
    modal_number: "",
  })

  const warrantyProductList = async () => {
    try {
      const response = await service?.warrantyService?.warrantyProductList({ invoice_number: searchDetail?.invoice_number, modal_number: searchDetail?.modal_number })
      if (response.status === 200) {
        setWarrantyProductData(response?.data?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleChange = (name: string, value: string) => {
    setSearchDetail({ ...searchDetail, [name]: value })
  }

  useEffect(() => { setActiveTab("New Claim") }, [])

  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='New Warranty' />
      </div>
      <div className='mt-3'>
        <Warranty
          warrantyProductData={warrantyProductData}
          warrantyProductList={() => warrantyProductList()}
          handleChange={(name: string, value: string) => handleChange(name, value)}
          searchDetail={searchDetail}
        />
      </div>
    </Wrapper>
  )
}

export default Index
import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import { ShowActiveTab } from '../../../Context'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
import { handleFileDownload } from '../../../constants/utils'
import { Default_Page_Limit } from '../../../constants/defaultValues'
import service from '../../../api/services';
import { useToasts } from 'react-toast-notifications';
import { BASE_URL } from "../../../constants/defaultValues";
const { TopBar } = molecules
const { SalesReturnHistory } = organisms

const Index = () => {
  const {addToast}= useToasts();
  const [initialLoading, setInitialLoading] = useState(false)
  const [salesReturnHistoryList, setSalesReturnHistoryList] = useState<any>()
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);

  const { setActiveTab }: any = useContext(ShowActiveTab)

  const salesReturnListApi = async (search: string) => {
    try {
      const response = await service?.salesReturnService?.salesReturnHistoryList({ search: search, page: currentPage, limit: limitPerPage })
      if (response.status === 200) {
        setSalesReturnHistoryList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const downloadCreditNote = async (id: string, share?: boolean) => {
    try {
      let response: any;
      if (share) {
        response = await service.salesReturnService.shareCreditNotesPdf(id, { share });
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
      });
      } else {
        response = await service.salesReturnService.downloadCreditNotesPdf(id);
        if (response?.status === 200) {
          handleFileDownload(response?.data?.data?.path)
        }
      }

    } catch (error:any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
    });
    }
  }
  // const downloadCreditNote = async(id:string, share?:boolean) => {

  //   try {
  //     let response = await service.salesReturnService.downloadCreditNotesPdf(id)
  //     if (response.status === 200) {
  //       let path = response.data.data.path
  //       let fullPath = `${BASE_URL}${path}`
  //       let cleanPAth = fullPath.replace(/api\/\.\//, '')
  //       let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
  //       // window.open(`${cleanPAth}`, '_blank')

  //       setTimeout(() => {
  //         fetch(`${cleanPAth}`).then((response) => {
  //           response.blob().then((blob) => {

  //             // Creating new object of PDF file
  //             const fileURL =
  //               window.URL.createObjectURL(blob);

  //             // Setting various property values
  //             let alink = document.createElement("a");
  //             alink.href = fileURL;
  //             alink.download = `${fileName}`;
  //             alink.click();
  //           });
  //         });
  //       }, 2000);

  //     }
  //   } catch (error) {
  //     console.log('error', error)
  //   }

  // }


  useEffect(() => {
    setActiveTab("sales return history")
    setCurrentPage(1)
    if (currentPage == 1 && initialLoading) {
      salesReturnListApi("")
    }
    setInitialLoading(true)
  }, [limitPerPage])

  useEffect(() => {
    salesReturnListApi("")
  }, [currentPage])


  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Sales Return History' />
      </div>
      <div className='row mt-3'>
        <SalesReturnHistory
          salesReturnHistoryList={salesReturnHistoryList}
          salesReturnListApi={(search: string) => salesReturnListApi(search)}
          currentPage={currentPage}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          setLimitPerPage={(limit: number) => setLimitPerPage(limit)}
          limitPerPage={limitPerPage}
          downloadCreditNote={(id: string, share?: boolean) => downloadCreditNote(id, share)} />
      </div>
    </Wrapper>
  )
}

export default Index
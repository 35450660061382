import React, {useContext} from 'react';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import service from '../../../api/services';

interface Product {
  id: number;
  name: string;
}

export interface CheckboxState {
  products: Product[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CheckboxState = {
  products: [],
  status: 'idle',
  error: null,
};

// Async thunk to fetch product list from an API
export const fetchFilterList = createAsyncThunk< any[], void,  { rejectValue: string } >(
  'filterList/fetchFilterList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.Product.filter();
      if (response.status === 200) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch filter list');
      }
    } catch (error:any) {
      return rejectWithValue(error.message);
    }
  }
);

const filterListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilterList.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchFilterList.fulfilled, (state, action: PayloadAction<Product[]>) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchFilterList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch products';
      });
  },
});

export default filterListSlice.reducer;

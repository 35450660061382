import atoms from '../../../atoms'
import moment from 'moment'
import constant from '../../../../constants/constant'
import { numberWithCommas } from '../../../../constants/utils'
import { generatePdf } from '../../../../constants/utils'
import { FaPrint } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import ThreeDots from '../../../../assets/Images/threeDots.svg'
import { useNavigate } from 'react-router'
import {deliveredProps, deliveredData} from './interface'
const { Search, Table, Button, Pagination, Image } = atoms

const Index = ({ receiptData, setSearch, setLimitPerPage, setCurrentPage, currentPage, limit,handlePrintSharePdf }: deliveredProps) => {
    const navigate = useNavigate()
    const columns = [
        { name: 'Date', selector: (row: deliveredData) => moment(row?.createdAt).format('DD/MM/YYYY'), width: '100px' },
        { name: 'Order Id', selector: (row: deliveredData) => row?.order_id_display, width: '100px' },
        { name: 'Invoice Number', selector: (row: deliveredData) => row?.invoice_number, width: '100px' },
        { name: 'Sold By', selector: (row: deliveredData) => row?.staff.username, width: '140px' },
        { name: 'Customer Name', selector: (row: deliveredData) => row?.customer.name || '-', width: '120px' },
        { name: 'Customer Contact', selector: (row: deliveredData) => row?.customer.mobile || '-', width: '100px' },
        { name: 'GST Number', selector: (row: deliveredData) => row?.gst_number || '-', width: '100px' },
        { name: 'Alternate Mobile', selector: (row: deliveredData) => row?.alternate_mobile || '-', width: '100px' },
        { name: 'Delivery Address', selector: (row: deliveredData) => row?.delivery_address || '-', width: '150px', },
        {
            name: 'Total Amount(₹)', selector: (row: deliveredData) => <>
                <div>
                    <p className='mb-1 ' >Order Value : {`₹${numberWithCommas(parseFloat(row.total_amount?.toFixed(2)))}`}</p>
                    {/* <p className='mb-1 '>Advance Paid : {`₹${numberWithCommas(parseFloat(row.total_paid_amount?.toFixed(2)))}`}</p> */}
                    {/* <p className='mb-1 '>Pending Amount : {row.total_amount - row.total_paid_amount}</p> */}
                </div>
            </>, width: '180px'
        },
        { name: 'Total Items', selector: (row: deliveredData) => row?.total_items, width: '120px' },
        { name: 'Status', selector: (row: any) => row?.order_status || '-', width: '110px' },
        { name: 'Action', selector: (row: any) => row?.action, center: true, width: '110px' },


    ];
    return (
        <>
            <div className='row mt-md-0 mt-3'>
                <div className='col-md-6'>
                    <Search className='search search-style w-100' searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
                <div>
                    <Table
                        columns={columns}
                        style={{overflow:"scroll",width:"54vw"}}
                        data={receiptData?.data?.map((item: deliveredData) => {
                            return ({
                                ...item,
                                total_items: <div>
                                    {item.order_status !== constant.ORDER_STATUS.DELIVERED && <p className='mb-1 ' >Total Order : {item.total_order_items}</p>}
                                    <p className='mb-1 '>Delivered : {item.total_delivered_order_items}</p>
                                    {/* <p className='mb-1 '>Pending : {row.total_pending_order_items}</p> */}
                                </div>,
                                order_status: item.order_status === constant.ORDER_STATUS.PENDING ? (
                                    <div className="background-light-yellow border-product-radius custom-badge"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.order_status === constant.ORDER_STATUS.DELIVERED ?
                                    (<div className="background-light-green border-product-radius custom-badge"><p className="color-green m-1 p-1">{"Delivered"}</p></div>) : item.order_status === constant.ORDER_STATUS.CANCELED ?
                                        (<div className="background-light-red border-product-radius custom-badge "><p className="color-red m-1 p-1">{"Canceled"}</p></div>) : ''
                                ,
                                action: <>
                                {<div className='table-data-width'>
                                    <button
                                        className="btn dropdown-dots shadow-none action-button"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <Image src={ThreeDots} />
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                        aria-labelledby="dropdownMenuButton1"
                                        style={{ width: "150px" }}
                                    >



                                        {/* Update Option */}
                                        <li>
                                            <button className="dropdown-item dropdown-item-size"
                                                onClick={() => { navigate(`/pending/order/${item.id}?delivered`) }}>
                                                <div className='d-flex align-items-center'>
                                                <AiOutlineEye />
                                                    <span className='ms-2 mb-0'>view</span>
                                                </div>
                                            </button>
                                        </li>

                                        <hr className="my-1"></hr>
                                        {/* Download Option */}

                                        <li>
                                            <button className="dropdown-item dropdown-item-size"
                                                onClick={() => handlePrintSharePdf(item?.id)}>
                                                <div className='d-flex align-items-center'>
                                                    <FaPrint />
                                                    <span className='ms-2 mb-0'>Print</span>
                                                </div>
                                            </button>
                                        </li>
                                        <hr className="my-1"></hr>

                                        {/* Share Option */}
                                        <li>
                                            <button className="dropdown-item dropdown-item-size"
                                                onClick={() => handlePrintSharePdf(item?.id, true)}>
                                                <div className='d-flex align-items-center'>
                                                    <FiShare />
                                                    <span className='ms-2 mb-0'>Share</span>
                                                </div>
                                            </button>
                                        </li>


                                    </ul>
                                </div>}
                            </>
                                // action:
                                //     <div className='d-flex' style={{ minWidth: "225px" }}>
                                //         <div className='col'>
                                //             <Button className='order' onClick={() => { navigate(`/pending/order/${item.id}?delivered`) }}>View</Button>
                                //         </div>
                                //         <div className='col '>
                                //             <Button className='order' onClick={() => generatePdf(item?.id)}>Print</Button>
                                //         </div>
                                //     </div>
                            })
                        })}
                    />

                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={receiptData?.totalPages}
                        onChangePage={(page: number) => setCurrentPage(page)}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    )
}

export default Index
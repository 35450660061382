import { combineReducers } from 'redux';
import checkboxReducer from "../features/productList/filter/checkboxSlice"
import checkboxAPI from "../features/productList/filter/checkboxAPI";
import bagesSlice from "../features/productList/bages/bagesSlice";
import productListSlice from "../features/productList/list/listSlice";
import pageCount from "../features/productList/pageCount/pageCount";
import isAvailable from "../features/productList/availableFilter/index"
import listLocation from "../features/productList/listLocation/listLocation";
import counterSlice from "../features/productList/counterSlice";
import logout from '../features/logout';

const commonReducer = {
    checkboxReducer,
    checkboxAPI,
    bagesSlice,
    productListSlice,
    pageCount,
    isAvailable,
    listLocation,
    counterSlice,
    logout
}

const appReducer = combineReducers(commonReducer);

const rootReducer = (state:any, action:any) => {
    if (action.type === "user/userLogout") {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;

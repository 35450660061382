import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import constant from '../../../constants/constant';

const initialState: any = {
  isAvailableCheck: false,
  isPictures: false,
  isScroll: false,
  sort_order:constant?.Product_List_Price_Sort?.Low_To_High
};

const additionalFilter = createSlice({
  name: 'isAvailableCheck',
  initialState,
  reducers: {
    setIsAvailableCheck: (state, action: PayloadAction<boolean>) => {
      state.isAvailableCheck = action.payload;
      state.isScroll =state?.isScroll ? !(state?.isScroll) : false;
    },
    setIsPicturesCheck: (state, action) => {
      state.isPictures = action.payload;
      state.isScroll =state?.isScroll ? !(state?.isScroll) : false;
    },
    setIsScroll: (state, action)=>{
      state.isScroll = action.payload;
    },
    setSortOrder: (state, action)=>{
      state.sort_order = action.payload;
    }
  },
});

export const { setIsAvailableCheck ,setIsPicturesCheck, setIsScroll ,setSortOrder} = additionalFilter.actions;

export default additionalFilter.reducer;

import React, { useState, useRef } from "react";
import atoms from "../../atoms";
import { useReactToPrint } from "react-to-print";
import { prescriptionProps } from "./inerface";
import moment from "moment";

const { CustomSearch, Input, Button } = atoms;
const Index = ({
  searchCallBack,
  prescriptionData,
  handleShare,
}: prescriptionProps) => {
  const PdfRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchMobileNumberError, setSearchMobileNumberError] = useState(false);

  const handleSearch = (searchValue: string) => {
    if (searchValue?.length === 10) {
      searchCallBack(searchValue);
      setSearchMobileNumberError(false);
    } else {
      setSearchMobileNumberError(true);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => PdfRef.current,
    documentTitle:
      "Inventory" + Math.floor(100000 + Math.random() * 900000).toString(),
  });

  const size = "210mm 297mm";

  const getPageMargins = () => {
    return `
      @page {
         margin-top: 10mm; 
         margin-right: 10mm; 
         margin-bottom:87mm; 
         margin-left: 10mm; 
         font-size:10px
         size: 210mm 297mm; !important
        } 

        div.page {
            page-break-after: always;
          }
         `;
  };

  const cssString = getPageMargins();

  return (
    <>
      <div className="w-100">
        <div className="row">
          <div
            className={`${prescriptionData?.lens ? "col-8 pe-0" : "col-12"
              } col-md-6 mb-md-0`}
          >
            <div className="">
              <CustomSearch
                placeholder="Search Mobile Number"
                className="fs-16"
                heading="Search"
                buttonStyle={` ${window.innerWidth > 876 && "w-25"}`}
                setIsOpen={setIsOpen}
                searchCallBack={(searchValue: string) =>
                  handleSearch(searchValue)
                }
              />
            </div>
            {searchMobileNumberError && (
              <p className="p-0 m-0 text-danger fs-12 fw-500">
                Mobile Number should be 10 digit
              </p>
            )}
          </div>
          {(prescriptionData?.lens?.prescription_details || prescriptionData?.contact_lens?.prescription_details) && (
            <div
              className={`col-4 col-md-6 d-flex gap-3 justify-content-end ps-3 ${window.innerWidth > 768 ? "justify-content-end pe-3" : "ps-3"
                }`}
            >
              <Button
                className={`${window.innerWidth < 876 && "outline-button-filter"
                  }`}
                onClick={() => handlePrint()}
              >
                Download
              </Button>
              <Button
                className={`d-md-block d-none ${window.innerWidth < 876 && "outline-button-filter"
                  }`}
                onClick={() => handleShare()}
              >
                Share
              </Button>
            </div>
          )}
        </div>
        <div ref={PdfRef} className="w-100">
          <style>{cssString}</style>
          {prescriptionData?.lens?.prescription_details && (
            <div className="mt-2 mb-3">
              <div className="row mt-4">
                <div className="col-12 ">
                  <div className="card">
                    <div>
                      <h2 className="fs-22 fw-800 text-center m-3">Lens</h2>
                    </div>
                    <div className="row mt-3 px-3">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-6">
                            <span className="fs-16 fw-600">Customer Name</span>
                            <p className="fs-16 fw-400 secondary-color mt-1">
                              {prescriptionData?.lens?.patient_name}
                            </p>
                          </div>
                          <div className="col-6 px-0">
                            <span className="fs-16 fw-600">
                              Customer Number
                            </span>
                            <p className="fs-16 fw-400 secondary-color mt-1">
                              {prescriptionData?.lens?.patient_mobile}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-6">
                            <span className="fs-16 fw-600">Date</span>
                            <p className="fs-16 fw-400 secondary-color mt-1">
                              {moment(prescriptionData?.lens?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                          <div className="col-6 px-0">
                            <span className="fs-16 fw-600">Store Name</span>
                            <p className="fs-16 fw-400 secondary-color mt-1">
                              {prescriptionData?.lens?.store?.store_name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="top-border"
                      style={{ borderTop: "1px solid #22222260" }}
                    >
                      <div className="row px-2 my-2">
                        <div className="col-12 col-md-6">
                          <h3 className="text-center inputs-label m-2 fs-16 fw-600 secondary-color">{`RIGHT EYE (OD)`}</h3>

                          <div className="d-flex gap-1">
                            <div
                              className="align-items-center d-flex"
                              style={{ width: "225px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`DV :`}</h4>
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="SPH"
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_dv_sph
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="CYL"
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_dv_cyl
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="AXIS"
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_dv_axis
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="PD"
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_dv_pd
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-2 gap-1">
                            <div
                              className="align-items-center d-flex"
                              style={{ width: "225px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`NV :`}</h4>
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_nv_sph
                                }
                                disabled={true}
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_nv_cyl
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_nv_axis
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_nv_pd
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-2 gap-1">
                            <div
                              className=" align-items-center d-flex"
                              style={{ width: "110px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`ADD :`}</h4>
                            </div>
                            <div className="" style={{ width: "110px" }}>
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.r_add
                                    ? prescriptionData?.lens
                                      ?.prescription_details?.r_add
                                    : ""
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mt-4 mt-md-0 left-border ">
                          <hr className="d-block d-md-none" />

                          <h3 className="text-center inputs-label my-2 fs-16 fw-600 secondary-color">{`LEFT EYE (OS)`}</h3>
                          <div className="d-flex gap-1">
                            <div
                              className="align-items-center d-flex"
                              style={{ width: "225px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`DV :`}</h4>
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="SPH"
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_dv_sph
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="CYL"
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_dv_cyl
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="AXIS"
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_dv_axis
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="PD"
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_dv_pd
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-2 gap-1">
                            <div
                              className=" align-items-center d-flex"
                              style={{ width: "225px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`NV :`}</h4>
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_nv_sph
                                }
                                disabled={true}
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_nv_cyl
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_nv_axis
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_nv_pd
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <div
                              className="align-items-center d-flex"
                              style={{ width: "110px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`ADD :`}</h4>
                            </div>
                            <div className="" style={{ width: "110px" }}>
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.lens?.prescription_details
                                    ?.l_add
                                    ? prescriptionData?.lens
                                      ?.prescription_details?.l_add
                                    : ""
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {prescriptionData?.contact_lens?.prescription_details && (
            <div className="" style={{ marginTop: "6rem" }}>
              <div className="row mt-4">
                <div className="col-12 ">
                  <div className="card">
                    <div>
                      <h2 className="fs-22 fw-800 text-center m-3">
                        Contact Lens
                      </h2>
                    </div>
                    <div className="row px-3 mt-3">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-6">
                            <span className="fs-16 fw-600">Customer Name</span>
                            <p className="fs-16 fw-400 secondary-color mt-1">
                              {prescriptionData?.contact_lens?.patient_name}
                            </p>
                          </div>
                          <div className="col-6 px-0">
                            <span className="fs-16 fw-600">
                              Customer Number
                            </span>
                            <p className="fs-16 fw-400 secondary-color mt-1">
                              {prescriptionData?.contact_lens?.patient_mobile}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-6">
                            <span className="fs-16 fw-600">Date</span>
                            <p className="fs-16 fw-400 secondary-color mt-1">
                              {moment(
                                prescriptionData?.contact_lens?.createdAt
                              ).format("DD/MM/YYYY")}
                            </p>
                          </div>
                          <div className="col-6 px-0">
                            <span className="fs-16 fw-600">Store Name</span>
                            <p className="fs-16 fw-400 secondary-color mt-1">
                              {
                                prescriptionData?.contact_lens?.store
                                  ?.store_name
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="top-border"
                      style={{ borderTop: "1px solid #22222260" }}
                    >
                      <div className="row px-2 my-2">
                        <div className="col-12 col-md-6">
                          <h3 className="text-center inputs-label m-2 fs-16 fw-600 secondary-color">{`RIGHT EYE (OD)`}</h3>

                          <div className="d-flex gap-1">
                            <div
                              className="align-items-center d-flex"
                              style={{ width: "225px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`DV :`}</h4>
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="SPH"
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_dv_sph
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="CYL"
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_dv_cyl
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="AXIS"
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_dv_axis
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="PD"
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_dv_pd
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-2 gap-1">
                            <div
                              className="align-items-center d-flex"
                              style={{ width: "225px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`NV :`}</h4>
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_nv_sph
                                }
                                disabled={true}
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_nv_cyl
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_nv_axis
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_nv_pd
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <div
                              className=" align-items-center d-flex"
                              style={{ width: "110px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`ADD :`}</h4>
                            </div>
                            <div className="" style={{ width: "110px" }}>
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.r_add
                                    ? prescriptionData?.contact_lens
                                      ?.prescription_details?.r_add
                                    : ""
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mt-4 mt-md-0 left-border ">
                          <hr className="d-block d-md-none" />

                          <h3 className="text-center inputs-label my-2 fs-16 fw-600 secondary-color">{`LEFT EYE (OS)`}</h3>
                          <div className="d-flex gap-1">
                            <div
                              className="align-items-center d-flex"
                              style={{ width: "225px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`DV :`}</h4>
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="SPH"
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_dv_sph
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="CYL"
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_dv_cyl
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="AXIS"
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_dv_axis
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                label="PD"
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_dv_pd
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-2 gap-1">
                            <div
                              className=" align-items-center d-flex"
                              style={{ width: "225px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`NV :`}</h4>
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_nv_sph
                                }
                                disabled={true}
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_nv_cyl
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_nv_axis
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_nv_pd
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <div
                              className="align-items-center d-flex"
                              style={{ width: "110px" }}
                            >
                              <h4 className="fs-16 fw-600 secondary-color">{`ADD :`}</h4>
                            </div>
                            <div className="" style={{ width: "110px" }}>
                              <Input
                                type="text"
                                onChange={(e: any) => ""}
                                disabled={true}
                                value={
                                  prescriptionData?.contact_lens
                                    ?.prescription_details?.l_add
                                    ? prescriptionData?.contact_lens
                                      ?.prescription_details?.l_add
                                    : ""
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!(
            prescriptionData?.contact_lens?.prescription_details ||
            prescriptionData?.lens?.prescription_details
          ) && (
              <div className="mt-3 d-flex justify-content-center dark-gray">
                <span>There are no records to display</span>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default Index;

import React from 'react'
import atoms from '../../atoms'
import { Card } from 'reactstrap'
import Images from '../../../assets/Images'
import molecules from '../../../components/molecules'
const { CustomSearch, Select, Button, CustomImages } = atoms
const { AuditProductImg, Copy } = Images
const { TopBar } = molecules
const Index = () => {

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className=''>
                        <CustomSearch placeholder='Search Barcode' className='fs-16' />
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12 col-md-6">
                    <CustomImages src={AuditProductImg}/>
                    {/* <div className='audit-product-img' style={{ backgroundImage: `url(${AuditProductImg})` }}>

                    </div> */}
                </div>
                <div className="col-12 col-md-6 mt-4 mt-md-3 order-1 order-md-2">
                    <div className="">
                        <div className='d-flex align-items-center gap-2'>
                            <h2 className='mb-0 fs-16 fw-500'>Product ID :-</h2>
                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                <h2 className='mb-0 fs-16 fw-500'>#2151SDFE51221</h2>
                                <img src={Copy} alt="" className='img-fluid cursor-pointer' />
                            </div>
                        </div>

                        <div className='d-flex align-items-center gap-2 mt-3'>
                            <h2 className='mb-0 fs-16 fw-500'>Barcode :-</h2>
                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                <h2 className='mb-0 fs-16 fw-500'>#2151SDFE51221</h2>
                                <img src={Copy} alt="" className='img-fluid cursor-pointer' />
                            </div>
                        </div>
                    </div>

                    <h2 className='mb-0 fs-20 fw-600 mt-4'>Brand Name</h2>
                    <h2 className='mb-0 fs-24 fw-600 mt-4'>Full Rim Rectangle Frame For Men (145 mm)</h2>

                    <div className='mt-4'>
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <h2 className='mb-0 fs-18 fw-600'>Product SRP :</h2>
                            <span className='fs-18 fw-500'>₹3,999</span>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <h2 className='mb-0 fs-18 fw-600'>Product SRP :</h2>
                            <span className='fs-18 fw-500'>₹3,999</span>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <h2 className='mb-0 fs-18 fw-600'>Specx Color Name :</h2>
                            <span className='fs-18 fw-500'>Dark Blue</span>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className="d-flex flex-wrap align-items-center gap-3 ">
                            <h2 className='mb-0 fs-18 fw-600'>Glass Size :</h2>
                            <span className='fs-18 fw-500'>40</span>
                        </div>
                    </div>


                    <hr style={{ border: '1px dashed #505D6F20' }} />

                </div>
            </div>

            <div className='row'>
                <div className="col-md-4 mt-4">
                    <Select className='cursor-pointer' label='Condition Of The Item' />
                </div>
                <div className="col-md-8">

                </div>
            </div>

            <div className='row'>
                <div className="col-12 mt-4 ">
                    <h2 className='mb-0 fs-16 fw-600 dark-gray mb-3'>Product Condition Remark</h2>
                    <textarea className={"textarea-style w-100"} placeholder={"Enter Description Here"}
                        onChange={(e: any) => ''} />
                </div>
            </div>

            <div>
                <Button className='w-100 mt-4 mt-md-5'>Save</Button>
            </div>
        </div>
    )
}

export default Index
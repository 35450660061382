import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
import { useToasts } from 'react-toast-notifications'
import service from '../../../api/services';
import constant from '../../../constants/constant';
import { ShowActiveTab } from '../../../Context';
const { TopBar } = molecules
const { SalesReturn } = organisms

const Index = () => {
  const { addToast } = useToasts();
  const { setActiveTab }: any = useContext(ShowActiveTab)
  const [salesReturnList, setSalesReturnList] = useState()
  const [showSidebarModal, setShowSidebarModal] = useState(false)
  const [stopSubmitButtonClick, setStopSubmitButtonClick] = useState(true)
  const [salesReturnData, setSalesReturnData] = useState({
    conditionRemark: "",
    // issue_type: NaN,
    search: "",
  })

  const checkEmptyInvoice = (data: any[]) => {
    if (!data || data.length === 0) {
      return;
    }

    const hasNonCancelledItems = data.some(item => item?.status !== constant?.Sale_Return_Item_Condition);

    if (!hasNonCancelledItems) {
      addToast("There are no active items in the invoice", {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };


  const handleModalSubmit = async (id: string) => {
    setStopSubmitButtonClick(false)
    const payload = {
      condition_remark: salesReturnData?.conditionRemark,
      // issue_type: salesReturnData?.conditionRemark
    }

    try {
      const response = await service?.salesReturnService?.salesReturnSubmit(id, payload)
      if (response.status === 200) {
        setShowSidebarModal(false)
        salesReturnListData(salesReturnData?.search, true)
        setStopSubmitButtonClick(true)
        addToast('Product Return Successfully', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });

      }
    } catch (error: any) {
      console.log(error)
      setShowSidebarModal(false)
      addToast(error.response.data.message, {
        appearance: 'error',
        className: "zindex-tooltip",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setStopSubmitButtonClick(true)
    }
  }


  const salesReturnListData = async (searchValue: string, status?: boolean) => {
    try {
      const response = await service?.salesReturnService?.salesReturnList({ search: searchValue })
      if (response.status === 200) {
        setSalesReturnList(response?.data?.data)
        { !status && checkEmptyInvoice(response?.data?.data) }
        setSalesReturnData({ ...salesReturnData, search: searchValue, conditionRemark: "" })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleChange = (name: string, value: any) => {
    setSalesReturnData({ ...salesReturnData, [name]: value })
  }

  useEffect(() => {
    setActiveTab("sales Return")
  }, [])

  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Product Sales Return' />
      </div>
      <div className='row mt-3'>
        <SalesReturn
          salesReturnData={salesReturnData}
          salesReturnList={salesReturnList}
          handleChange={(name: string, value: any) => handleChange(name, value)}
          salesReturnListData={(searchValue: string) => salesReturnListData(searchValue)}
          showSidebarModal={showSidebarModal}
          setShowSidebarModal={setShowSidebarModal}
          handleModalSubmit={(id: string) => stopSubmitButtonClick ? handleModalSubmit(id) : ""}
          setSalesReturnData={setSalesReturnData} />
      </div>
    </Wrapper>
  )
}

export default Index
import { Navigate, Route, Routes } from 'react-router';
import { PublicRoutes, privateRoutes } from './routes';
import NonAuthLayout from './layout/NonAuthLayout';
import AuthLayout from './layout/AuthLayout';
import Interceptor from './api/interceptor'
import Error404 from './pages/App/Error404'
import React, { useState } from 'react';
import { store } from './store/store'
import { Provider } from 'react-redux'
import { Context, BadgesCount, ShowActiveTab, SalesCartTimer, SalesCartTimerPopup, StorePopup,SelectedCheckboxes } from './Context';

const App = () => {
  const defaultPopup = localStorage.getItem('salesCartTimerPopup')
  const [context, setContext] = useState<string[]>();
  const [activeTab, setActiveTab] = useState<string>()
  const [notificationCount, setNotificationCount] = useState<any>();
  const [salesCartTimerPopup, setSalesCartTimerPopup] = useState<any>(defaultPopup == '1' ? true : false);
  const [salesCartTimer, setSalesCartTimer] = useState<any>({ isFirst: true, isStarted: false });
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<{ [key: string]: string[] }>({
    brand: [],
    type: [],
    polarized: [],
    frame_materials: [],
    product_shapes: [],
    rim_type: [],
    features: [],
    gender: [],
    price: [],
    sw_color_list: [],
    lens_color: [],
    bridge_size: [],
    weight: [],
    glass_size: [],
    temple_size: [],
  });

  return (
    <React.Fragment>
      <Provider store={store}>
        <StorePopup.Provider value={{ isShow, setIsShow }}>
         <SelectedCheckboxes.Provider value={{ selectedCheckboxes, setSelectedCheckboxes }}>
            <SalesCartTimer.Provider value={{ salesCartTimer, setSalesCartTimer }}>
              <SalesCartTimerPopup.Provider value={{ salesCartTimerPopup, setSalesCartTimerPopup }}>
                <ShowActiveTab.Provider value={{ activeTab, setActiveTab }}>
                  <BadgesCount.Provider value={[notificationCount, setNotificationCount]}>
                    <Context.Provider value={[context, setContext]}>
                      <Interceptor />
                      <Routes >
                        {PublicRoutes.map((route, idx) => (
                          <Route
                            path={route.path}
                            element={
                              <NonAuthLayout>
                                {route.component}
                              </NonAuthLayout>
                            }
                            key={idx}
                          />
                        ))}
                        {privateRoutes.map((route, idx) => (
                          <Route
                            path={route.path}
                            element={
                              <AuthLayout>
                                {route.component}
                              </AuthLayout>}
                            key={idx}
                          />
                        ))}
                        <Route path='error' element={<Error404 />} />
                        <Route path='*' element={<Navigate to='/error' />} />
                      </Routes>
                    </Context.Provider>
                  </BadgesCount.Provider>
                </ShowActiveTab.Provider>
              </SalesCartTimerPopup.Provider>
            </SalesCartTimer.Provider>
          </SelectedCheckboxes.Provider>
        </StorePopup.Provider>
      </Provider>
    </React.Fragment>
  );
}

export default App;

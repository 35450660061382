import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { transferRequest } = Dictionary

const transferRequestService={
    list: async (paramsObj: any) => {
        return axios.get(BASE_URL + transferRequest.list() ,{ params: paramsObj })
    },
}

export default transferRequestService
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  listLocation: 1,
  previousListLocation:1
};

const listLocationSlice = createSlice({
  name: 'listLocation',
  initialState,
  reducers: {
    setListLocation: (state, action: PayloadAction<any>) => {
      state.listLocation = action.payload;
    },
    setPreviousListLocation :(state, action:PayloadAction<any>)=>{
      state.previousListLocation = action.payload
    }
  },
});

export const { setListLocation, setPreviousListLocation } = listLocationSlice.actions;

export default listLocationSlice.reducer;

import React from "react";
import Select from "react-select";
import './Select.scss'

export const ReactSelect = ({ className, placeholder,
    options, value,
    onChange, isMulti,
    menuIsOpen, isDisabled, defaultValue,label, htmlFor ,errors
}: any) => {
    const handleChange = (option: any) => {
        onChange(option);
    };
    return (
        <>
           {label && <label className='inputs-label mb-2 fs-16 fw-600  secondary-color' htmlFor={htmlFor}>{label}</label>}
            <Select
                isDisabled={isDisabled}
                menuIsOpen={menuIsOpen}
                // menuIsOpen={true}
                className={`multi_select ${className}`}
                // name={field.name}
                defaultValue={defaultValue}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                isSearchable={true}
            />
             {errors && <span className="error-text">{errors}</span>}
        </>
    );
};

export default ReactSelect;

import React from 'react'
import './AvailableBadge.scss'
const Index = (props: { title?: string }) => {
    const { title } = props
    return (
        <div className="badge-product-sale">
            <span className="new">{`${title ? title : 'Available At Store'}`}</span>
        </div>
    )
}

export default Index
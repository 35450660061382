import React from 'react'
import { Navigate } from 'react-router'
import privatePages from '../pages/App'
import publicPages from '../pages/Auth'
const { Dashboard, ProductList, ProductDetail, SalesCart, PendingOrder, AccountDetail, Audit, Warranty,
    WarrantyDetail, Claimed, ClaimedHistory, IssueNewProduct, SalesReturn, SalesReturnHistory, StoreRequested, StoreRequestedHistory,
    PendingReceipt, InventoryAudit, Delivered,CancelledOrder, TransferRequest,Prescription,PaymentVoucher,PaymentVoucherHistory } = privatePages
const { Login, Registration } = publicPages

const privateRoutes = [
    { path: "/", exact: true, component: <Navigate to="/dashboard" />, },
    { path: '/dashboard', component: <Dashboard /> },
    { path: '/product/list', component: <ProductList /> },
    { path: '/product/details/:id', component: <ProductDetail /> },
    { path: '/sales-cart', component: <SalesCart /> },
    { path: '/account-details', component: <AccountDetail /> },
    { path: '/audit', component: <Audit /> },
    { path: '/warranty', component: <Warranty /> },
    { path: '/warranty-detail/:id', component: <WarrantyDetail /> },
    { path: '/claimed', component: <Claimed /> },
    { path: '/claimed-history', component: <ClaimedHistory /> },
    { path: '/issue-new-product/:id', component: <IssueNewProduct /> },
    { path: '/sales-return', component: <SalesReturn /> },
    { path: '/sales-return-history', component: <SalesReturnHistory /> },
    // { path: '/store-requested', component: <StoreRequested /> },
    // { path: '/store-requested-history', component: <StoreRequestedHistory /> },
    { path: '/pending-receipt', component: <PendingReceipt /> },
    { path: '/delivered', component: <Delivered /> },
    { path: '/cancelled-order', component: <CancelledOrder /> },
    { path: '/pending/order/:id', component: <PendingOrder /> },
    { path: '/inventory-audit', component: <InventoryAudit /> },
    { path: '/transfer-request', component: <TransferRequest /> },
    { path: '/prescription', component: <Prescription /> },
    { path: '/payment-voucher', component: <PaymentVoucher /> },
    { path: '/payment-voucher-history', component: <PaymentVoucherHistory /> }


]

const PublicRoutes = [
    { path: '/login', component: <Login /> },
    { path: '/registration', component: <Registration /> },
]
export { privateRoutes, PublicRoutes }
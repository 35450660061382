import auth from './auth'
import product from './product'
import salesCart from './salesCart'
import pendingOrder from './pendingOrder'
import audit from './audit'
import transferRequest from './transferRequest'
import store from './store'
import salesReturn from './salesReturn'
import warranty from './warranty'
import prescription from './prescription'
import dashboard from './dashboard'
import paymentVoucher from './paymentVoucher'

const dictionary = {
  auth,
  product,
  salesCart,
  pendingOrder,
  audit,
  transferRequest,
  store,
  salesReturn,
  warranty,
  prescription,
  dashboard,
  paymentVoucher,
}
export default dictionary

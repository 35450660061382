// features/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false,
    userInfo: null,
  },
  reducers: {
    userLogout: (state) => {
      state.isAuthenticated = false;
      state.userInfo = null;
    },
  },
});

export const { userLogout } = userSlice.actions;
export default userSlice.reducer;

import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
// import CopyIcon from '../../../assets/Images/copy-icon.svg'
import atoms from "../../../atoms";
import './salesReturnPopup.scss'
import { RxCross2 } from "react-icons/rx";
import ConfirmationModal from "../../ConfirmationModal";

const { Button } = atoms

const Index = (props: any) => {

    const style = { top: '15%', right: '3%', backgroundColor: 'transparent', border: '1px solid #222', borderRadius: '50%', cursor: 'pointer' }
    const [approvalPopup, setApprovalPopup] = useState<boolean>(false)
    const handleSubmitModal = () => {
        // props?.handleModalSubmit;
        props?.handleModalSubmit?.();
    }
    return (
        <>
            <Modal className="rounded-0"
                isOpen={props.show}
                wrapClassName="modal-right"
            >
                <ModalHeader className="d-flex border-0 position-relative" >
                    <div className="mt-4 fs-24 fw-600 mb-0">Sales Return</div>
                    <div className="position-absolute py-1 px-2"
                        style={style}
                        onClick={props.onHide}><RxCross2 style={{ color: '#222' }} />
                    </div>
                </ModalHeader>
                <ModalBody className="pt-md-0">
                    {props?.salesReturnProductId && <div>
                        <div className=' d-md-flex gap-3 align-items-center mb-4'>
                            <h2 className='mb-md-0 mb-2 fs-16 fw-700 secondary-color '>Product ID:</h2>
                            <div className='d-flex justify-content-around align-items-center p-2 add-product-custom-div cursor-pointer' style={{ minWidth: "15rem" }}>
                                <h2 className='mb-0 fs-14 fw-600 secondary-color'>{props?.salesReturnProductId}</h2>
                            </div>
                        </div>
                    </div>}
                    <hr className="mt-3" style={{ border: '1px dashed #222', }} />

                    {/* <div className="mb-5">
                        <h2 className='mb-0 fs-16 fw-700 secondary-color'>Sales Return Issue Type</h2>
                        <div className="d-sm-flex gap-5 mt-3">

                            <label className="d-flex gap-2 align-item-center">
                                <Input
                                    type="radio"
                                    name="issue_type"
                                    value={constant?.SALES_RETURN_ISSUE_TYPE?.PAY_CASH}
                                    // checked={props?.salesReturnData?.issue_typ === constant?.SALES_RETURN_ISSUE_TYPE?.PAY_CASH}
                                    className="d-flex my-auto"
                                    radioInputClass="radio-button-style"
                                    onChange={() => props?.handleChange("issue_type", constant?.SALES_RETURN_ISSUE_TYPE?.PAY_CASH)}
                                />
                                <span className={`fs-16 fw-400 ${props?.salesReturnData?.issue_type === constant?.SALES_RETURN_ISSUE_TYPE?.PAY_CASH ? "main-color" : "color-gray"}`}> Pay Cash</span>
                            </label>
                            <label className="d-flex gap-2 align-item-center">
                                <Input
                                    type="radio"
                                    name="issue_type"
                                    value={2}
                                    className="d-flex my-auto"
                                    radioInputClass="radio-button-style"
                                    // checked={props?.salesReturnData?.issue_typ === constant?.SALES_RETURN_ISSUE_TYPE?.ISSUE_CREDIT_NOTE}
                                    onChange={() => props?.handleChange("issue_type", constant?.SALES_RETURN_ISSUE_TYPE?.ISSUE_CREDIT_NOTE)}
                                />
                                <span className={`fs-16 fw-400 ${props?.salesReturnData?.issue_type === constant?.SALES_RETURN_ISSUE_TYPE?.ISSUE_CREDIT_NOTE ? "main-color" : "color-gray"}`}>Issue Credit Note</span>

                            </label>
                        </div>
                    </div> */}
                    <div className="mt-4">
                        <div className='col-12'>
                            <label className="inputs-label mb-2 fs-16 fw-700 secondary-color">Product Condition Remark</label>
                            <textarea
                                name={"conditionRemark"}
                                className="w-100 inputs form-control"
                                rows={4}
                                value={props?.salesReturnData?.conditionRemark}
                                placeholder={"Enter Description Here"}
                                onChange={(e: any) => { props?.handleChange("conditionRemark", e.target.value) }} />
                        </div>
                    </div>
                </ModalBody>
                <hr className="mt-3" style={{ border: '1px dashed #222', }} />

                <ModalFooter className="border-0 d-flex" style={{ flexWrap: 'inherit' }}>
                    {/* <Button className="w-100"
                        isDisabled={!props?.salesReturnData?.conditionRemark}
                        onClick={props?.handleModalSubmit}>Submit</Button> */}

                    <Button className="w-100"
                        isDisabled={!props?.salesReturnData?.conditionRemark}
                        onClick={() => setApprovalPopup(!approvalPopup)}>Submit</Button>
                </ModalFooter>
                {
                    approvalPopup && <ConfirmationModal
                        show={approvalPopup}
                        onHideButtonText={"Cancel"}
                        confirmButtonText={"OK"}
                        handleConfirmItem={() => { handleSubmitModal(); setApprovalPopup(!approvalPopup) }}
                        onHide={() => setApprovalPopup(!approvalPopup)}
                        message={'issue credit note'} />
                }
            </Modal>
        </>
    )
}

export default Index
import React from 'react'
import atoms from '../../../atoms'
import moment from 'moment'
import constant from '../../../../constants/constant'
import { numberWithCommas } from '../../../../constants/utils'
import { ShowActiveTab } from '../../../../Context'
import { generatePdf } from '../../../../constants/utils'
import { FaPrint } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import ThreeDots from '../../../../assets/Images/threeDots.svg'
import { cancelledOrderProps, cancelledOrderData } from './interface'
import { useNavigate } from 'react-router'
const { Search, Table, Button, Pagination, Image } = atoms

const Index = ({ cancelledOrderData, setSearch, setLimitPerPage, setCurrentPage, currentPage, limit, handlePrintSharePdf }: cancelledOrderProps) => {
    const navigate = useNavigate()
    const columns = [
        { name: 'Date', selector: (row: cancelledOrderData) => moment(row?.createdAt).format('DD/MM/YYYY'), width: '100px' },
        { name: 'Order Id', selector: (row: cancelledOrderData) => row?.order_id_display, width: '100px' },
        { name: 'Sold By', selector: (row: cancelledOrderData) => row?.staff.username, width: '150px' },
        { name: 'Customer Name', selector: (row: cancelledOrderData) => row?.customer.name || '-', width: '150px' },
        { name: 'Customer Contact', selector: (row: cancelledOrderData) => row?.customer.mobile || '-', width: '100px' },
        { name: 'Delivery Address', selector: (row: cancelledOrderData) => row?.delivery_address || '-', width: '170px', },
        {
            name: 'Total Amount(₹)', selector: (row: cancelledOrderData) => <>
                <div>
                    <p className='mb-1 ' >Order Value : {`₹${numberWithCommas(parseFloat(row.total_amount?.toFixed(2)))}`}</p>
                    <p className='mb-1 '>Advance Paid : {`₹${numberWithCommas(parseFloat(row.total_paid_amount?.toFixed(2)))}`}</p>
                    {/* <p className='mb-1 '>Pending Amount : {row.total_amount - row.total_paid_amount}</p> */}
                </div>
            </>, width: '180px'
        },
        {
            name: 'Total Items', selector: (row: cancelledOrderData) => <>
                <div>
                    <p className='mb-1 ' >Total Order : {row.total_order_items}</p>
                    <p className='mb-1 '>Delivered : {row.total_delivered_order_items}</p>
                    {/* <p className='mb-1 '>Pending : {row.total_pending_order_items}</p> */}

                </div>
            </>, width: '120px'
        },
        { name: 'Status', selector: (row: any) => row?.order_status || '-', width: '120px' },
        { name: 'Action', selector: (row: any) => row?.action, center: true, width:'160px' },


    ];
    return (
        <>
            <div className='row mt-md-0 mt-3'>
                <div className='col-md-6 '>
                    <Search className='search search-style w-100' searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
                <div>
                    <Table
                        columns={columns}
                        data={cancelledOrderData?.data?.map((item: cancelledOrderData) => {
                            return ({
                                ...item,
                                order_status: item.order_status === constant.ORDER_STATUS.PENDING ? (
                                    <div className="background-light-yellow border-product-radius custom-badge"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.order_status === constant.ORDER_STATUS.DELIVERED ?
                                    (<div className="background-light-green border-product-radius custom-badge"><p className="color-green m-1 p-1">{"Delivered"}</p></div>) : item.order_status === constant.ORDER_STATUS.CANCELED ?
                                        (<div className="background-light-red border-product-radius custom-badge "><p className="color-red m-1 p-1">{"Cancelled"}</p></div>) : ''
                                ,
                                action: <>
                                    {/* {<div className='table-data-width'>
                                        <button
                                            className="btn dropdown-dots shadow-none action-button"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <div className='d-flex align-items-center'>
                                                <AiOutlineEye />
                                                <span className='ms-2 mb-0'>View</span>
                                            </div>
                                        </button>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                            aria-labelledby="dropdownMenuButton1"
                                            style={{ width: "150px" }}
                                        >
                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => { navigate(`/pending/order/${item.id}?cancelled`) }}>
                                                    <div className='d-flex align-items-center'>
                                                        <AiOutlineEye />
                                                        <span className='ms-2 mb-0'>View</span>
                                                    </div>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>} */}
                                    <Button className='order'
                                        onClick={() => { navigate(`/pending/order/${item.id}?cancelled`) }}>
                                        <AiOutlineEye className='fs-16' />
                                        <span className='ms-2 mb-0'>View</span>
                                    </Button>
                                </>,

                                // action:
                                //     <div className='d-flex'  >
                                //         <div className='col' style={{minWidth:"115px"}}>
                                //             <Button className='order' onClick={() => {navigate(`/pending/order/${item.id}?cancelled`)}}>View</Button>
                                //         </div>
                                //         <div className='col ' style={{minWidth:"115px"}}>
                                //             <Button className='order' onClick={() => generatePdf(item?.id)}>Print</Button>
                                //         </div>
                                //     </div>
                            })
                        })}
                    />

                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={cancelledOrderData?.totalPages}
                        onChangePage={(page: number) => setCurrentPage(page)}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    )
}

export default Index
import React, { useState, useRef } from 'react'
import atoms from '../../atoms'
import molecules from '../../molecules'
import { Card } from 'reactstrap'
import Images from '../../../assets/Images'
import close from '../../../assets/Images/close.svg'
import { warrantyDetailProps } from './interface'
import CustomImages from '../../atoms/CustomImages'
import constant from '../../../constants/constant'
const { Select, Button, Input, Image } = atoms
const { ConfirmationModal } = molecules
const { uploadimg, } = Images


const Index = ({ errors, warrantyProductData, handelChange, fileInputRef, _handleImageChange, previewImage, handleImageRemove, handleSubmitButton , warrantyUpdated }: warrantyDetailProps) => {
    const [handleProductClaim, setHandleProductClaim] = useState<boolean>(false)

    const handleClaimSumbitButton = () => {
        if (warrantyProductData?.claimed_for === "" || warrantyProductData?.condition_remark === "") {
            handleSubmitButton();
        } else {
            setHandleProductClaim(!handleProductClaim)
        }
    }

    return (
        <div>
            <div className='row'>
                <div className="col-md-4 mt-md-4 mt-2">
                    <Select
                        className='cursor-pointer'
                        label='Warranty Claimed For'
                        value={warrantyProductData?.claimed_for}
                        options={constant?.warrantee_claim_for}
                        onChange={(e: any) => handelChange("claimed_for", e)}
                    />
                    {errors?.claimed_for && !warrantyProductData?.claimed_for && <span className="error-text">{errors?.claimed_for}</span>}
                </div>
                <div className="col-md-8">

                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-md-4 mt-3">
                    <div className='d-flex justify-content-center align-items-center warranty-upload-card'>
                        <label>
                            <div className='text-center'>
                                <img src={uploadimg} alt="upload Icon" className='cursor-pointer' />
                                <h3 className='mb-0 fs-18 fw-600'>Click Product Photo</h3>
                                <Input className="d-none" type="file" id="formFile"
                                    ref={fileInputRef}
                                    onChange={(e: any) => {
                                        _handleImageChange(e)
                                    }}
                                    accept={"image/png, image/gif, image/jpeg"}
                                    multiple={true} />
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div className='mt-md-3 mt-2'>
                <div className="d-flex row">
                    {previewImage && previewImage.map((image: any, index: number) => {
                        return (
                            <div className="photo_upload mb-1 m-2 col-2">
                                <div className="photo_preview cursor-pointer mb-2" style={{overflow:"hidden"}}>
                                    <div id="imagePreview">
                                        <CustomImages src={image} />
                                    </div>
                                </div>
                                <div className='align-items-center d-flex delete-icon justify-content-center'
                                    onClick={() => { handleImageRemove(image, index) }} >
                                    <Image src={close} />
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>

            <div className='row'>
                <div className="col-12 mt-md-4 mt-2 ">
                    <h2 className='mb-0 fs-16 fw-600 dark-gray mb-md-3 mb-2'>Product Condition Remark</h2>
                    <textarea
                        className="warranty-textarea-style w-100"
                        placeholder={"Enter Description Here"}
                        value={warrantyProductData?.condition_remark}
                        onChange={(e: any) => handelChange("condition_remark", e.target.value)}
                    />
                    {(errors.condition_remark && !warrantyProductData.condition_remark) && <span className="error-text">{errors.condition_remark}</span>}
                </div>
            </div>

            <div>
                {/* <Button className='w-100 mt-4 mt-md-5' onClick={() => { setHandleProductClaim(!handleProductClaim) }} >Submit Report</Button> */}
                <Button className='w-100 mt-md-4 mt-2 mt-md-5' onClick={() => handleClaimSumbitButton()} >Submit Report</Button>
            </div>
            {
                handleProductClaim && <ConfirmationModal
                    show={handleProductClaim}
                    onHideButtonText={"Cancel"}
                    confirmButtonText={"OK"}
                    handleConfirmItem={() => { handleSubmitButton(); setHandleProductClaim(!handleProductClaim) }}
                    onHide={() => setHandleProductClaim(!handleProductClaim)}
                    message={`${warrantyUpdated ?  "update warrantee claim" : 'claim warrantee'}`} />
            }
        </div>
    )
}

export default Index
import React from 'react'
import './Wrapper.scss'
const Wrapper = ({ children }: any) => {
    return (
        <div className="wrapper-layout mt-lg-2">
            <main className="wrapper-content" style={{overflow:"hidden"}}>
                <div className="wrapper-card" >
                    {children}
                </div>
            </main>
        </div>
    )
}

export default Wrapper
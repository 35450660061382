import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
import Images from '../../../assets/Images'
import service from '../../../api/services';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications'
import {ShowActiveTab} from "../../../Context"

const { CarouselComp, TopBar } = molecules
const { IssueNewProduct } = organisms
const { Copy } = Images

const Index = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate()
  const params = useParams()
  const product_ID: string = params.id || ''
  const [productDetails, setProductDetails] = useState<any>()
  const { setActiveTab }: any = useContext(ShowActiveTab)
  const [productID, setProductID] = useState<string>("")
  const [title, setTitle] = useState<string>('')

  const getProductDetails = async () => {
    let warrantyClaimedId = product_ID
    try {
      const response = await service.warrantyService.issueNewProductDetail(warrantyClaimedId, productID)
      if (response.status === 200) {
        setProductDetails(response?.data?.data)
        if (response?.data?.data?.out_of_stock) {
          sessionStorage?.setItem("outOfStockData", JSON.stringify({
            id: response?.data?.data?.id,
            brand_color_code: response?.data?.data?.brand_color_code,
            brand_color_name: response?.data?.data?.brand_color_name,
            color_code: response?.data?.data?.specx_colors[0]?.color_code,
            glass_size: response?.data?.data?.glass_size,
            isPolarised: response?.data?.data?.isPolarised
          }));
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const requestProduct = async () => {
    try {
      const response = await service.warrantyService.requestProduct(product_ID)
      if (response.status === 200) {
        navigate("/claimed")
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    } catch (error:any) {
      console.log('error', error)
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const issueCreditNote = async () => {
    try {
      const response = await service.warrantyService.issueCreditNote(product_ID)
      if (response.status === 200) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        navigate("/claimed")
      }
    } catch (error:any) {
      console.log('error', error)
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const issueNewProduct = async (barCodeId: string) => {
    const payload = {
      inventory_item_id: barCodeId
    }
    try {
      const response = await service.warrantyService.replaceProduct(product_ID, payload)
      if (response.status === 200) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        navigate("/claimed")
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    productID && getProductDetails()
    scrollTop()
  }, [productID])

  useEffect(() => {
    setProductID(sessionStorage?.getItem('warrantyProductId') || '');
    setActiveTab("Issue New Product")
  }, [])


  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar navigate={'/claimed'}>
           <div className='d-md-none d-flex align-items-center justify-content-end'>
            <h2 className='mb-md-0 mb-1 fs-md-16 fs-12 fw-600' style={{ minWidth: window.innerWidth < 768 ? "80px" : "100px" }}>Product ID :-</h2>
            <div className='id-input-design d-flex justify-content-between'>
              <h2 className='mb-0 fs-md-16 fs-12 fw-400'>{productDetails?.product_id_display}</h2>
              <img src={Copy} alt="" className='img-fluid' />
            </div>
          </div>
        </TopBar>
      </div>
      <div className='row mt-4'>
        <div className="col">
          <CarouselComp productDetails={productDetails} />
        </div>
      </div>
      <IssueNewProduct
        productDetails={productDetails}
        title={title}
        _handleProductId={(e: any) => { setProductID(e) }}
        issueNewProduct={(barCodeId: string) => issueNewProduct(barCodeId)}
        issueCreditNote={() => issueCreditNote()}
        requestProduct={() => requestProduct()}
      />
    </Wrapper>
  )
}

export default Index
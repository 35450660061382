import React from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
const { TopBar } = molecules
const { StoreRequestedHistory } = organisms

const Index = () => {
  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Store Requested History' />
      </div>
      <div className='row mt-4'>
        <StoreRequestedHistory />
      </div>
    </Wrapper>
  )
}

export default Index
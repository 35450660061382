import React, { useState, useRef, useEffect } from 'react'
import atoms from '../../atoms'
import moment from 'moment'
import { FiShare } from "react-icons/fi";
import ThreeDots from '../../../assets/Images/threeDots.svg'
import { MdOutlineFileDownload } from "react-icons/md"
const { Search, Table, Pagination, Image } = atoms

const Index = ({ cancelledOrderData, setSearch, setLimitPerPage, displayedData, setDisplayedData, handleDownload, setCurrentPage, currentPage, limitPerPage }: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const searchTabContainer = useRef<HTMLDivElement>(null);
    const [newCurrentPage, setNewCurrentPage] = useState(1);
    const [pageIndex, setPageIndex] = useState({
        tableStartIndex: 0,
        tableEndIndex: 0
    })
    const totalPages = Math.ceil(cancelledOrderData?.data?.length / limitPerPage);
    const handlePageChange = (newPage: any) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setNewCurrentPage(newPage);
        }
    };

    const handleSearch = (search: string) => {
        const filteredData = cancelledOrderData?.data?.filter((item: any) => {
            return item?.customer?.mobile?.includes(search);
        }) ?? [];

        setDisplayedData(filteredData);
    }

    const columns = [
        { name: 'Date', selector: (row: any) => moment(row?.createdAt).format('DD/MM/YYYY') || '-' },
        { name: 'Customer Name', selector: (row: any) => row?.customer?.name || '-' },
        { name: 'Customer Mobile Number', selector: (row: any) => row?.customer?.mobile || '-' },
        { name: 'GL Number', selector: (row: any) => row?.customer?.account?.gl_number || '-' },
        { name: 'Total Amount', selector: (row: any) => row?.payment_amount || '-' },
        { name: 'Remark', selector: (row: any) => row?.remarks || '-' },
        { name: 'Action', selector: (row: any) => row?.action || '-' },
    ];

    useEffect(() => {
        const startIndex = (newCurrentPage - 1) * limitPerPage;
        const endIndex = startIndex + limitPerPage;
        const slicedData = cancelledOrderData?.data?.slice(startIndex, endIndex);
        setPageIndex({ ...pageIndex, tableStartIndex: startIndex, tableEndIndex: endIndex })
        setDisplayedData(slicedData)
    }, [newCurrentPage, cancelledOrderData?.data, limitPerPage]);

    useEffect(() => {
        setNewCurrentPage(1)
    }, [limitPerPage])
    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <div ref={searchTabContainer}>
                        <div className=" position-relative" onClick={() => setIsOpen(!isOpen)} >
                            {/* <Search
                                setIsOpen={setIsOpen}
                                searchCallBack={(e: string) => handleSearch(e)}
                                placeholder='Search Mobile Number'
                                className='fs-16 new-custom-search'
                                heading="Search"
                                buttonStyle="w-25" /> */}
                            <Search
                                placeholder="Search Mobile Number"
                                searchCallBack={(search: string) => handleSearch(search)}
                                className='fs-16 new-custom-search' heading={'Search Product'} />
                        </div>
                    </div>
                </div>
                <div>
                    {/* todo arrange status in right order it will be done on friday */}
                    <Table
                        columns={columns}
                        data={(displayedData)?.map((item: any) => {
                            return ({
                                ...item,
                                action: <div className='table-data-width'>
                                    <button
                                        className="btn dropdown-dots shadow-none action-button"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <Image src={ThreeDots} />
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                        aria-labelledby="dropdownMenuButton1"
                                        style={{ width: "150px" }}
                                    >
                                        {/* View Option */}
                                        <li>
                                            <button className="dropdown-item dropdown-item-size"
                                                onClick={() => handleDownload(item?.voucher_number)}>
                                                <div className='d-flex align-items-center'>
                                                    <MdOutlineFileDownload />
                                                    <span className='ms-2 mb-0'>Download</span>
                                                </div>
                                            </button>
                                        </li>
                                        <hr className="my-1"></hr>

                                        {/* Edit Option */}
                                        <li>
                                            <button className="dropdown-item dropdown-item-size"
                                                onClick={() => handleDownload(item?.voucher_number, true)}>
                                                <div className='d-flex align-items-center'>
                                                    <FiShare />
                                                    <span className='ms-2 mb-0'>Share</span>
                                                </div>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            })
                        })}
                    />

                </div>
                {displayedData?.length > 0 && <div>
                    <Pagination
                        totalCount={cancelledOrderData?.data?.length}
                        currentPage={newCurrentPage}
                        totalPages={totalPages}
                        onChangePage={handlePageChange}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limitPerPage}
                    />
                </div>}
            </div>
        </>
    )
}

export default Index
import React from "react";

export const Context: any = React.createContext('default value');
export const BadgesCount: any = React.createContext('default value');
export const ShowActiveTab: any = React.createContext('default value');
export const SalesCartTimerPopup: any = React.createContext('default value');
export const SalesCartTimer: any = React.createContext('default value');
export const StorePopup: any = React.createContext('default value');
export const SelectedCheckboxes: any = React.createContext('default value');



import React, { useState, useRef } from 'react'
import atoms from '../../atoms'
import molecules from '../../molecules'
import { InventoryAuditProps } from './interface'

const { Input, Select, CustomSearch, Button } = atoms
const { AuditProduct } = molecules

const Index = ({ handleBarcodeSearch, updateItemCondition, barcodeData, handleChange, updateData,inventoryItemCondition }: InventoryAuditProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const searchTabContainer = useRef<HTMLDivElement>(null);
  

    const searchCallBack = (search: string) => {
        handleBarcodeSearch(search)
    }

    return (
        <>
            <div className='row mt-3'>
                <div className='col-md-6'>
                    <div className='' ref={searchTabContainer}>
                        <div className=" position-relative" onClick={() => setIsOpen(!isOpen)} >
                            <CustomSearch
                                setIsOpen={setIsOpen}
                                searchCallBack={searchCallBack}
                                placeholder='Enter Barcode Number'
                                className='fs-16'
                                heading="Search"
                                buttonStyle={` ${window.innerWidth>876 && "w-25"}`} />
                        </div>
                    </div>
                </div>
                <div className='col-md-6 d-none d-md-flex'></div>
                {/* {barcodeData?.audit_trails[0]?.createdAt && <div className='col-md-4 d-flex justify-content-end mt-3'>
                    <div className="d-flex align-items-center">
                        <h2 className='mb-0 fs-18 fw-600'>Updated Date :</h2>
                        <span className='fs-18 fw-400'>{barcodeData?.audit_trails?.length > 0 ? moment(barcodeData?.audit_trails[0]?.createdAt)?.format("DD/MM/YYYY") : ""}</span>
                    </div>
                </div>} */}
            </div>
            {barcodeData ? <>
                <div className='my-3'>
                    <AuditProduct barcodeData={barcodeData} />
                </div>
                <div>
                    {/* <div className='mt-4'>
                        {barcodeData?.audit_trails[0]?.createdAt &&
                            <div className="col-md-4">
                                <h2 className='mb-2 fs-16 fw-600'>Date of Last Audit</h2>
                                <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                    <h2 className='mb-0 fs-16 fw-400 dark-gray'>{barcodeData?.audit_trails?.length > 0 ? moment(barcodeData?.audit_trails[0]?.createdAt)?.format("DD/MM/YYYY") : ""}</h2>
                                </div>
                            </div>}
                    </div> */}
                    <div className='mt-md-4 mt-2'>
                        <div className='col-md-4'>
                            <label className={`inputs-label mb-2 fs-16 fw-600`}>Condition of the items</label>
                            <Select
                                options={inventoryItemCondition}
                                name={"item_condition"}
                                value={updateData?.item_condition}
                                className={"w-100"}
                                placeholder={"Select Condition"}
                                onChange={(e: any) => { handleChange("item_condition", e) }}
                            />
                        </div>
                        <div className='col-md-8'></div>
                    </div>
                    <div className='mt-md-4 mt-2'>
                        <div className='col-12'>
                            <label className={`inputs-label mb-2 fs-16 fw-600`}>Product Condition Remark</label>
                            <Input
                                type={'text'}
                                name={"conditionRemark"}
                                value={updateData.conditionRemark}
                                placeholder={"Enter Description Here"}
                                onChange={(e: any) => { handleChange("conditionRemark", e.target.value) }} />
                        </div>
                    </div>
                </div>
                <div className='mx-1 mt-20'>
                    <Button name={"Save"} className={"w-100"} onClick={() => { updateItemCondition(barcodeData?.id) }} />
                </div>
            </> :
                <div className='mt-3 d-flex justify-content-center mt-3 dark-gray'>
                    <span>There are no records to display</span>
                </div>}
        </>
    )
}

export default Index
import React, { useState, useRef } from 'react'
import atoms from '../../atoms'
import moment from 'moment'
import constant from '../../../constants/constant'
import ThreeDots from '../../../assets/Images/threeDots.svg'
import { FiShare } from "react-icons/fi";
import PlaceHolderImage from '../../../assets/Images/placeholderimg.png'
import { claimedHistoryProps, cancelledOrderInterface, OrderInterface } from './interface'
import { MdOutlineFileDownload } from "react-icons/md"
import { findConstantLabel, numberWithCommas } from '../../../constants/utils'
const { Search, Table, Button, Pagination, CustomSearch, CustomImages, Image } = atoms

const Index = ({ cancelledOrderData, setSearch, setLimitPerPage, setCurrentPage, currentPage, limitPerPage, handleDownload }: claimedHistoryProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const searchTabContainer = useRef<HTMLDivElement>(null);
    const columns = [
        { name: 'Warranty Claim ID', selector: (row: OrderInterface) => row?.claim_id || '-', width: '100px' },
        { name: 'Invoice Number', selector: (row: OrderInterface) => row?.order?.invoice_number || '-', width: '150px' },
        { name: 'Customer Name', selector: (row: OrderInterface) => row?.customer?.name || '-', width: '150px' },
        { name: 'Customer Mobile Number', selector: (row: OrderInterface) => row?.customer?.mobile || '-', width: '190px' },
        { name: 'Cover Image', cell: (row: OrderInterface) => <CustomImages src={row?.product?.images?.length > 0 && row?.product?.images[0]} height="75%" />, center: true },
        { name: 'SRP', selector: (row: OrderInterface) => numberWithCommas(parseFloat((row?.product?.product_srp).toFixed(2))) || '-', width: '80px', },
        { name: 'Amount Paid', selector: (row: OrderInterface) => numberWithCommas(parseFloat(row?.paid_amount.toFixed(2))) || '-', width: '120px' },
        { name: 'Claim For', selector: (row: OrderInterface) => findConstantLabel(constant?.warrantee_claim_for, row?.claimed_for), width: '130px', },
        { name: 'Claim Resolution', selector: (row: any) => row?.order_status, minWidth: "160px" },
        { name: 'SpecxWeb Approval', selector: (row: any) => row?.claim_approval, minWidth: "120px" },
        { name: 'Action', selector: (row: any) => row?.action || '-' },

    ];
    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <div ref={searchTabContainer}>
                        <div className=" position-relative" onClick={() => setIsOpen(!isOpen)} >
                            <CustomSearch
                                setIsOpen={setIsOpen}
                                searchCallBack={(e: string) => setSearch(e)}
                                placeholder='Search Invoice ID '
                                className='fs-16'
                                heading="Search"
                                buttonStyle="w-25" />
                        </div>
                    </div>
                </div>
                <div>
                    {/* todo arrange status in right order it will be done on friday */}
                    <Table
                        columns={columns}
                        data={cancelledOrderData?.data?.map((item: OrderInterface) => {
                            return ({
                                ...item,
                                claim_approval: item.claim_approval_status === constant.WARRANTY_CLAIM_APPROVAL_STATUS[0]?.value ?
                                    (<div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.claim_approval_status === constant.WARRANTY_CLAIM_APPROVAL_STATUS[1]?.value ?
                                        (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Approve"}</p></div>) : item.claim_approval_status === constant.WARRANTY_CLAIM_APPROVAL_STATUS[2]?.value ?
                                            (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rejected"}</p></div>) : ''
                                ,
                                order_status: item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[2]?.value ? (
                                    <div className="background-light-yellow border-product-radius custom-badge"><p className="color-yellow m-1 p-1">{'Issue Credit Note'}</p></div>) : item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[1]?.value ?
                                    (<div className="background-light-green border-product-radius custom-badge"><p className="color-green m-1 p-1">{"Delivered"}</p></div>) : item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[4]?.value ?
                                        (<div className="background-light-red border-product-radius custom-badge "><p className="color-red m-1 p-1">{"Rejected"}</p></div>) : item?.claim_resolution_status === constant?.WARRANTY_CLAIM_RESOLUTION_STATUS[3]?.value ?
                                            (<div className="background-light-yellow border-product-radius custom-badge "><p className="color-yellow m-1 p-1">{"Requested"}</p></div>) : ""
                                ,
                                action: <>
                                    {!(item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[4]?.value) && <div className='table-data-width'>
                                        <button
                                            className="btn dropdown-dots shadow-none action-button"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <Image src={ThreeDots} />
                                        </button>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                            aria-labelledby="dropdownMenuButton1"
                                            style={{ width: "150px" }}
                                        >
                                            {/* View Option */}
                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => handleDownload(item?.id)}>
                                                    <div className='d-flex align-items-center'>
                                                        <MdOutlineFileDownload />
                                                        <span className='ms-2 mb-0'>Download</span>
                                                    </div>
                                                </button>
                                            </li>
                                            <hr className="my-1"></hr>

                                            {/* Edit Option */}
                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => handleDownload(item?.id, true)}>
                                                    <div className='d-flex align-items-center'>
                                                        <FiShare />
                                                        <span className='ms-2 mb-0'>Share</span>
                                                    </div>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>}
                                </>
                                // action: <>
                                //    {!(item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[4]?.value ) && <div className="custom-download-icon-style" onClick={() => handleDownload(item?.id)}>
                                //         <MdOutlineFileDownload />
                                //     </div>}
                                // </>
                            })
                        })}
                    />

                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={cancelledOrderData?.totalPages}
                        onChangePage={(page: number) => setCurrentPage(page)}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limitPerPage}
                    />
                </div>
            </div>
        </>
    )
}

export default Index
import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/common/Header/Header';
import Footer from '../components/common/Footer'
import { Navigate, } from 'react-router';
import Button from '../components/atoms/Button';
import { BadgesCount, SalesCartTimer, SalesCartTimerPopup, StorePopup } from '../Context';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import service from '../api/services';
import atoms from '../components/atoms';
import { RxCross2 } from 'react-icons/rx';
import { ShowActiveTab } from '../Context'
import './style.scss'
import { Cart_discard_timer, Cart_popup_timer } from '../constants/defaultValues';
const { Select } = atoms

let newTimer: any
let autoCleanTimer: any

const AuthLayout = (props: any) => {
    const cdt: any = Cart_discard_timer
    const cpt: any = Cart_popup_timer
    const authDetail: any = localStorage?.getItem("auth_details");
    const auth_details = localStorage?.getItem("auth_details") ? JSON.parse(authDetail) : {}
    const AUTH_TOKEN = auth_details?.access_token
    const { isShow, setIsShow } = useContext<any>(StorePopup);
    const [storeList, setStoreList] = useState([])
    const { salesCartTimer, setSalesCartTimer }: any = useContext(SalesCartTimer)
    const { salesCartTimerPopup, setSalesCartTimerPopup }: any = useContext(SalesCartTimerPopup)
    const [notificationCount, setNotificationCount] = useContext<any>(BadgesCount);
    const { activeTab }: any = useContext(ShowActiveTab)


    if (!AUTH_TOKEN) {
        return (
            <Navigate to={{ pathname: "/login", }} />
        );
    }

    const cancelCart = async () => {
        try {
            const response = await service.SalesCart.cancelCart()
            if (response.status === 200) {
                setSalesCartTimerPopup(false)
                localStorage.setItem('sales_count', '0')
                localStorage.setItem('salesCartTimerPopup', '0')
                window.location.reload()
            }
        }
        catch (error) {
            console.log('error', error)
        }
    }

    const handleSalesCartTimer = () => {

        if ((!salesCartTimer.isFirst)) {
            if (newTimer) {
                clearTimeout(newTimer)
            }
            newTimer = setTimeout(() => {
                let count: any = localStorage.getItem("sales_count") || 0
                let c = parseInt(count)
                if (c != 0) {
                    setSalesCartTimerPopup(true)
                    localStorage.setItem('salesCartTimerPopup', '1')

                }
            }, cpt);  // 14 minutes

            return () => clearTimeout(newTimer);
        }
    }
    const getStoreList = async () => {
        let dropdown: any = []
        const StoreResponse: any = await service.store.storeList()
        StoreResponse && StoreResponse?.data?.data.map((value: any) => {
            dropdown.push({
                "key": value.store.id,
                "value": value.store.id,
                "label": value.store.store_name,
            })
        })
        setStoreList(dropdown)
    }
    const switchStoreList = async (storeId: string) => {
        try {
            const response = await service.store.switchStore(storeId)
            if (response.status === 200) {
                localStorage.setItem("store_name", response?.data?.data?.store_name)
                localStorage.setItem("store_location", response?.data?.data?.store_location)

                setIsShow(false)
                window.location.reload()
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    const handleResumeBtn = async () => {

        try {
            await service.SalesCart.resumeCart()
            setSalesCartTimer({ isFirst: false, isStarted: !salesCartTimer.isStarted })
            setSalesCartTimerPopup(false)
            localStorage.setItem('salesCartTimerPopup', '1')
        }
        catch (error) {
            console.log('error', error)
        }
    }

    const showFooter = () => {
        if (activeTab === "Product Detail" || activeTab === "Warranty Detail" || activeTab === "Issue New Product") {
            return false;
        }
        return true
    }

    useEffect(() => {
        getStoreList()
    }, []);

    useEffect(() => {
        handleSalesCartTimer()
    }, [salesCartTimer]);

    useEffect(() => {
        // if ((!salesCartTimer.isFirst)) {
        if (autoCleanTimer) {
            clearTimeout(autoCleanTimer)
        }
        autoCleanTimer = setTimeout(() => {

            if (salesCartTimerPopup) {
                setSalesCartTimerPopup(false)
                localStorage.setItem('salesCartTimerPopup', '0')
                cancelCart()
            }
        }, cdt); // 30 sec

        return () => clearTimeout(autoCleanTimer);
        // }
    }, [salesCartTimerPopup])


    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header storeList={storeList} />
                <div className="main-content container-fluid">{props.children}</div>
            </div>
            {showFooter() ? <Footer /> : null}
            <>
                {(salesCartTimerPopup && (notificationCount?.sales_cart_count > 0)) &&
                    <Modal className="h-100 d-flex justify-content-center align-items-center" isOpen={salesCartTimerPopup}>
                        <ModalHeader className="d-flex justify-content-center border-0 position-relative" >
                            <div className="mt-4 fs-30 fw-600">Warning !!</div>
                        </ModalHeader>
                        <div className='m-4'>
                            <p className='fs-18 fw-500'>Please make any changes in cart or your cart will be empty after 30 seconds</p>
                        </div>
                        <ModalFooter className="mx-auto border-0 mb-4 gap-4">
                            <Button className='cancel-btn'
                                onClick={() => cancelCart()}
                            >Discard</Button>
                            <Button onClick={() => handleResumeBtn()}>Resume</Button>
                        </ModalFooter>
                    </Modal>
                }
            </>
            <>
                {
                    isShow && Object.keys(storeList).length > 1 &&
                    <Modal className="_custom-height" isOpen={isShow} >
                        <ModalHeader className="d-flex justify-content-center border-0 position-relative" >
                            <div className="mt-4 fs-30 fw-600">Store List</div>
                            <div className="position-absolute py-1 px-2" style={{
                                top: '0', right: '0', backgroundColor: '#222',
                                borderRadius: '0px 4px 0px 8px', cursor: 'pointer'
                            }} onClick={() => setIsShow(false)}>
                                <RxCross2 style={{ color: '#fff' }} /></div>

                        </ModalHeader>
                        <ModalBody className="rounded-10" style={{ height: 'auto' }}>
                            <Select
                                label={"Select Store"}
                                options={storeList}
                                name={"type"}
                                className={"w-100"}
                                placeholder={"Select store "}
                                onChange={(e: any) => switchStoreList(e?.value)}
                            ></Select>
                        </ModalBody>
                    </Modal>
                }
            </>
        </React.Fragment>
    )
}

export default AuthLayout
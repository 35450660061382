import React, { useState, useEffect } from 'react'
import atoms from '../../atoms'
import molecules from '../../molecules';
import service from '../../../api/services';
import Images from '../../../assets/Images'
import { useToasts } from 'react-toast-notifications'
const { CustomSearch, Button } = atoms
const { Coin, Currency, PlaceholderImg } = Images
const { SalesReturnCard, SalesReturnPopup } = molecules

const Index = ({ salesReturnData, setSalesReturnData, salesReturnList, handleChange, handleModalSubmit, salesReturnListData, showSidebarModal, setShowSidebarModal }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const { addToast } = useToasts();
    const [salesReturnItemId, setSalesReturnItemId] = useState<string>("")
    const [salesReturnProductId, setSalesReturnProductID] = useState<string>("")
    const [showEmptyCard, setShowEmptyCard] = useState<boolean>(true)

    const handleSalesReturnButton = async (id: string, productId: string) => {
        try {
            const response = await service?.salesReturnService?.checkWarrantyClaimAvailability(productId)
            if (response?.status === 201) {
                setShowSidebarModal(!showSidebarModal)
                setSalesReturnProductID(id)
                setSalesReturnItemId(productId)
            }
        } catch (error: any) {
            addToast(error.response.data.message, {
                appearance: 'error',
                className: "zindex-tooltip",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    useEffect(() => {
        if (!showSidebarModal) {
            setSalesReturnData({ ...salesReturnData, conditionRemark: "", issue_type: NaN })
        }
    }, [showSidebarModal])

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className=''>
                        <CustomSearch
                            setIsOpen={setIsOpen}
                            searchCallBack={(search: string) => { salesReturnListData(search); setShowEmptyCard(true); }}
                            placeholder='Search Invoice ID'
                            className='fs-16'
                            heading="Search"
                            buttonStyle="w-25" />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col mt-4">
                    {
                        salesReturnList?.length > 0 ? (
                            salesReturnList?.map((data: any, index: number) => (
                                (data?.product ?
                                    <SalesReturnCard
                                        key={index} // Add a unique key for each mapped element
                                        salesReturnData={salesReturnData}
                                        handleSalesReturnButton={(id: any, productId: string) => handleSalesReturnButton(id, productId)}
                                        isViewData={data}
                                        setShowEmptyCard={(status: boolean) => setShowEmptyCard(status)}
                                    /> : ""
                                )
                            ))
                        ) : (
                            showEmptyCard ? <div className='justify-content-center d-flex mt-5 secondary-color'>
                                There are no records to display
                            </div> : ""
                        )
                    }
                </div>
            </div>

            {!showEmptyCard && <div className='justify-content-center d-flex mt-5 secondary-color'>
                There are no records to display
            </div>}

            {showSidebarModal && <div>
                <SalesReturnPopup
                    show={showSidebarModal}
                    onHide={() => setShowSidebarModal(false)}
                    salesReturnProductId={salesReturnProductId}
                    handleChange={(name: string, value: any) => handleChange(name, value)}
                    handleModalSubmit={() => handleModalSubmit(salesReturnItemId)}
                    salesReturnData={salesReturnData}
                />
            </div>}
        </div>
    )
}

export default Index
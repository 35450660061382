import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { prescription } = Dictionary

const Prescription = {
    searchPrescription: async (paramsObj: {}) => {
        return axios.get(BASE_URL + prescription.searchPrescription(), { params: paramsObj })
    },
    sharePrescription: async (paramsObj: {}) => {
        return axios.get(BASE_URL + prescription.sharePrescription(), { params: paramsObj })
    },

}
export default Prescription
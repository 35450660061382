import React, { useState, useEffect, useRef, useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
import { useToasts } from 'react-toast-notifications'
import service from '../../../api/services';
import { useNavigate } from "react-router-dom";
import { getDropdownValue } from "../../../constants/utils"
import constant from '../../../constants/constant';
import { ShowActiveTab } from "../../../Context"
import { useParams } from 'react-router';
const { TopBar } = molecules
const { WarrantyDetail } = organisms

const Index = () => {
  const navigate = useNavigate()
  const { setActiveTab }: any = useContext(ShowActiveTab)
  const params = useParams()
  const product_ID: string = params.id || ''
  const { addToast } = useToasts();
  const fileInputRef = useRef<any>(null);
  const [previewImage, setPreviewImage] = useState<any>([])
  const [warrantyUpdated, setWarrantyUpdated] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>({})
  const [warrantyProductData, setWarrantyProductData] = useState<any>({
    claimed_for: "",
    condition_remark: "",
    images: []
  })

  const handleSubmitButton = async () => {
    const formData = new FormData();
    { formData.append('claimed_for', warrantyProductData?.claimed_for?.value); }
    { formData.append('condition_remark', warrantyProductData?.condition_remark); }
    { warrantyProductData?.images?.forEach((image: any) => formData.append('images', image)); }
    const isValid = validate();

    if (isValid) {
      try {
        const response = await service?.warrantyService?.warrantyProductSubmit(product_ID, formData)
        if (response.status === 201) {
          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          navigate("/claimed")
        }
      } catch (error: any) {
        console.log('error', error)
        addToast(error.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    }
  }

  const validationRules: any = {
    claimed_for: {
      "label": {
        required: true,
      },
    },
    condition_remark: {
      required: true,
    }
  }

  const validate = () => {
    let newErrors: any = {};
    for (const field in validationRules) {
      if (validationRules.hasOwnProperty(field)) {
        if ((validationRules[field]?.label?.required) && (!warrantyProductData[field]?.label)) {
          newErrors[field] = "Please Select field";
      } else if (validationRules[field]?.required && !warrantyProductData[field]) {
          newErrors[field] = "This field is required.";
        } 
    }
  }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleWarrantyProductDetail = async () => {
    try {
      const response = await service?.warrantyService?.warrantyProductDetail(product_ID)
      if (response?.status === 200) {
        setWarrantyProductData({
          ...warrantyProductData,
          claimed_for: getDropdownValue(constant?.warrantee_claim_for, response?.data?.data?.claimed_for),
          condition_remark: response?.data?.data?.condition_remark,
          images: response?.data?.data?.images
        })
        setPreviewImage(response?.data?.data?.images)
        setWarrantyUpdated(true)
      }
    } catch (error) {

    }
  }

  const handleWarrantyUpdated = async () => {
    const formData = new FormData();
    { formData.append('claimed_for', warrantyProductData?.claimed_for?.value); }
    { formData.append('condition_remark', warrantyProductData?.condition_remark); }
    { warrantyProductData?.images?.forEach((image: any) => formData.append('images', image)); }

    try {
      const response = await service?.warrantyService?.warrantyProductUpdate(product_ID, formData)
      if (response.status === 200) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        navigate("/claimed")
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const handelChange = (name: string, value: string) => {
    setWarrantyProductData({ ...warrantyProductData, [name]: value })
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  }

  const _handleImageChange = (event: any) => {
    const files = event.target.files;
    const updatedImages: any = [...warrantyProductData?.images];
    const updateImageUrl: any = [...previewImage];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const imageUrl = URL.createObjectURL(file);
      updateImageUrl.push(imageUrl)
      updatedImages.push(file);
    }
    setPreviewImage(updateImageUrl)
    setWarrantyProductData({ ...warrantyProductData, images: updatedImages })
  };


  const handleImageRemove = (image: any, index: number) => {
    if (fileInputRef && fileInputRef.current && fileInputRef.current.value == null) {
      fileInputRef.current.value = '';
    }
    let tempPreview = [...previewImage]
    let tempStoreImage = warrantyProductData?.images
    tempPreview.splice(index, 1)
    setPreviewImage(tempPreview)
    tempStoreImage.splice(index, 1)
    setWarrantyProductData({ ...warrantyProductData, images: tempStoreImage })
    imagesDelete(image)
  }

  const imagesDelete = async (url: any) => {
    try {
      let payload = {
        "url": url,
        "product_id": params?.id
      }
      await service.warrantyService.warrantyProductImageDelete(payload)
    } catch (error: any) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    handleWarrantyProductDetail()
    setActiveTab("Warranty Detail") 
  }, [])

  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Warranty' />
      </div>
      <div className='row mt-md-4'>
        <WarrantyDetail
          errors={errors}
          warrantyProductData={warrantyProductData}
          handelChange={(name: string, value: any) => handelChange(name, value)}
          fileInputRef={fileInputRef}
          _handleImageChange={_handleImageChange}
          previewImage={previewImage}
          handleImageRemove={handleImageRemove}
          warrantyUpdated={warrantyUpdated}
          handleSubmitButton={() => { warrantyUpdated ? handleWarrantyUpdated() : handleSubmitButton() }} />
      </div>
    </Wrapper>
  )
}

export default Index
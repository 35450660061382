import React from 'react'
import atoms from '../../atoms';
import constant from '../../../constants/constant';
import { findConstantLabel } from '../../../constants/utils'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import moment from 'moment';

const { CustomImages } = atoms
const Index = ({ barcodeData }: any) => {

    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-6 mt-1'>
                    <div className='card'>
                        <CustomImages src={barcodeData?.product?.images[0]} imageHeight={window.innerWidth>768 ? "450px" : "150px" }/>
                    </div>
                </div>
                <div className='col-12 col-md-6 mt-1'>
                    <div className='d-flex align-items-center'>
                        <h2 className=' mb-0 fs-16 fw-700' style={{ paddingRight: 0, width:"7rem" }}>Product ID :</h2>
                        <div className=' px-0 d-flex'>
                            <div className='id-input-design d-flex align-items-center'>
                                <h2 className='mb-0 fs-16 fw-400 dark-gray'>{barcodeData?.product?.product_id_display ? barcodeData?.product?.product_id_display : ""}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center mt-md-3 mt-2'>
                        <h2 className=' mb-0 fs-16 fw-700' style={{ paddingRight: 0,width:"7rem"  }}>Barcode :</h2>
                        <div className=' px-0 d-flex'>
                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                <h2 className='mb-0 fs-16 fw-400 dark-gray'>{barcodeData?.bar_code ? barcodeData?.bar_code : ""}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="mt-md-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-2">
                            <span className='fs-md-20 fs-18 fw-500'>{barcodeData?.product?.brand?.brand_name ? barcodeData?.product?.brand?.brand_name : ""}</span>
                        </div>
                    </div>
                    {barcodeData?.product?.description && <div className="mt-md-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-md-3 gap-1 ">
                            <span className='fs-md-18 fs-16 fw-700'>{barcodeData?.product?.description}</span>
                        </div>
                    </div>}
                    <div className='row mt-md-0 mt-2'>
                        <div className='col-6 pe-0'>
                            <div className="mt-md-3 mt-2">
                                <div className="d-flex flex-wrap align-items-center gap-md-2 gap-1">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Product SRP :</h2>
                                    <span className='fs-md-18 fs-14 fw-400'>₹{barcodeData?.product?.product_srp ? barcodeData?.product?.product_srp : ""}</span>
                                </div>
                            </div>
                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-md-3 gap-1">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Brand Color Code :</h2>
                                    <span className='fs-md-18 fs-14 fw-400'>{barcodeData?.product?.brand_color_code}</span>
                                </div>
                            </div>
                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-md-2 gap-1 ">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Lense Color :</h2>
                                    {barcodeData?.product?.lens_colors && barcodeData?.product?.lens_colors?.map((item: any, index: number) => {
                                        return (
                                            <span className='fs-md-18 fs-14 fw-400'>{item?.color_name},</span>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-md-2 gap-1">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Glass Size :</h2>
                                    <span className='fs-md-18 fs-14 fw-400'>{barcodeData?.product?.glass_size ? barcodeData?.product?.glass_size : ""}MM</span>
                                </div>
                            </div>
                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Product With Case :</h2>
                                    <span className='fs-md-18 fs-14 fw-400'>{barcodeData?.product?.product_with_case ? "Yes" : "No"}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-2 ">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Bridge Size :</h2>
                                    <span className='fs-md-18 fs-14 fw-400'>{barcodeData?.product?.bridge_size ? barcodeData?.product?.bridge_size : ""}MM</span>
                                </div>
                            </div>

                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Warranty :</h2>
                                    <span className='fs-md-18 fs-14 fw-400'>{barcodeData?.product?.warranty ? findConstantLabel(constant?.PRODUCT_WARRANTY, barcodeData?.product?.warranty) : "-"}</span>
                                </div>
                            </div>

                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Product Color :</h2>
                                    {barcodeData?.product?.specx_colors && barcodeData?.product?.specx_colors?.map((item: any, index: number) => {
                                        return (
                                            <span className='fs-md-18 fs-14 fw-400'>{item?.color_name},</span>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Temple Size :</h2>
                                    <span className='fs-md-18 fs-14 fw-400'>{barcodeData?.product?.temple_size ? barcodeData?.product?.temple_size : ""}MM</span>
                                </div>
                            </div>

                            <div className="mt-md-3 mt-1">
                                <div className="d-flex flex-wrap align-items-center gap-2">
                                    <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Date of Last Audit :</h2>
                                    <span className='fs-md-18 fs-14 fw-400'>{barcodeData?.audit_trails?.length > 0 ? moment(barcodeData?.audit_trails[0]?.createdAt)?.format("DD/MM/YYYY") : ""}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-md-3 mt-1">
                        <div className="d-flex flex-wrap align-items-center gap-2">
                            <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Condition of Items :</h2>
                            <span className='fs-md-18 fs-14 fw-400'>{findConstantLabel(constant?.INVENTORY_ITEM_CONDITION, barcodeData?.item_condition)}</span>
                        </div>
                    </div>
                    <div className="mt-md-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-2">
                            <h2 className='mb-0 fs-md-18 fs-14 fw-600'>Product Condition Remark :</h2>
                            <span className='fs-md-18 fs-14 fw-400'>{barcodeData?.condition_remark}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index
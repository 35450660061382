import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalCount: 0,
  currentCount: 0,
};

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setCurrentCount: (state, action) => {
      state.currentCount = action.payload;
    },
  },
});

export const {setTotalCount,setCurrentCount,} = counterSlice.actions;

export default counterSlice.reducer;

import auth from './auth'
import Product from './product'
import SalesCart from './salesCart'
import PendingOrder from './pendingOrder'
import Audit from './auditService'
import transferRequestService from './transferRequest'
import store from './store'
import salesReturnService from './salesReturn'
import warrantyService from './warranty'
import Prescription from './prescription'
import DashboardService from './dashboard'
import PaymentVoucherService from './paymentVoucher'

const service = {
  auth,
  Product,
  SalesCart,
  PendingOrder,
  Audit,
  transferRequestService,
  store,
  salesReturnService,
  warrantyService,
  Prescription,
  DashboardService,
  PaymentVoucherService,
}
export default service

import React from "react";
import atoms from "../../atoms";
// import Delete from "../../../../assets/Images/dustbin.svg"
import constant from "../../../constants/constant";
import Delete from "../../../assets/Images/dustbin.svg"
import { handleDecimalInputChange } from "../../../constants/utils"
import { VoucherComponent } from './interface'

const { Input, Select, Image } = atoms

const Index = ({ Index, handleChange, amount, handleDeleteComponent, addComponent, mode_of_payment, transaction_number }: any) => {

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-6 mb-3 voucher-padding-right">
                                <Select
                                    className={"w-100 fs-16"}
                                    options={constant.mode_of_payment}
                                    value={mode_of_payment}
                                    onChange={(e: any) => handleChange("mode_of_payment", e)}
                                    placeholder={"Mode of Payment"}
                                    label={"Mode Of Payment"} />
                            </div>
                            <div className="col-6 mb-3">
                                <Input
                                    label={"Amount"}
                                    className="fs-16"
                                    value={amount}
                                    placeholder={"Amount"}
                                    type="number" onChange={(e: any) => handleDecimalInputChange(handleChange, e.target.value)}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            {mode_of_payment?.value === constant?.mode_of_payment[1]?.value && <div className="col-8">
                                <Input
                                    label={"Transaction Number"}
                                    className="fs-16"
                                    value={transaction_number}
                                    placeholder={"Enter Transaction Number"}
                                    type="text"
                                    onChange={(e: any) => handleChange("transaction_number", e?.target?.value)}
                                />
                            </div>}
                            <div className="col-4 col-md-4 d-flex gap-3 remove-padding-left" style={{ marginTop:(window.innerWidth<768 && !(mode_of_payment?.value === constant?.mode_of_payment[1]?.value)) ? "" : "2.54rem" }}>
                                <div className="d-flex">
                                    <div className=" cursor-pointer mt-1" onClick={handleDeleteComponent}>
                                        <Image src={Delete} />
                                    </div>
                                </div>
                                {Index && <div className="d-flex cursor-pointer" onClick={() => addComponent()}>
                                    <p className="brand-add-Button text-center">
                                        <span className="fs-20">+</span>
                                    </p>
                                </div>}
                            </div>
                            {!(mode_of_payment?.value === constant?.mode_of_payment[1]?.value) && <div className="col-8"></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index
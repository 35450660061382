import BackIcon from './backIcon.svg';
import Cart from './cart.svg';
import Copy from './copy.svg';
import CoverImage from './coverImage.png';
import FilterImage from './filter.svg';
import Logo from './logo.png';
import OfferBadge from './offerBadge.svg';
import PlaceholderImg from './placeholderimg.png';
import User from './user.svg';
import Currency from './currency.svg';
import Coin from './coin.svg';
import confirmationCheck from './confirmationcheck.svg';
import SuccessIcon from './Success-Icon.png';
import Download from './download.svg';
import Subtract from './subtract.svg';
import AuditProductImg from './AuditProductImg.jpg';
import uploadimg from './uploadimg.svg';
import CancelIcon from './CancelIcon.png';


const Images = {
    BackIcon,
    Cart,
    Copy,
    CoverImage,
    FilterImage,
    Logo,
    OfferBadge,
    PlaceholderImg,
    User,
    Currency,
    Coin,
    confirmationCheck,
    Download,
    Subtract,
    AuditProductImg,
    uploadimg,
    SuccessIcon,
    CancelIcon

}
export default Images
import React from 'react'
import './productTopbar.scss'
import atoms from '../../../../components/atoms';
import Images from '../../../../assets/Images';
import { useNavigate } from 'react-router';
import { FiSearch } from "react-icons/fi";
import { MdFilterAlt } from "react-icons/md";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { TbArrowsSort } from "react-icons/tb";
import { RootState, AppDispatch } from '../../../../store/store';
import { setSortOrder } from '../../../../features/productList/availableFilter'
import constant from '../../../../constants/constant';
const { Image } = atoms
const { BackIcon } = Images

const ProductListTopBar = ({ heading, currentCount, totalCount, setShowSearchTab, showSearchTab, setShowFilter, showFilter }: any) => {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const reduxCounter = useSelector((state: RootState) => state?.rootReducer?.counterSlice);
    const reduxProductList = useSelector((state: RootState) => state?.rootReducer?.productListSlice);
    const reduxPriceSort = useSelector((state: RootState) => state.rootReducer.isAvailable?.sort_order)
    return (
        <>
            <div className='mt-1'>
                <div className='d-flex justify-content-between align-items-center gap-1'>
                    <div className='d-flex gap-md-1 gap-1'>
                        <div>
                            <Image src={BackIcon} alt="Back Icon" className='img-fluid cursor-pointer back-icon-width mb-0' onClick={() => navigation(-1)} />
                        </div>
                        <div className='d-flex justify-content-center my-auto gap-2'>
                            <div className='my-auto top-bar-text-width'>
                                <h2 className='fs-md-20 fs-16 fw-600 mb-0'>{heading}</h2>
                            </div>
                            <div className='mb-0 top-bar-count-width'>
                                {reduxProductList?.list?.length} / {reduxCounter?.totalCount}
                            </div>
                        </div>
                    </div>
                    <div className='fs-md-18 fs-16 fw-600'>
                        <div className=' d-flex gap-3 align-item-center'>
                            <div className='my-auto d-lg-none mt-1'>
                                <button
                                    className='btn pe-0 me-0'
                                    style={{ borderColor: "white" }}
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false" >
                                    <div className='d-flex gap-1 align-item-center my-auto'>
                                        <TbArrowsSort className='fs-18 my-auto' />
                                        {/* <span className='fs-16 fw-500'>
                                            Sort
                                        </span> */}
                                    </div>
                                </button>
                                <ul
                                    className="dropdown-menu zindex-tooltip"
                                    aria-labelledby="dropdownMenuButton1"
                                    style={{ width: "175px" }}
                                >
                                    {<li>
                                        <button
                                            className={`dropdown-item ${(constant?.Product_List_Price_Sort?.Low_To_High === reduxPriceSort) && 'top-bar-active-tab'}`} >
                                            <div className='d-flex align-items-center'
                                            onClick={()=>dispatch(setSortOrder(constant?.Product_List_Price_Sort?.Low_To_High))}
                                            >
                                             
                                                <span className='ms-2 mb-0'>Price: Low to High</span>
                                            </div>
                                        </button>
                                    </li>}

                                  
                                    {<li>
                                        <hr className="my-1"></hr>
                                    </li>}
                                    <li>
                                        <button
                                            className={`dropdown-item ${(constant?.Product_List_Price_Sort?.High_To_Low === reduxPriceSort) && 'top-bar-active-tab'}`}
                                            onClick={()=>dispatch(setSortOrder(constant?.Product_List_Price_Sort?.High_To_Low))}
                                        >
                                            <div className={'d-flex align-items-center'}>
                                               
                                                <span className='ms-2 mb-0'>Price:High to Low</span>
                                            </div>
                                        </button>
                                     
                                    </li>
                                </ul>
                            </div>
                            <div className='d-lg-none my-auto'>
                                <FiSearch className='fs-18' onClick={() => setShowSearchTab(!showSearchTab)} />
                            </div>
                            <div className='d-lg-none my-auto'>
                                {!showFilter ? <MdFilterAlt className='fs-18' onClick={() => setShowFilter(!showFilter)} /> : <MdFilterAltOff className='fs-18' onClick={() => setShowFilter(!showFilter)} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductListTopBar
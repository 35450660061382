import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper';
import organisms from '../../../components/organisms';
import { ShowActiveTab } from '../../../Context';
import molecules from '../../../components/molecules';
import service from '../../../api/services';
import { Default_Page_Limit } from '../../../constants/defaultValues'

const { TransferRequest } = organisms
const { TopBar } = molecules

const Index = () => {
    const [productList, setProductList] = useState<[] | undefined>();
    const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, setSearch] = useState<string | undefined>();

    const { setActiveTab }: any = useContext(ShowActiveTab)

    useEffect(() => {
        setActiveTab("transferRequest")
        list()
    }, [search])


    const list = async () => {
        try {
            const response = await service.transferRequestService.list({ "page": currentPage, "search": search, "limit": limitPerPage, "status": '' })
            // const response = await service.procurementService.listByOther({ "page": currentPage, "search": search, "limit": limitPerPage, "status": '' })
            if (response.status === 200) {
                setProductList(response?.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    return (
        <Wrapper>
            <div className='row align-items-center'>
                <TopBar heading='Transfer Request' />
            </div>
            <TransferRequest
                productList={productList}
                setSearch={setSearch}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLimitPerPage={setLimitPerPage}
                limit={limitPerPage} />

        </Wrapper>
    )
}
export default Index

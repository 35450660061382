import React from 'react';
import { limitOptions } from '../../../constants/defaultValues';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './Pagination.scss'

const Pagination = ({
    currentPage,
    totalPages,
    onChangePage,
    limit,
    setLimit
}: any) => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const generatePageNumbers = () => {
        const pageNumbers = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 5; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            } else if (currentPage >= totalPages - 2) {
                pageNumbers.push(1);
                pageNumbers.push('...');
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pageNumbers.push(i);
                }
            } else {
                pageNumbers.push(1);
                pageNumbers.push('...');
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            }
        }

        return pageNumbers;
    };
    return (
        <>
            {totalPages ?
                <div 
                className='row align-items-center custom-pagination px-3'
                >

                    <div className="col-12 col-md-6 custom-limit-dropdown" >
                        <select
                            className='count-style'
                            value={limit}
                            onChange={(e) => {
                                setLimit(Number(e.target.value));
                            }}
                        >
                            {limitOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-12 col-md-6 d-flex gap-4 align-items-center justify-content-md-end justify-content-center">
                        <button
                            onClick={() => onChangePage(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="align-items-center border-0 btn d-flex justify-content-center"
                        >
                            <IoIosArrowBack />
                            Previous
                        </button>
                        {generatePageNumbers().map((pageNumber, index) => (
                            <span
                                key={index}
                                className={`${pageNumber === currentPage ? 'active-background' : ''} cursor-pointer`}
                                onClick={() => {
                                    if (pageNumber !== '...') {
                                        onChangePage(pageNumber);
                                    }
                                }}
                            >
                                {pageNumber}
                            </span>
                        ))}
                        <button
                            className="align-items-center border-0 btn d-flex justify-content-center"
                            onClick={() => onChangePage(currentPage + 1)}
                            disabled={currentPage >= totalPages}
                        >
                            Next
                            <IoIosArrowForward />
                        </button>
                    </div>

                </div>
                : ""
            }
        </>
    );
};

export default Pagination;

import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import atoms from "../../atoms";
import './sideModal.scss'
import { RxCross2 } from "react-icons/rx";
import constant from "../../../constants/constant";
import { inherits } from "util";

const { Button, Image, Input } = atoms

const Index = (props: any) => {
    return (
        <>
            <Modal className="rounded-0"
                isOpen={props.show}
                wrapClassName="modal-right"
            >
                <ModalHeader className="d-flex border-0 position-relative" >
                    <div className="mt-4 fs-24 fw-600 mb-0">{props.header}</div>
                    <div className="position-absolute py-1 px-2 cross-icon-style"
                        onClick={props.onHide}><RxCross2 style={{ color: '#222' }} />
                    </div>
                </ModalHeader>
                <ModalBody className="pt-md-0">
                    {props?.children}
                </ModalBody>
                <hr className="mt-3" style={{ border: '1px dashed #222', }} />

                <ModalFooter className="border-0 d-flex justify-content-center" style={{ flexWrap: 'inherit' }}>
                    <Button className="w-100" onClick={props?.handleModalSubmit}>{props.submitBtnText}</Button>
                    {/* {renderButtons()} */}
                </ModalFooter>

            </Modal>
        </>
    )
}

export default Index
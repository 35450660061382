import React from 'react'
import { InputInterface } from './interface'
import './Input.scss'
const RadioInput = ({ id, name, defaultValue, onChange, className, label, htmlFor, value, checked, required, ref, labelClass }: InputInterface) => {
    return (
        <div className={`form-group ${className}`}>
            <input
                id={id}
                className={`shadow-none cursor-pointer`}
                type={'radio'}
                name={name}
                defaultValue={defaultValue}
                onChange={onChange}
                value={value}
                checked={checked}
                required={required}
                ref={ref}
            />
            <label className={`inputs-label mb-1 ${labelClass ? labelClass : 'fs-16'} fw-600 ms-2 secondary-color`} htmlFor={id}>{label}</label>
        </div>
    )
}

export default RadioInput
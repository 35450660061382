import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
import service from '../../../api/services';
import { ShowActiveTab } from '../../../Context';
import { Default_Page_Limit } from '../../../constants/defaultValues'


const { TopBar } = molecules
const { Claimed } = organisms

const Index = () => {
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [claimedProductData, setClaimedProductData] = useState<any>();
  const [claimProductDetail, setClaimedProductDetail] = useState<any>()
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();

  const { setActiveTab }: any = useContext(ShowActiveTab)



  const getClaimedProductData = async () => {
    try {
      const response = await service.warrantyService.claimedProductList({ search: search, page: currentPage, limit: limitPerPage })
      if (response.status === 200) {
        setClaimedProductData(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }


  const handleWarrantyReject = async (id: string) => {
    try {
      const response = await service.warrantyService.claimProductReject(id)
      if (response.status === 200) {
        getClaimedProductData()
      }

    } catch (error) {
      console.log('error', error)
    }
  }

  const getDetailById = async (id: string) => {
    try {
      const response = await service.warrantyService.claimProductDetail(id)
      if (response.status === 200) {
        setClaimedProductDetail(response?.data?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getClaimedProductData()
  }, [search, currentPage, limitPerPage])

  useEffect(() => {
    setActiveTab("claim")
    setCurrentPage(1)
    if (currentPage == 1 && initialLoading) {
      getClaimedProductData()
    }
    setInitialLoading(true)
  }, [limitPerPage])
  return (
    <Wrapper>
      <div className='row align-items-center mb-3'>
        <TopBar heading='Claim Product' />
      </div>
      <div>
        <Claimed
          claimedProductData={claimedProductData}
          searchCallBack={(e: string) => setSearch(e)}
          currentPage={currentPage}
          limit={limitPerPage}
          setLimitPerPage={(limit: number) => setLimitPerPage(limit)}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          handleWarrantyReject={(id: string) => handleWarrantyReject(id)}
          getDetailById={(id: string) => getDetailById(id)}
          claimProductDetail={claimProductDetail}
        />
      </div>
    </Wrapper>
  )
}

export default Index
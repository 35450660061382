import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const initialState: any = {
  list: [],
  listLocation: 0
};

const productListSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    setProductList: (state, action: PayloadAction<string[]>) => {
      const combinedArray = [...state.list, ...action.payload]
      state.list = combinedArray;
    },
    setNewProductList: (state, action: PayloadAction<string[]>) => {
      state.list = action.payload;
    }, 
  },
});

export const { setProductList, setNewProductList} = productListSlice.actions;

export default productListSlice.reducer;

import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import atoms from '../../../components/atoms';
import organisms from '../../../components/organisms';
import { ShowActiveTab } from '../../../Context';
import service from '../../../api/services';
import { RxCross2 } from 'react-icons/rx';
import Images from '../../../assets/Images'
import ProductListTopBar from "./ProductListTopBar"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TbArrowsSort } from "react-icons/tb";

import { useDispatch, useSelector } from 'react-redux';
import { fetchFilterList } from '../../../features/productList/filter/checkboxAPI';
import { fetchProductsList } from "../../../features/productList/list/productListAPI"
import { setProductList, setNewProductList } from '../../../features/productList/list/listSlice';
import { setListLocation } from '../../../features/productList/listLocation/listLocation';
import { setPageCount, setTotalPageCount, setPageLimit } from '../../../features/productList/pageCount/pageCount';
import { setIsAvailableCheck, setIsPicturesCheck, setSortOrder } from "../../../features/productList/availableFilter/index"


import { setAllLabels } from '../../../features/productList/bages/bagesSlice';
import { setSelectedCheckboxes } from "../../../features/productList/filter/checkboxSlice"
import { RootState, AppDispatch } from '../../../store/store';
import { setTotalCount, setCurrentCount } from "../../../features/productList/counterSlice"
import { Navigate } from 'react-router';
import constant from '../../../constants/constant';

// import InfiniteScroll from 'react-infinite-scroll-component'

const { ProductList } = organisms
const { Filter } = molecules
const { Search, Button, Image } = atoms
const { FilterImage } = Images
interface Checkbox {
  id: string;
  name: string;
}

interface SelectedCheckboxes {
  [key: string]: Checkbox[];
}

const Index = () => {
  const dispatch: AppDispatch = useDispatch();
  const status = useSelector((state: RootState) => state?.rootReducer.checkboxAPI.status);

  // store data for this page
  const reduxSelectCheckboxes = useSelector((state: RootState) => state?.rootReducer.checkboxReducer);
  const reduxFilterList: any = useSelector((state: RootState) => state?.rootReducer?.checkboxAPI.products);
  const reduxAllLabels = useSelector((state: RootState) => state?.rootReducer.bagesSlice);
  const reduxProductList: any = useSelector((state: RootState) => state?.rootReducer.productListSlice);
  const reduxCheckboxAPI = useSelector((state: RootState) => state?.rootReducer?.checkboxAPI);
  const reduxPageCount = useSelector((state: RootState) => state?.rootReducer?.pageCount);
  const reduxIsAvailableCheck = useSelector((state: RootState) => state?.rootReducer?.isAvailable);
  const reduxProductListLocation = useSelector((state: RootState) => state?.rootReducer?.listLocation);
  const reduxCounter = useSelector((state: RootState) => state?.rootReducer?.counterSlice);
  const reduxTotalPageCount = useSelector((state: RootState) => state?.rootReducer?.pageCount)
  const reduxLimitPerPage = useSelector((state: RootState) => state?.rootReducer.pageCount.limit)
  const reduxPriceSort = useSelector((state: RootState) => state.rootReducer.isAvailable?.sort_order)

  // const reduxIsScroll = useSelector((state: RootState) => state?.rootReducer.isAvailable?.isScroll)

  const controlledRef = useRef<AbortController>();
  const [isLoading, setIsLoading] = useState<number>(0)
  const [isScroll, setIsScroll] = useState<boolean>(true)

  const [search, setSearch] = useState<string>('')
  const [range, setRange] = useState<number[]>([0, 50000]);
  const [previousRange, setPreviousRange] = useState<number[]>([0, 50000]);

  // const [totalPage, setTotalPage] = useState<number>(1)
  const [initialRendering, setInitialRendering] = useState<boolean>(false)
  const scrollContainerRef = useRef(null);
  const debounce: any = useRef(null);
  const inputElement: any = useRef(null)

  const [showFilter, setShowFilter] = useState(window.innerWidth < 876 ? false : true)
  const [showSearchTab, setShowSearchTab] = useState<boolean>(window.innerWidth < 876 ? false : true)
  const [prevSelectedCheckboxes, setPrevSelectedCheckboxes] = useState(reduxSelectCheckboxes);

  const { activeTab, setActiveTab }: any = useContext(ShowActiveTab)

  const calculateProductsToAdd = (check: boolean, count: number): boolean => {
    return !check && count > 1;
  }

  const getProductList = async (count: number, check: boolean) => {

    const shouldAddProducts = calculateProductsToAdd(check, count);

    setIsLoading(prevCount => prevCount + 1)
    // if (controlledRef.current) {
    //   if (previousPriority === 'high') {
    //     if (apiPriority === 'medium') {
    //       setPreviousPriority(apiPriority)
    //       setIsLoading(prevCount => prevCount - 1)
    //       return
    //     }
    //   }
    // }

    if (check && controlledRef.current) {
      controlledRef.current.abort()
    }

    controlledRef.current = new AbortController();

    // controlledRef.current.abort()
    const signal = controlledRef.current.signal;

    let priceRange = {
      min: range[0],
      max: range[1]
    }
    let convertedCheckboxes: SelectedCheckboxes = {};
    for (let key in reduxSelectCheckboxes) {
      convertedCheckboxes[key] = reduxSelectCheckboxes[key].map((item: any) => item.id);
    }
    const requestPayload: any = {
      available_at_store: reduxIsAvailableCheck?.isAvailableCheck,
      search: search,
      brand: (convertedCheckboxes.brand),
      type: (convertedCheckboxes.type),
      polarized: (convertedCheckboxes.polarized),
      frame_materials: (convertedCheckboxes.frame_materials),
      product_shapes: (convertedCheckboxes.product_shapes),
      rim_type: (convertedCheckboxes.rim_type),
      features: (convertedCheckboxes.features),
      gender: (convertedCheckboxes.gender),
      price: (priceRange),
      sw_color: (convertedCheckboxes.sw_color_list),
      lens_color: (convertedCheckboxes.lens_color),
      bridge_size: (convertedCheckboxes.bridge_size),
      weight: (convertedCheckboxes.weight),
      glass_size: (convertedCheckboxes.glass_size),
      temple_size: (convertedCheckboxes.temple_size),
      limit: reduxLimitPerPage,
      page: check ? 1 : count
    };

    // Conditionally add the 'pictures' key if isPictures is true
    if (reduxIsAvailableCheck?.isPictures) {
      requestPayload.pictures = reduxIsAvailableCheck.isPictures;
    }
    if (reduxIsAvailableCheck?.sort_order === constant?.Product_List_Price_Sort.High_To_Low) {
      requestPayload.sort_order = reduxIsAvailableCheck?.sort_order;
    }
    try {
      const response: any = await service.Product.productList(requestPayload, signal)

      if (response.status === 200) {
        dispatch(setTotalCount(response.data.total_count))
        dispatch(setCurrentCount(response.data.count))
        dispatch(setTotalPageCount(response.data.totalPages))

        // Do not remove unique id code
        // if ((!check && count > 1)) {
        //   const combinedArray = [...reduxProductList?.list, ...response.data.data];
        //   const uniqueIds = new Set<number>();
        //   // Filter the combined array to include only unique entries based on ID
        //   const uniqueArray = combinedArray?.filter((item) => {
        //     if (!uniqueIds.has(item.id)) {
        //       uniqueIds.add(item.id);
        //       return true;
        //     }
        //     return false;
        //   });
        //   // Update the state with the unique array
        //   // dispatch(setProductList(uniqueArray));
        //   setIsLoading(prevCount => prevCount - 1)

        // }
        setIsScroll(false)
        if (shouldAddProducts) {
          dispatch(setPageLimit(48))
          dispatch(setProductList(response.data.data));
        } else {
          dispatch(setPageLimit(48))
          dispatch(setNewProductList(response?.data?.data))
          const element: any = scrollContainerRef.current;
          if (element) {
            element.scrollTop = 1;
          }
          dispatch(setListLocation(0))
        }
        setIsLoading(prevCount => prevCount - 1)
      }
    }

    catch (error) {
      console.log('error', error)
      setIsLoading(prevCount => prevCount - 1)
    }

  }

  const handlePriceRange = useCallback((event: any, newValue: any) => {
    setRange(newValue);
    if (!newValue) {
      setRange([0, 50000])
    }
  }, [range])

  const handleInputPrice = useCallback((e: any) => {
    setIsScroll(false)
    if (e.target.name == "minPrice") {
      setRange([e.target.value, range[1]])
    }
    else {
      setRange([range[0], e.target.value])
    }
  }, [range])

  const handleSearchDebounced = (value: any) => {
    if (inputElement.current) {
      clearTimeout(inputElement.current);
    }
    inputElement.current = window.setTimeout(() => {
      dispatch(setPageCount(1));
      setSearch(value);
    }, 500)
  }

  const _handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchDebounced(e);
  }, [handleSearchDebounced]);

  const handleScroll = async () => {
    setIsScroll(true)
    const element: any = scrollContainerRef.current;
    if (element) {
      dispatch(setListLocation(element?.scrollTop));
      if (
        element.scrollTop + element.clientHeight >= element.scrollHeight - 1000
      ) {
        if (reduxProductList?.list?.length !== reduxCounter?.currentCount) {

          if (debounce.current) {
            clearTimeout(debounce.current);
          }
          if (reduxPageCount?.pageCount < reduxTotalPageCount?.totalPage) {
            const newCount = reduxPageCount?.pageCount + 1;
            debounce.current = window.setTimeout(() => {
              // setCount(newCount);
              dispatch(setPageCount(newCount))
              getProductList(newCount, false);
            }, 700);
          }
        }
      }
    }

  };

  useEffect(() => {
    const element: any = scrollContainerRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => {
        element.removeEventListener('scroll', handleScroll);
        clearTimeout(debounce.current);
      };
    }
  }, [reduxPageCount?.pageCount, reduxProductList?.productList, reduxCounter?.currentCount, reduxTotalPageCount?.totalPage]);

  useEffect(() => {
    setIsScroll(false)
    if (initialRendering) {
      // dispatch(setPageLimit(12))
      getProductList(reduxPageCount?.pageCount, true);
    }
    setInitialRendering(true)
  }, [search, reduxIsAvailableCheck?.isAvailableCheck, reduxIsAvailableCheck?.isPictures, reduxCheckboxAPI, reduxIsAvailableCheck?.sort_order])

  useEffect(() => {
    if (reduxPageCount?.pageCount > 1) {
      dispatch(setPageCount(1))
    }
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      if (range[0] !== previousRange[0] || range[1] !== previousRange[1]) {
        getProductList(reduxPageCount?.pageCount, true)
        setPreviousRange(range)
      }
    }, 500);
    return () => {
      clearTimeout(debounce.current);
    };
  }, [range]);

  const _handleFilterChange = () => {
    const isAnyCheckboxSelected = Object.values(reduxSelectCheckboxes).some(
      (checkboxArray: any) => checkboxArray.length > 0
    );

    if (isAnyCheckboxSelected) {
      dispatch(setPageCount(1))
    }
    getProductList(reduxPageCount?.pageCount, true)
  }

  useEffect(() => {
    setIsScroll(false)
    const isCheckboxChanged = JSON.stringify(reduxSelectCheckboxes) !== JSON.stringify(prevSelectedCheckboxes);
    if (isCheckboxChanged) {
      _handleFilterChange();
      setPrevSelectedCheckboxes(reduxSelectCheckboxes);
    }

    // Create an array to store labels
    const labels: string[] = [];

    // Iterate through each property
    for (const prop in reduxSelectCheckboxes) {
      // Check if the property is an array
      if (Array.isArray(reduxSelectCheckboxes[prop])) {
        // Iterate through the array and add labels to the array
        reduxSelectCheckboxes[prop].forEach((item: any) => {
          labels.push(item);
        });
      }
    }
    // Update the state with the labels
    dispatch(setAllLabels(labels));
  }, [reduxSelectCheckboxes]);

  const handleBadgeRemove = (badgeToRemove: string) => {
    // Create a copy of the selectedCheckboxes
    const updatedCheckboxes = { ...reduxSelectCheckboxes };

    // // Iterate through each property and remove the label from the corresponding array
    for (const prop in updatedCheckboxes) {
      if (Array.isArray(updatedCheckboxes[prop])) {
        updatedCheckboxes[prop] = updatedCheckboxes[prop].filter((item: any) => item !== badgeToRemove);
      }
    }
    // // Update the state with the modified selectedCheckboxes
    // dispatch(setAllLabels(updatedCheckboxes));

    dispatch(setSelectedCheckboxes(updatedCheckboxes));
    dispatch(setPageCount(1))
  };

  useEffect(() => {
    const element: any = scrollContainerRef.current;
    // 
    if (element && (activeTab === 'Product Detail')) {
      const scrollPosition = reduxProductListLocation?.previousListLocation;
      if (scrollPosition) {
        element.scrollTop = scrollPosition;
      }
    }

  }, [Navigate])

  useEffect(() => {
    setActiveTab("productList")
    if (reduxFilterList?.length === 0) {
      dispatch(fetchFilterList());
    }
  }, []);

  return (
    <Wrapper style={{ overflow: "hidden" }}>
      <div className='row align-items-center'>
        <div className='col-lg-3' style={{ overflow: "hidden" }}>
          <ProductListTopBar
            heading={'Product List'}
            setShowSearchTab={setShowSearchTab}
            showSearchTab={showSearchTab}
            setShowFilter={setShowFilter}
            showFilter={showFilter}
          />
        </div>

        {showSearchTab &&
          <div className='col-12 col-lg-9 mt-3 mt-lg-0' ref={inputElement}>
            <div className='row ps-0'>
              <div className='col-lg-11'>
                <Search className='search search-style' placeholder='Search for Model No, Product ID'
                  searchCallBack={(e: any) => _handleSearch(e)}
                />
              </div>
              <div className='col-lg-1 px-0 d-none d-lg-flex' >
                <button
                  className='outline-button-filter d-flex gap-1 px-1 justify-content-center'
                  style={{
                    width: "84%", height: "44px",
                    color: (constant?.Product_List_Price_Sort?.High_To_Low === reduxPriceSort) ? '#05B7E4' : '#505d6f',
                    borderColor: (constant?.Product_List_Price_Sort?.High_To_Low === reduxPriceSort) ? '#05B7E4' : '#505d6f',
                  }}
                  id="dropdownSortButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false" >
                  <div className='d-flex gap-1 align-item-center my-auto'>
                    <TbArrowsSort className='fs-18 my-auto' />
                    <span className='fs-16 fw-500'>Sort </span>
                  </div>
                </button>
                <ul
                  className="dropdown-menu zindex-tooltip"
                  aria-labelledby="dropdownSortButton"
                  style={{ width: "175px" }}
                >
                  {<li>
                    <button
                      className={`dropdown-item ${(constant?.Product_List_Price_Sort?.Low_To_High === reduxPriceSort) && 'top-bar-active-tab'}`} >
                      <div className='d-flex align-items-center'
                        onClick={() => dispatch(setSortOrder(constant?.Product_List_Price_Sort?.Low_To_High))}
                      >

                        <span className='ms-2 mb-0'>Price: Low to High</span>
                      </div>
                    </button>
                  </li>}


                  {<li>
                    <hr className="my-1"></hr>
                  </li>}
                  <li>
                    <button
                      className={`dropdown-item ${(constant?.Product_List_Price_Sort?.High_To_Low === reduxPriceSort) && 'top-bar-active-tab'}`}
                      onClick={() => dispatch(setSortOrder(constant?.Product_List_Price_Sort?.High_To_Low))}
                    >
                      <div className={'d-flex align-items-center'}>

                        <span className='ms-2 mb-0'>Price:High to Low</span>
                      </div>
                    </button>

                  </li>
                </ul>
              </div>
            </div>

          </div>}
        {/* <div className='col-3 mt-md-0 mt-3 p-0' style={{ display: window.innerWidth < 768 ? "" : "none" }}>

          <Button className='outline-button-filter' onClick={() => setShowFilter(!showFilter)}> <Image src={FilterImage} alt="" /> Filter</Button>
        </div> */}
      </div>
      <div className='row mt-0 mt-md-0'>
        {showFilter && <div className="col-lg-3">
          {/* <p className='m-0 p-0 text-end text-muted fs-14'>{productList?.length} / {totalCount}</p> */}

          <Filter
            range={range}
            handlePriceRange={handlePriceRange}
            handleInputPrice={handleInputPrice}
            setShowFilter={() => setShowFilter(!showFilter)}
          />
        </div>}
        <div className="col-lg-9 col-fixed">
          <div className="mt-2 mb-2">
            {reduxIsAvailableCheck?.isAvailableCheck ? (
              <div className="badge bg-secondary fw-500 mx-1" > Available at Store
                <span className='px-2 cursor-pointer' onClick={() => { dispatch(setIsAvailableCheck(!reduxIsAvailableCheck?.isAvailableCheck)); dispatch(setPageCount(1)) }}> <RxCross2 />
                </span>
              </div>

            ) : ("")}
            {reduxIsAvailableCheck?.isPictures ? (
              <div className="badge bg-secondary fw-500 mx-1" > Product with Picture
                <span className='px-2 cursor-pointer' onClick={() => { dispatch(setIsPicturesCheck(!reduxIsAvailableCheck?.isPictures)); dispatch(setPageCount(1)) }}> <RxCross2 />
                </span>
              </div>

            ) : ("")}

            {reduxAllLabels && reduxAllLabels?.allLabels?.map((item: any) => {
              return (
                <div className="badge bg-secondary fw-500 mx-1" > {item.label}
                  <span className='px-2 cursor-pointer' onClick={() => handleBadgeRemove(item)}> <RxCross2 />
                  </span>
                </div>
              )
            })}
          </div>


          {/* {
            ((isLoading > 0) && (isScroll)) 
            &&
              <>
                {
                  Array.from({ length: 3 }).map((_, index) => (
                    <div className="row mt-1" key={index}>
                      <div className='col-4'>
                        <Skeleton height={200} borderRadius={8} />
                        <Skeleton width="60%" />
                        <Skeleton width="80%" />
                      </div>
                      <div className='col-4'>
                        <Skeleton height={200} borderRadius={8} />
                        <Skeleton width="60%" />
                        <Skeleton width="80%" />
                      </div>
                      <div className='col-4'>
                        <Skeleton height={200} borderRadius={8} />
                        <Skeleton width="60%" />
                        <Skeleton width="80%" />
                      </div>
                    </div>
                  ))

                }
              </> } */}
          {
            <>
              {reduxProductList?.list?.length > 0 ?
                <div className={`product-list-scroll ${showFilter && 'filter-scroll-stop'}`} ref={scrollContainerRef} >
                  <ProductList />
                </div>
                :
                <div className='fw-400 text-center dark-gray mt-4'><p>No Product Available</p></div>
              }
            </>
          }

          {/* {productList?.length > 0 ? <div className='product-list-scroll' ref={scrollContainerRef} onScroll={handleScroll}>
            <InfiniteScroll
              dataLength={productList?.length}
              next={() => { <></> }}
              hasMore={true}
              loader={<></>}
            >
              <ProductList productList={productList} />
            </InfiniteScroll>
          </div> : <div className='fw-400 text-center dark-gray mt-4'><p>No Product Available</p></div>
          } */}
        </div>
      </div >
      {/* loader for product list */}
      {(isLoading > 0) &&
        <div className="product-list-loader">
          {!isScroll && <div className="spinner-border text-primary loading-Two" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>}
        </div>
      }
    </Wrapper >
  )
}




export default Index
import React from 'react'
import DataTable from 'react-data-table-component';
import './Table.scss'
const Index = ({
  columns, data,
  selectableRows,
  handleSelectedRow,
  paginationTotalRows,
  setCurrentPage,
  setLimitPerPage,
  pagination, className, CustomRow }: any) => {

  const customStyles: any = {
    headCells: {
      style: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        paddingTop: '15px',
        paddingBottom: '15px',
        background: '#D0F6FF',
        borderBottom: '1px solid #000',
        fontSize: '14px',

      },
    },
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },

    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },

    },

  };
  return (
    <div className={`${className} mt-4`}>
      <div className="data-table-container">
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={data}
          pagination={pagination}
          striped
          selectableRows={selectableRows}
          onSelectedRowsChange={handleSelectedRow}
          selectableRowsHighlight
          // theme="dark"
          paginationServer
          paginationTotalRows={paginationTotalRows}
          onChangeRowsPerPage={(limit) => setLimitPerPage(limit)}
          onChangePage={(page) => setCurrentPage(page)}
        // expandableRows={true}
        // expandableRowsComponent={(rowData) => (
        //   <div style={{display:"block"}}>
        //     <span>qqqqqqqqqqq</span>
        //     {/* Add more custom content here */}
        //   </div>
        // )}
        />
      </div>
    </div>
  )
}

export default Index
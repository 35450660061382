import React from 'react';
import { CheckboxInputProps } from './interface';
import './Checkbox.scss'
const CheckboxInput: React.FC<CheckboxInputProps> = ({
    id,
    name,
    checked,
    onChange,
    label,
    htmlFor,
    className,
    disabled,
    labelClass
}) => {
    return (
        <div className="form-group d-flex align-items-center">
            <input
                id={id}
                className={`input-checkbox form-check-input shadow-none ${className || ''}`}
                type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
                disabled={disabled || false}
            />
            {label &&
                <label
                    className={`inputs-label fw-600 secondary-color ms-2 ${labelClass ? labelClass : 'fs-16'}`}
                    htmlFor={htmlFor}
                >
                    {label}
                </label>
            }
        </div>
    );
};

export default CheckboxInput;

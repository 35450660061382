const WarrantyProduct = {
    warrantyProductList: () => 'store/warranty/products/search',
    warrantyProductSubmit: (id:string) => `store/warranty/${id}/claim`,
    warrantyProductUpdate:(id:string)=>`store/warranty/claimed-products/${id}`,
    warrantyProductDetail:(id:string)=>`store/warranty/claimed-products/${id}`,
    productImageDelete:()=>'store/warranty/images-remove',

    claimedProductList: () => 'store/warranty/claimed-products',
    claimedProductDetail: (id:string) => `store/warranty/claimed-products/${id}`,
    claimedProductReject: (id:string) => `store/warranty/reject/${id}`,
    claimedProductApprove: (id:string) => `store/warranty/approve/${id}`,
    claimedHistory:()=>'store/warranty/claimed-products/history',
    shareDoc:(id:string)=>`store/warranty/share-document/${id}`,
    
    issueNewProductDetail:(warrantyClaimedId:string, productId:string)=>`store/warranty/${warrantyClaimedId}/product/${productId}/view`,
    requestProduct:(id:string)=>`store/warranty/product/${id}/request`,
    replaceProduct:(id:string)=>`store/warranty/product/${id}/replace`,
    issueCreditNote:(id:string)=>`store/warranty/credit-note/${id}`,



}
export default WarrantyProduct
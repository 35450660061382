import React, { useState } from 'react'
import atoms from '../../atoms'
import { Card } from 'reactstrap'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import CancelPopUpModal from '../../molecules/CancelPopUpModal'
import Images from '../../../assets/Images'
const { CustomSearch, Table, Button } = atoms
const { Coin, Currency } = Images

const Index = () => {
    const [showModal, setShowModal] = useState(false)

    const columns = [
        {
            name: 'Product ID',
            selector: (row: any) => row.title,
        },
        {
            name: 'Barcode ',
            selector: (row: any) => row.year,
        }, {
            name: 'Product Image',
            selector: (row: any) => row.title,
        },
        {
            name: 'Driver To Pickup',
            selector: (row: any) => row.year,
        }, {
            name: 'SRP',
            selector: (row: any) => row.title,
        },
        {
            name: 'Status',
            selector: (row: any) => row.year,
        }, {
            name: 'Action',
            selector: (row: any) => row.title,
        },
    ];

    const data = [
        {
            id: 1,
            title: '#151564665545',
            year: '1988',
        },
        {
            id: 2,
            title: '#154847851',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Heide L. Sawyer',
            year: '1984',
        }, {
            id: 1,
            title: '₹3,500.00',
            year: '1988',
        },
        {
            id: 2,
            title: 'Redy For Pickup',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]

    return (
        <>
            <div className="">
                <div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className=''>
                                <CustomSearch placeholder='Search Product ID' className='fs-16' />
                            </div>
                        </div>
                    </div>
                    <Table columns={columns} data={data} />

                    <Button className='account-page-btn w-100 mt-5' onClick={() => setShowModal(true)}>Store Requested Cancel</Button>

                </div>

                {showModal && <>
                    <CancelPopUpModal
                        show={showModal}
                        onHide={() => setShowModal(false)}

                    >


                    </CancelPopUpModal>

                </>}
            </div>

        </>


    )
}

export default Index
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  pageCount: 1,
  totalPage:1,
  limit:48,
};

const pageCountSlice = createSlice({
  name: 'pageCount',
  initialState,
  reducers: {
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload;
    },
    setTotalPageCount: (state, action: PayloadAction<number>) => {
      state.totalPage = action.payload;
    },
    setPageLimit:(state,action:PayloadAction<number>)=>{
      state.limit = action.payload
    }
  },
});

export const { setPageCount,setTotalPageCount, setPageLimit } = pageCountSlice.actions;

export default pageCountSlice.reducer;

import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import organisms from '../../../components/organisms'
import molecules from '../../../components/molecules'
import service from '../../../api/services'
import constant from '../../../constants/constant'
import { ShowActiveTab } from '../../../Context'
import { Default_Page_Limit } from '../../../constants/defaultValues'
import { handleFileDownload } from '../../../constants/utils'
import { useToasts } from 'react-toast-notifications'

const { ClaimedHistory } = organisms
const { TopBar } = molecules
const Index = () => {
  const { addToast } = useToasts();
  const [claimedHistoryData, setClaimedHistoryData] = useState<any>()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const { setActiveTab }: any = useContext(ShowActiveTab)

  const getClaimProductHistoryList = async () => {
    try {
      const response = await service.warrantyService.claimedHistoryList({ search: search, page: currentPage, limit: limitPerPage, status: constant?.ORDER_STATUS?.CANCELED })
      if (response.status === 200) {
        setClaimedHistoryData(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleDownload = async (id: string, share?:boolean) => {
 
    try {
      let response;
      if (share) {
        response = await service.warrantyService?.shareDoc(id, {share:share})
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
      });
      } else {
        response = await service.warrantyService?.shareDoc(id)
        if (response.status === 200) {
          handleFileDownload(response?.data?.data?.path)
        }
      }
    }
    catch (error:any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
    });
    }

  }

  useEffect(() => {
    getClaimProductHistoryList()
  }, [search, currentPage, limitPerPage])

  useEffect(() => {
    setActiveTab("Claim History")
    setCurrentPage(1)
    if (currentPage == 1 && initialLoading) {
      getClaimProductHistoryList()
    }
    setInitialLoading(true)
  }, [limitPerPage])


  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Claim History' />
      </div>
      <div className='mt-3'>
        <ClaimedHistory
          cancelledOrderData={claimedHistoryData}
          limitPerPage={limitPerPage}
          currentPage={currentPage}
          setSearch={(e: string) => setSearch(e)}
          setLimitPerPage={(limit: number) => setLimitPerPage(limit)}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          handleDownload={(id: string, share?:boolean) => handleDownload(id, share)}
        />
      </div>
    </Wrapper>
  )
}

export default Index
import React, { useState } from 'react'
import atoms from '../../atoms'
import { Card } from 'reactstrap'
import Images from '../../../assets/Images'
const { CustomSearch, Table, } = atoms
const { Coin, Currency } = Images

const Index = () => {
    const [showModal, setShowModal] = useState(false)

    const columns = [
        {
            name: 'Product ID',
            selector: (row: any) => row.title,
        },
        {
            name: 'Barcode ',
            selector: (row: any) => row.year,
        }, {
            name: 'Product Image',
            selector: (row: any) => row.title,
        },
        {
            name: 'Driver To Pickup',
            selector: (row: any) => row.year,
        }, {
            name: 'SRP',
            selector: (row: any) => row.title,
        },
        {
            name: 'Status',
            selector: (row: any) => row.year,
        }, {
            name: 'Action',
            selector: (row: any) => row.title,
        },
    ];

    const data = [
        {
            id: 1,
            title: '#151564665545',
            year: '1988',
        },
        {
            id: 2,
            title: '#154847851',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Heide L. Sawyer',
            year: '1984',
        }, {
            id: 1,
            title: '₹3,500.00',
            year: '1988',
        },
        {
            id: 2,
            title: 'Redy For Pickup',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        }, {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]

    return (
        <>
            <div className="">
                <div className='custom-tabs mt-4'>
                    <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                Items received
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                Items transfered
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-5">
                                <div className="col-12 col-md-6">
                                    <div className=''>
                                        <CustomSearch placeholder='Search Product ID' className='fs-16' />
                                    </div>
                                </div>
                            </div>
                            <Table columns={columns} data={data} />
                        </div>
                        <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-5">
                                <div className="col-12 col-md-6">
                                    <div className=''>
                                        <CustomSearch placeholder='Search Product ID' className='fs-16' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>


    )
}

export default Index
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Context, BadgesCount } from "../Context";
import { BASE_URL } from "../constants/defaultValues";
import constant from "../constants/constant";
import Auth from "./dictionary/auth";
let isRefreshToken = false;

const Interceptor = () => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [userPermissions, setUserPermissions] = useContext<any>(Context);
    const [BadgeCount, setBadgeCount] = useContext<any>(BadgesCount)
    const url = new URL(window.location.href);


    useEffect(() => {
        axios.interceptors.request.use((config) => {
            const local_storage = localStorage.getItem('auth_details') || ''
            const auth_details = local_storage && JSON.parse(local_storage)
            const AUTH_TOKEN = auth_details?.access_token
            let isLogin = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'login')
            if (config && config.headers && !(isLogin))
                config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
            if (!AUTH_TOKEN && !(isLogin)) {
                navigate("/login");
            }
            setIsLoading(true)
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(
            response => {
                setIsLoading(false);
                {
                    response?.data?.notification_count && setBadgeCount(response?.data?.notification_count)
                    response?.data?.notification_count?.sales_cart_count != undefined && localStorage.setItem('sales_count', response?.data?.notification_count?.sales_cart_count)
                }
                const permissionsHeader = response?.headers['x-app-permission'];
                const result = [];
                for (let i = 0; i < permissionsHeader?.length; i += 5) {
                    result.push(permissionsHeader.slice(i, i + 5));
                }
                setUserPermissions(result)
                return response
            }, (error) => {
                setIsLoading(false)
                {
                    error?.response?.data?.notification_count && setBadgeCount(error?.response?.data?.notification_count)
                    error?.data?.notification_count?.sales_cart_count != undefined && localStorage.setItem('sales_count', error?.data?.notification_count?.sales_cart_count)
                }
                if (error.response && error.response.status === 401 && !isRefreshToken) {
                    const main = async () => {
                        try {
                            // localStorage.clear()
                            const tokens: any = await refreshToken();
                            axios.defaults.headers.common['Authorization'] = tokens.accessToken;
                            error.config.headers['Authorization'] = tokens.accessToken;
                            isRefreshToken = false
                            return axios.request(error.config);
                        } catch (err) {
                            isRefreshToken = false
                            localStorage.clear();
                            navigate("/login");
                        }
                    }
                    return main()
                } else {
                    return Promise.reject(error);
                }
            }
        )
    }, [])

    const refreshToken = () => {
        isRefreshToken = true
        return new Promise(async (resolve, reject) => {
            try {
                let auth = {};
                const refresh = localStorage.getItem('refresh_token')
                const res = await axios.post(BASE_URL + Auth.login(), {
                    grant_type: 'refresh_token',
                    refresh_token: refresh
                }, {
                    headers: {
                        'Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0',
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                // console.log('res.data.data', res.data.data)
                auth = {
                    access_token: res?.data?.data?.accessToken,
                    username: res?.data?.data?.username,
                    store_name: res?.data?.data?.store_name,
                    store_location: res?.data?.data?.store_location,
                }
                const auth_details = JSON.stringify(auth);
                localStorage.setItem("auth_details", auth_details)
                localStorage.setItem("refresh_token", res.data.data.refreshToken)
                resolve(res.data.data)

            } catch (err) {
                reject(err);
            }
        })

    }

    return (
        <div>
            {((url.pathname != constant?.PRODUCT_lIST_PATH)  && isLoading) &&
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        alignItems: 'center'
                    }}
                    className="loading-One"
                >
                    <div
                        style={{
                            position: 'fixed',
                            zIndex: '9999'
                        }}
                        className="spinner-border text-primary loading-Two" role="status">

                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
        </div >
    )
}
export default Interceptor;
import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import { ShowActiveTab } from '../../../Context';
import { useNavigate } from 'react-router';
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import service from '../../../api/services';
import organisms from '../../../components/organisms';

const { Dashboard } = organisms

const Index = () => {
  const { setActiveTab }: any = useContext(ShowActiveTab)
  const navigate = useNavigate()
  const [dashboardDetails, setDashboardDetails] = useState()

  const getDashboardDetails = async () => {
    try {
      const response = await service.DashboardService.dashboard()
      if (response.status === 200) {
        setDashboardDetails(response.data.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setActiveTab("dashBoard")
    getDashboardDetails()
  }, [])

  return (
    <Wrapper>
      {dashboardDetails && <Dashboard
        dashboardDetails={dashboardDetails}
      />}
      {/* <div className="d-flex flex-row flex-wrap gap-3">
        <div className="flex-grow-1">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Top Brands</h2>
          </div>
        </div>

        <div className="flex-grow-1">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Stock List</h2>
          </div>
        </div>

        <div className="flex-grow-1">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Master Stock List</h2>
          </div>
        </div>


        <div className="flex-grow-1">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Order Completed </h2>
          </div>
        </div>

        <div className="flex-grow-1" onClick={() => navigate("/prescription")}>
          <div className='dashboard' >
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Prescription</h2>
          </div>
        </div>

        <div className="flex-grow-1" onClick={() => navigate("/sales-return-history")}>
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Sales Return History </h2>
          </div>
        </div>

        <div className="flex-grow-1" onClick={() => navigate("/sales-return")}>
          <div className='dashboard' >
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Sales Return  </h2>
          </div>
        </div>

        <div className="flex-grow-1 " onClick={() => navigate("/warranty")}>
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center ' >
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Warranty</h2>
          </div>

        </div>
        <div className="flex-grow-1 " onClick={() => navigate("/claimed")}>
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center ' >
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Claim Product</h2>
          </div>

        </div>
        <div className="flex-grow-1 " onClick={() => navigate("/claimed-history")}>
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center ' >
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Claimed Product History</h2>
          </div>

        </div>

        <div className="flex-grow-1" style={{ visibility: 'hidden' }}>
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='custom-black-bg text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Warranty</h2>
          </div>

        </div>
      </div> */}
    </Wrapper>
  )
}

export default Index
import React, { useState, useEffect } from 'react'
import atoms from '../../atoms'
import Images from '../../../assets/Images'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications';
import PlaceHolderImage from '../../../assets/Images/placeholderimg.png'
import ThreeDots from '../../../assets/Images/threeDots.svg'
import { RiDeleteBin6Line } from 'react-icons/ri';
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit, FiShare } from "react-icons/fi";
import molecules from '../../molecules'
import constant from '../../../constants/constant'
import { findConstantLabel, handleFileDownload } from '../../../constants/utils'
import { claimedProductProps, ClaimProductDetail } from './interface'
import { MdOutlineFileDownload } from "react-icons/md"
import service from '../../../api/services'
import { BASE_URL } from '../../../constants/defaultValues'

const { CustomSearch, Select, Button, Table, Image, Pagination, CustomImages } = atoms
const { AuditProductImg, Copy } = Images
const { TopBar, CreateSideModal, ConfirmationModal } = molecules
const Index = ({ claimedProductData, claimProductDetail, searchCallBack, currentPage, limit, setLimitPerPage, setCurrentPage, handleWarrantyReject, getDetailById }: claimedProductProps) => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const [showSidebarModal, setShowSidebarModal] = useState(false)
    const [handleCancelModal, setHandleCancelModal] = useState<boolean>(false)
    const [claimProductId, setClaimProductId] = useState<string>("")
    const [isOpen, setIsOpen] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);


    const columns = [
        { name: 'Warranty Claim ID', selector: (row: ClaimProductDetail) => row?.claim_id || '' },
        { name: 'Customer Name', selector: (row: ClaimProductDetail) => row?.customer?.name },
        { name: 'Product Image', cell: (row: ClaimProductDetail) => <CustomImages src={row?.product?.images?.length > 0 && row?.product?.images[0]} height="75%" />, center: true },
        { name: 'Product Id', selector: (row: ClaimProductDetail) => row?.product?.product_id_display || '-' },
        { name: 'SpecxWeb Approval', selector: (row: ClaimProductDetail) => row?.claim_approval },
        { name: 'Claim Resolution', selector: (row: ClaimProductDetail) => row?.claim_resolution },
        { name: 'Action', selector: (row: any) => row?.action, center: true },
    ];
    const handleSharedDoc = async (id: string, share?:boolean) => {
        try {
            // const response = await service?.warrantyService?.shareDoc(id, { share: true })
            let response: any;
            if (share) {
              response = await service?.warrantyService?.shareDoc(id, { share: true });
              addToast(response?.data?.message, {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
            } else {
              response = await service.warrantyService?.downloadDoc(id);
              if (response.status === 200) {
                  handleFileDownload(response?.data?.data?.path)
              }
            }
        } catch (error:any) {
            console.log('error', error)
            addToast(error?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleModalSubmit = () => {
        // Perform action on modal submit
        navigate(`/issue-new-product/${claimProductDetail?.id}`);
        sessionStorage.setItem('warrantyProductId', claimProductDetail?.product?.id);
        sessionStorage.setItem("outOfStockData", JSON.stringify({ id: "", brand_color_code: "", brand_color_name: "", color_code: "", }));
    }
    
    return (
        <div>
            <div className="row mt-md-0 mt-1">
                <div className="col-md-6">
                    <div className=''>
                        <CustomSearch
                            placeholder='Search Claim ID'
                            heading='Search'
                            className='fs-16'
                            buttonStyle="w-25"
                            setIsOpen={setIsOpen}
                            searchCallBack={(searchValue: string) => searchCallBack(searchValue)}
                        />
                    </div>
                </div>
            </div>

            <div>
                <Table
                    columns={columns}
                    data={claimedProductData?.data?.map((item: ClaimProductDetail) => {
                        return ({
                            ...item,
                            claim_approval: item.claim_approval_status === constant.WARRANTY_CLAIM_APPROVAL_STATUS[0]?.value ?
                                (<div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.claim_approval_status === constant.WARRANTY_CLAIM_APPROVAL_STATUS[1]?.value ?
                                    (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Approve"}</p></div>) : item.claim_approval_status === constant.WARRANTY_CLAIM_APPROVAL_STATUS[2]?.value ?
                                        (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rejected"}</p></div>) : ''
                            ,
                            claim_resolution: item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[0].value ? (
                                <div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[1]?.value ?
                                (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Replace"}</p></div>) : item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[2]?.value ?
                                    (<div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{"Give Credit Note"}</p></div>) : item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[3]?.value ?
                                        (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Requested"}</p></div>) : item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[4]?.value ?
                                            (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rejected"}</p></div>) : ''
                            ,
                            action:
                                <> <div className='table-data-width'>
                                    <button
                                        className="btn dropdown-dots shadow-none action-button"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <Image src={ThreeDots} />
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                        aria-labelledby="dropdownMenuButton1"
                                        style={{ width: "150px" }}
                                    >
                                        {!(item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[3]?.value) && <li>
                                            <button
                                                className="dropdown-item dropdown-item-size" >
                                                <div className='d-flex align-items-center'
                                                    onClick={() => {
                                                        getDetailById(item?.id)
                                                        setClaimProductId(`${item?.id}`)
                                                        setShowSidebarModal(true)
                                                    }}>
                                                    <AiOutlineEye />
                                                    <span className='ms-2 mb-0'>View</span>
                                                </div>
                                            </button>
                                        </li>}

                                        {/* ) : ( */}
                                        {!(item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[3]?.value) && <li>
                                            <hr className="my-1"></hr>
                                        </li>}
                                        <li>
                                            <button
                                                className="dropdown-item dropdown-item-size"
                                                disabled={(item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[4]?.value)}
                                                onClick={() => navigate(`/warranty-detail/${item?.id}`)}
                                            >
                                                <div className={'d-flex align-items-center'}>
                                                    <FiEdit />
                                                    <span className='ms-2 mb-0'>Edit</span>
                                                </div>
                                            </button>
                                            {/* )} */}
                                        </li>

                                        {/* ) : ( */}
                                        {(item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[3]?.value) && <>
                                            <li>
                                                <hr className="my-1"></hr>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item dropdown-item-size"
                                                    // disabled={(item.claim_resolution_status === constant.WARRANTY_CLAIM_RESOLUTION_STATUS[4]?.value)}
                                                    onClick={() => {
                                                        sessionStorage.setItem('warrantyProductId', item?.product?.id);
                                                        navigate(`/issue-new-product/${item?.id}`);
                                                    }}
                                                >
                                                    <div className={'d-flex align-items-center'}>
                                                        <FiEdit />
                                                        <span className='ms-2 mb-0'>Update</span>
                                                    </div>
                                                </button>
                                                {/* )} */}
                                            </li>
                                        </>}

                                        {/* ) : ( */}
                                        <li>
                                            <hr className="my-1"></hr>
                                        </li>
                                        <li
                                        >
                                            <button
                                                onClick={() => handleSharedDoc(item?.id)}
                                                className="dropdown-item dropdown-item-size" >
                                                <div className='d-flex align-items-center'>
                                                    <MdOutlineFileDownload />
                                                    <span className='ms-2 mb-0'>Download</span>
                                                </div>
                                            </button>
                                            {/* )} */}
                                        </li>

                                        {/* ) : ( */}
                                        <li>
                                            <hr className="my-1"></hr>
                                        </li>
                                        <li
                                        >
                                            <button
                                                onClick={() => handleSharedDoc(item?.id, true)}
                                                className="dropdown-item dropdown-item-size" >
                                                <div className='d-flex align-items-center'>
                                                    <FiShare />
                                                    <span className='ms-2 mb-0'>Share</span>
                                                </div>
                                            </button>
                                            {/* )} */}
                                        </li>

                                        {/* ) : ( */}
                                        <li>
                                            <hr className="my-1"></hr>
                                        </li>
                                        <li>
                                            <button
                                                className="dropdown-item dropdown-item-size color-red"
                                                onClick={() => { setClaimProductId(`${item?.id}`); setHandleCancelModal(!handleCancelModal) }} >
                                                <div className='d-flex align-items-center'>
                                                    <RiDeleteBin6Line />
                                                    <span className='ms-2 mb-0 '>Reject</span>
                                                </div>
                                            </button>
                                            {/* )} */}
                                        </li>
                                    </ul>
                                </div></>
                        })
                    })}
                />
            </div>

            <Pagination
                currentPage={currentPage}
                totalPages={claimedProductData?.totalPages}
                onChangePage={(page: number) => setCurrentPage(page)}
                setLimit={(e: number) => setLimitPerPage(e)}
                limit={limit}
            />

            {
                handleCancelModal && <ConfirmationModal
                    show={handleCancelModal}
                    handleConfirmItem={() => { handleWarrantyReject(claimProductId); setHandleCancelModal(!handleCancelModal) }}
                    onHide={() => setHandleCancelModal(!handleCancelModal)}
                    message={'reject'} />
            }

            {showSidebarModal && <div>
                <CreateSideModal
                    show={showSidebarModal}
                    onHide={() => setShowSidebarModal(!showSidebarModal)}
                    submitBtnText="Approve"
                    // handleModalSubmit={() => {
                    //     navigate(`/issue-new-product/${claimProductDetail?.id}`);
                    //     sessionStorage.setItem('warrantyProductId', claimProductDetail?.product?.id);
                    //     sessionStorage.setItem("outOfStockData", JSON.stringify({ id: "", brand_color_code: "", brand_color_name: "", color_code: "", }));
                    // }}
                    handleModalSubmit={()=> { setShowConfirmationModal(!showConfirmationModal)}}
                    header="Product Detail">
                    <>
                        <div className=' d-flex gap-3 align-items-center mb-4'>
                            <h2 className='mb-0 fs-18 fw-700 secondary-color '>Product ID:</h2>
                            <div className='d-flex w-auto justify-content-around align-items-center p-2 add-product-custom-div cursor-pointer' >
                                <h2 className='mb-0 fs-16 fw-600 secondary-color'>{claimProductDetail?.product?.product_id_display}</h2>
                                <div className='ms-2'>
                                    {/* <Image src={CopyIcon} onClick={() => navigator.clipboard.writeText(claimProductDetail?.product_id_display)} /> */}
                                </div>
                            </div>
                        </div>

                        <hr className="mt-3" style={{ border: '1px dashed #222', }} />
                        <div className="mt-4 fs-20 fw-600 secondary-color">Basic Details</div>
                        <div className='d-md-flex justify-content-md-between'>
                            <div className="d-flex mt-20 info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Invoice Number :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.order?.invoice_number}</h2>
                            </div>
                            <div className="d-flex mt-20 info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Brand Color Code :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.product?.brand_color_code}</h2>
                            </div>
                        </div>
                        <div className='d-md-flex justify-content-md-between'>
                            <div className="d-flex info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Warranty Claim ID :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.claim_id}</h2>
                            </div>
                            <div className="d-flex  info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Size :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.product?.glass_size}MM</h2>
                            </div>
                        </div>

                        <div className='d-md-flex justify-content-md-between'>
                            <div className="d-flex info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Customer Name :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.customer?.name}</h2>
                            </div>
                            <div className="d-flex  info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Polarized :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.product?.isPolarised ? findConstantLabel(constant?.POLARIZED, claimProductDetail?.product?.isPolarised) : ""}</h2>
                            </div>
                        </div>

                        <div className='d-md-flex justify-content-md-between'>
                            <div className="d-flex  info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Customer Number :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.customer?.mobile}</h2>
                            </div>
                            <div className="d-flex info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">SRP :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.product?.product_srp}</h2>
                            </div>
                        </div>

                        <div className='d-md-flex justify-content-md-between'>
                            <div className="d-flex  info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Brand :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.product?.brand?.brand_name}</h2>
                            </div>
                            <div className="d-flex info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Product With Case :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.product?.product_with_case ?
                                    <p className='fs-16 fw-400 mb-0 secondary-color info-text mx-2'>Yes</p> :
                                    <p className='fs-16 fw-400 mb-0 secondary-color info-text mx-2'>No</p>}</h2>
                            </div>
                        </div>
                        <div className='d-md-flex justify-content-between'>
                            <div className="d-flex  info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Modal Number :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.product?.modal_number}</h2>
                            </div>
                            <div className="d-flex info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Claim For :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{findConstantLabel(constant?.warrantee_claim_for, claimProductDetail?.claimed_for)}</h2>
                            </div>
                        </div>
                        <div className='d-md-flex justify-content-between'>
                            <div className="d-flex info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Amount Paid :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{claimProductDetail?.paid_amount}</h2>
                            </div>
                            <div className="d-flex info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Claimed Approval Status :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{findConstantLabel(constant?.WARRANTY_CLAIM_APPROVAL_STATUS, claimProductDetail?.claim_approval_status)}</h2>
                            </div>
                        </div>
                        <div className='d-md-flex justify-content-between'>
                            <div className="d-flex info-style" >
                                <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Claim Resolution Status :</h2>
                                <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{findConstantLabel(constant?.WARRANTY_CLAIM_RESOLUTION_STATUS, claimProductDetail?.claim_resolution_status)}</h2>
                            </div>

                        </div>

                        <div className="mt-4 fs-20 fw-600 secondary-color">Product Images</div>
                        <div className='row mb-3'>
                            {claimProductDetail?.images && claimProductDetail?.images.map((productImage: any, index: number) => {
                                return (
                                    <div className="d-flex flex-wrap gap-4 col-2">
                                        <div className="info-cover-img position-relative" >
                                            <CustomImages className="info-cover-img" src={productImage || ""} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="row info-style mt-5" >
                            <h2 className=" col-sm-4 mb-0 fs-16 fw-600 secondary-color info-text" style={{ paddingRight: 0 }}>Product Condition Remark :</h2>
                            <h2 className=" col-sm-8 px-0 mb-0 fs-16 fw-400 secondary-color info-text">{claimProductDetail?.condition_remark}</h2>
                        </div>

                    </>
                </CreateSideModal>
            </div>}

            {
                showConfirmationModal && <ConfirmationModal
                    show={showConfirmationModal}
                    onHideButtonText={"Cancel"}
                    confirmButtonText={"OK"}
                    handleConfirmItem={() => { handleModalSubmit(); setShowConfirmationModal(!showConfirmationModal) }}
                    onHide={() => setShowConfirmationModal(!showConfirmationModal)}
                    message={'approve warranty claim'} />
            }                  
        </div>
    )
}

export default Index
import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Images from '../../../assets/Images';
import atoms from '../../atoms'

const { Button, Image, Select } = atoms
const { CancelIcon, Subtract, } = Images


const CancelModal = (props: any) => {
    return (
        <>
            <Modal className="h-100 d-flex justify-content-center align-items-center modal-m position-relative" isOpen={props.show} >
                {/* <ModalHeader >Confirmation</ModalHeader> */}
                <div className='w-100 d-flex justify-content-center'>
                    <Image src={CancelIcon} className="img-fluid position-absolute success-icon" />
                </div>
                <ModalBody className="rounded-10 mt-4">
                    <h5 className='text-center fs-24 fw-700 mt-4 '>Cancel Request</h5>
                    <h5 className='text-center fs-18 fw-400 mt-3 '>The reason for the cancellation</h5>

                    <hr className='mt-5' />

                    <div className='align-items-center mt-4'>
                        <h2 className="mb-0 fs-20 fw-600 secondary-color">Item Discount :-</h2>
                        <Select className='sales-custom-select' />
                    </div>

                    <div className='row'>
                        <div className="col-12 mt-4 ">
                            <h2 className='mb-0 fs-20 fw-600 secondary-color'>Product Condition Remark</h2>
                            <textarea className={"textarea-style w-100 mt-3"} placeholder={"Enter Description Here"}
                                onChange={(e: any) => ''} />
                        </div>
                    </div>



                    <div className='text-center mt-4'>
                        <Button className='fs-16 fw-500 w-100 approve-btn  primary-btn add-btn'
                            onClick={props.onHide}> Submit</Button>

                    </div>
                    <div className='w-100 d-flex justify-content-center'>
                        <div className=' position-absolute popup-bottom-design' style={{ backgroundImage: `url(${Subtract})` }}>
                        </div>
                    </div>
                </ModalBody>


            </Modal>




        </>



    )
}

export default CancelModal